import { Injectable } from "@angular/core";
import { Router } from "@angular/router";

import {
  ProjectService,
  SiteService,
  DefectService,
  UserService,
  CatchErrorService,
  AlertService,
} from "../services";

import type { IDefectContractorAssignment } from "../models";

import {
  Project,
  List,
  Defect,
  IProjectProgressSummaryUpdate,
  IDefectStatus,
  HashDataId,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  DefectPrimaryKeyId,
  PrimaryKeyId,
} from "../models";

import SortHelper from "../helpers/sorting.helper";

import { environment } from "../../environments/environment";

/** Internet connectivity service */
@Injectable({
  providedIn: "root",
})
export class ClientProjectProvider {
  /** Whether current user is a projet owner */
  readonly isOwner: boolean = false;
  /** For comparing against comments/other */
  uid: number | undefined;
  /** Company name */
  company: string = "";
  projectImage: string = "";

  // PROJECT SCREEN INFORMATION
  /** Project Id number */
  projectId: ProjectPrimaryKeyId | undefined;
  /** Project Id number */
  projectHashId: HashDataId | "" = "";
  /** Project object */
  project: Project.Project | undefined;
  /** Array of original sites */
  lists: Project.ListView[] = [];
  /** TODO: project contractors */
  contractors: Project.Contractor[] = [];

  // PROJECT UI CONTROLS
  /** UI Control for things pending get project */
  loadingProject = true;
  loadingList = false;
  loadingDefect = true;

  // LIST SCREEN INFO
  /** Project Id number */
  listId: ListPrimaryKeyId | undefined;
  /** Project Id number */
  listHashId: HashDataId | "" = "";
  /** IListView screen view object */
  private _list: List.List | undefined;
  /** Original array of defects for list */
  listDefects: List.DefectView[] = [];

  // DEFECT SCREEN INFO
  /** Defect object to show on page */
  private _defect: Defect.Defect | undefined;
  /** Defect photos array */
  originalDefectPhotos: Defect.Photo[] = [];
  /** Defect photos array */
  defectPhotos: Defect.Photo[] = [];
  /** Holds the old id when switching and updating UI */
  oldDefectStatus: IDefectStatus | undefined;
  /** Contractor Assignments - Only used for populating the sub-component */
  originalDefectContractors: IDefectContractorAssignment[] = [];

  // DEFECT UI CONTROLS
  /** controls the side panel or modal */
  showDefectInPanel: boolean = false;
  /** controls the side panel or modal */
  showDefectInModal: boolean = false;
  /** Contractor Assignments - Used for the multi-select control */
  defectContractorIds: PrimaryKeyId[] = [];
  /** Controls the gallery popup in list.html */
  showDefectGallery: boolean = false;
  /** Sets which image to open in the gallery popup in list.html */
  activeGalleryIndex: number = 0;

  /** @ignore */
  constructor(
    private ps: ProjectService,
    private ss: SiteService,
    private ds: DefectService,
    private us: UserService,
    private cx: CatchErrorService,
    private as: AlertService,
    private router: Router
  ) {}

  get defect(): Defect.Defect | undefined {
    return this._defect;
  }

  /** Set defect - clears related items if undefined */
  set defect(o: Defect.Defect | undefined) {
    if (o === undefined) {
      // resetting defect, reset associated stuff
      this.oldDefectStatus = undefined;
      this.showDefectInModal = false;
      this.showDefectInPanel = false;
      this.defectPhotos = [];
      this.originalDefectContractors = [];
    }
    this._defect = o;
  }

  get list(): List.List | undefined {
    return this._list;
  }

  /** Set defect - clears related items if undefined */
  set list(o: List.List | undefined) {
    if (o === undefined) {
      this.listId = undefined;
      this.listHashId = "";
      this.listDefects = [];
    }
    this._list = o;
  }

  /**
   * Clear / Wipe any data stored in the Project Provider
   */
  destroy(): void {
    if (!environment.production) {
      console.log("Reset client project data");
    }
    this.projectId = undefined;
    this.projectHashId = "";
    this.project = undefined;
    this.lists = [];
    this.contractors = [];
    this.loadingProject = true;

    this.destroyList();
    this.destroyDefect();
  }
  /**
   * Clear list screen data
   */
  destroyList(): void {
    this.list = undefined;
  }
  /**
   * Clear the cached defect
   */
  destroyDefect(): void {
    this.defect = undefined;
  }
  /**
   * Close the currently selected defect
   */
  closeDefect(): void {
    this.destroyDefect();
    this.showDefectInModal = false;
    this.showDefectInPanel = false;
  }
  /**
   * Retrieves project data and relevant information for the user.
   *
   * @param {boolean} keepChildIds - Whether to keep child ids or not.
   * @return {Promise<{
   *    project: Project.Project;
   *    lists: Project.ListView[];
   *    contractors: Project.Contractor[];
   *    projectTeam: Project.TeamMember[];
   *    reports: Project.Report[];
   *    isOwner: boolean;
   * }>} - A promise that resolves with the project data.
   * @throws {string} - Access denied if no subscription found or rejection error if failed to get project data.
   */
  async getProject(keepChildIds?: boolean): Promise<boolean> {
    if (!environment.production) {
      console.log("getClientProject()");
    }
    this.loadingProject = true;

    if (this.uid === undefined) {
      const clientIds = await this.us.checkAndGetClientIds();

      if (clientIds.tenantId && clientIds.userId) {
        this.uid = clientIds.userId;
      } else {
        return Promise.reject("Error, please logout and then try again.");
      }
    }

    try {
      if (this.projectId) {
        if (keepChildIds !== true) {
          this.destroyList();
          this.destroyDefect();
        }

        const { project, lists, contractors, company, projectImage } =
          await this.ps.getClientProjectPageData(this.projectId);

        this.company = company;
        this.projectImage = projectImage;
        this.project = project;
        this.contractors = contractors;
        this.lists = SortHelper.defaultListsSort(lists, this.project.type);
        return true;
      }
    } catch (ex) {
      const error = JSON.stringify(ex);
      if (error.includes("User is inactive or does not have permission")) {
        this.cx.logWarning(error);
      } else {
        this.cx.handle(ex, true);
      }
      return Promise.reject(ex);
    } finally {
      this.loadingProject = false;
    }
    return Promise.reject("Unknown error loading project data");
  }
  /**
   * Get Site & defect data
   */
  async getProjectList(keepChildIds?: boolean): Promise<boolean> {
    if (this.uid === undefined) {
      const clientIds = await this.us.checkAndGetClientIds();

      if (clientIds.tenantId && clientIds.userId) {
        this.uid = clientIds.userId;
      } else {
        return Promise.reject("Error, please logout and then try again.");
      }
    }

    if (!this.loadingList && this.projectId && this.listId) {
      this.loadingList = true;
      // check if project is loaded, if not, do it first
      if (this.project === undefined) {
        await this.getProject(true);
      }

      if (keepChildIds !== true) {
        this.destroyDefect();
      }

      try {
        const { site, defects } = await this.ss.getClientSitePageData(
          this.listId,
          this.projectId
        );

        this.listDefects = defects;
        this.listDefects.sort(SortHelper.sortDefects);
        this.list = site;

        this.loadingList = false;
        return true;
      } catch (ex) {
        this.cx.handle(ex, true);
        this.loadingList = false;
        this.router.navigate(["/client", this.projectHashId]);
        return false;
      }
    } else {
      this.loadingList = false;
      this.router.navigate(["/client", this.projectHashId]);
      return false;
    }
  }
  /**
   * Get the defect
   */
  async getDefect(defectId?: DefectPrimaryKeyId): Promise<void> {
    try {
      if (!this.projectId) return;

      if (defectId) {
        this.loadingDefect = true;

        if (this.uid === undefined) {
          const clientIds = await this.us.checkAndGetClientIds();

          if (clientIds.tenantId && clientIds.userId) {
            this.uid = clientIds.userId;
          } else {
            return Promise.reject("Error, please logout and then try again.");
          }
        }

        const { defect, photos } = await this.ds.getClientDefect(
          defectId,
          this.projectId
        );

        this.defect = defect;
        this.oldDefectStatus = defect.status;
        this.oldDefectStatus = this.oldDefectStatus;
        this.defectPhotos = [...photos];
        this.originalDefectPhotos = [...photos];
        if (!environment.production) {
          console.log("GOT DEFECT", this.defect, this.defectPhotos);
        }
      } else {
        // new
        this.defect = {
          cloudId: -1 as DefectPrimaryKeyId,
          defectNumberString: "NEW",
          defectNumber: 0,
          area: "",
          element: "",
          issue: "",
          comments: "",
          created: new Date(),
          completed: undefined,
          dueDate: undefined,
          status: 0,
          priority: 1,
          lastModified: 0,
        };
        this.defectPhotos = [];
        this.originalDefectPhotos = [];
        this.originalDefectContractors = [];
        // Sets default contractors for a new defect
        this.defectContractorIds = [
          ...this.contractors
            .filter((o) => o.defaultAssignment)
            .map((o) => o.contractorCloudId),
        ];
      }
    } catch (ex) {
      const exMessage = JSON.stringify(ex);
      if (exMessage.includes("Unable to find defect in database")) {
        this.as.showAlert({
          title: "Error",
          message:
            "Defect not found, it may have been deleted. Try refreshing the page.",
        });
        this.cx.logWarning(ex);
      } else if (ex && !exMessage.includes("#401")) {
        await this.cx.handle(
          ex,
          true,
          `Error retrieving defect from server: ${
            exMessage ?? ex ?? "Unknown error"
          }`
        );
      } else {
        await this.cx.handle(ex, true);
      }
      // Refresh list data
      this.showDefectInPanel = false;
      this.showDefectInModal = false;
      await this.getProjectList(true);
    } finally {
      this.loadingDefect = false;
    }
  }

  async onListChange(
    data?: IProjectProgressSummaryUpdate | undefined
  ): Promise<void> {
    if (data && this.lists.length > 0) {
      // Update counter if data available
      // Fired from save/delete defect
      for (const list of this.lists) {
        if (list.cloudId == data.listId) {
          // update site counter
          if (data.oldStatus === null && data.newStatus !== null) {
            list.defectsCount += 1;
          } else if (data.defectId === null) {
            list.defectsCount =
              list.defectsCount - 1 >= 0 ? list.defectsCount - 1 : 0;
          }

          // update progress counters if needed
          if (data.newStatus != data.oldStatus) {
            list.newCount = this._setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.newCount,
              0
            );
            list.inProgressCount = this._setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.inProgressCount,
              1
            );
            list.completeCount = this._setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.completeCount,
              2
            );
          }
          break;
        }
      }
    }
  }

  async onDefectChange(
    isNewDefect: boolean,
    data?: IProjectProgressSummaryUpdate
  ): Promise<void> {
    if (isNewDefect && this.defect) {
      await this.getProjectList();
    } else if (this.defect) {
      const defectId = this.defect.cloudId;
      const index = this.listDefects.findIndex((o) => {
        return o.cloudId === defectId;
      });
      if (index > -1 && this.defect) {
        let photo = "";

        if (this.defectPhotos.length > 0) {
          photo = this.defectPhotos[0].fullPath;
        }

        this.listDefects[index] = {
          ...this.listDefects[index],
          area: this.defect?.area,
          element: this.defect?.area,
          issue: this.defect?.area,
          photo: photo,
          thumbnail: photo,
          status: this.defect?.status,
          assigned: this.defectContractorIds.length > 0 ? 1 : 0,
          created: this.defect?.created,
          completed: this.defect?.completed ?? null,
          selectedToMove: false,
        };

        this.listDefects.sort(SortHelper.sortDefects);
      }
    }

    this.onListChange(data);
  }

  /** */
  private _setProgressCounter(
    newStatus: number | null | undefined,
    oldStatus: number | null | undefined,
    count: number,
    type: number
  ): number {
    switch (type) {
      case newStatus:
        count += 1;
        break;
      case oldStatus:
        count -= 1;
        break;
    }
    return count;
  }
}
