import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { getAuth } from "firebase/auth";

import { UserService } from "src/app/services";
import { environment } from "src/environments/environment";

/** Authorisation guard */
@Injectable({
  providedIn: "root",
})
export class OptionsAdminGuard {
  /** @ignore */
  constructor(private router: Router, private us: UserService) {}
  /** Guard canActive handler */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const result = await Promise.all([
      this.checkAuthState(),
      this.us.awaitPremium(),
      this.us.awaitAdmin(),
    ]);

    const loggedIn = result[0];
    const isPremium = result[1];
    const isAdmin = result[2];

    if (!environment.production) {
      console.log(
        "OptionsAdminGuard loggedIn",
        loggedIn && isPremium && isAdmin
      );
    }
    if ((loggedIn && isPremium && isAdmin) || (loggedIn && !isPremium)) {
      return true;
    } else {
      this.router.navigate(["/options"]);
      return false;
    }
  }

  async checkAuthState(): Promise<boolean> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user != null) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
