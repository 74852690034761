import { Component, ViewChild } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AnalyticsService } from "../services";
import { HashService } from "../services";
import { ClientRegisterComponent } from "./ui/client-register/client-register.component";
import { RegisterComponent } from "./ui/register/register.component";
import { LoginWidgetComponent } from "./ui/login-widget/login-widget.component";
import { CardModule } from "primeng/card";
import { NgIf } from "@angular/common";

@Component({
  selector: "app-login",
  imports: [
    NgIf,
    CardModule,
    LoginWidgetComponent,
    RegisterComponent,
    ClientRegisterComponent,
  ],
  standalone: true,
  templateUrl: "./login.component.html",
  styleUrl: "./login.component.scss",
})
export class LoginPage {
  /** Login widget */
  @ViewChild("loginWidget", { static: true }) loginWidget: any;
  /** Register widget */
  @ViewChild("registerWidget", { static: true }) registerWidget: any;
  /**  */
  showRegister: boolean = false;
  /** Query Param */
  private readonly productId: string | undefined;
  /** Query Param */
  private readonly quantity: string | undefined;
  /** Query Param */
  private readonly discountCode: string | undefined;
  /** If query params are provided, this will redirect back to checkout */
  private readonly redirectToCheckout: boolean = false;
  /** If query params are provided, this will redirect back to checkout */
  readonly redirectToClient: boolean = false;
  /** If query params are provided, this will redirect back to checkout */
  readonly clientParamsInvalid: boolean = false;
  /** @ignore */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private analytics: AnalyticsService,
    private hash: HashService
  ) {
    this.analytics.pageView("DefectWise - Login", "login");

    const params: any = this.route.snapshot.queryParams;
    const mode: string | undefined = params["mode"];
    if (mode === "checkout") {
      this.productId = params["productId"];
      this.quantity = params["quantity"];
      this.discountCode = params["code"];
      this.redirectToCheckout = true;
    } else if (mode === "client") {
      this.redirectToClient = true;
      const decodedProjId = this.hash.decode<number>(
        this.route.snapshot.queryParams["id"] ?? ""
      );
      this.clientParamsInvalid = decodedProjId < 1;
    }
  }
  /**
   * Registration completed navigation
   */
  async registerCompleted() {
    let routeOpts: any[] = [];
    let queryParams: any = {};
    if (this.redirectToCheckout) {
      routeOpts = ["/billing", this.productId, this.quantity];
      if (this.discountCode) {
        queryParams["code"] = this.discountCode;
      }
    } else if (this.redirectToClient) {
      const projectHashId: any = this.route.snapshot.queryParams["id"] ?? "";
      routeOpts = ["/client", projectHashId];
    } else {
      routeOpts = ["/projects"];
    }

    this.router.navigate(routeOpts, { queryParams }).then(() => {
      this.showRegister = false;
    });
  }
  /**
   * Show register
   */
  showRegisterScreen() {
    try {
      if (this.loginWidget?.googleName) {
        this.registerWidget.registerForm.controls.firstname.setValue(
          this.loginWidget.googleName.firstname
        );
        this.registerWidget.registerForm.controls.lastname.setValue(
          this.loginWidget.googleName.lastname
        );
      }
    } catch (ex) {
      //
    }
    this.showRegister = true;
  }
}
