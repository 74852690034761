import { bootstrapApplication } from "@angular/platform-browser";
import { appConfig } from "./app/app.config";
import { AppComponent } from "./app/app.component";

import * as Sentry from "@sentry/angular";
import { environment } from "./environments/environment";
import packageJson from "../package.json";

Sentry.init({
  dsn: "https://ec517c893b194984b54cbe1bddb1ae75@o941297.ingest.sentry.io/6601097",
  release: "defectwise-web@" + packageJson.version,
  dist: "1",
  environment: environment.production ? "production" : "development",
  // debug: !environment.production,
  enabled: environment.production,
  integrations: [
    Sentry.browserTracingIntegration(),
    Sentry.replayIntegration({
      maskAllText: true,
      blockAllMedia: true,
    }),
  ],
  tracesSampleRate: environment.production ? 0.5 : 0,
  replaysOnErrorSampleRate: environment.production ? 1.0 : 0,
  // replaysSessionSampleRate: environment.production ? 0.1 : 0,
  // reducing to only capture errors as keep going over limit
  replaysSessionSampleRate: 0,
});

bootstrapApplication(AppComponent, appConfig).catch((err) =>
  console.error(err)
);
