import { AfterViewInit, Component, OnDestroy, OnInit } from "@angular/core";
import { RouterOutlet, RouterModule } from "@angular/router";

import { initializeApp } from "firebase/app";
import { getAuth } from "firebase/auth";
import { environment } from "src/environments/environment";

import {
  AnalyticsService,
  LogService,
  ServiceWorkerUpdateService,
  EventsService,
} from "./services";
import {
  defaultAlertConfig,
  defaultUpgradeAlertConfig,
} from "src/app/utils/constants";
import { LoadingSpinner, NotificationsPanelComponent } from "src/app/utils/ui";

import { register } from "swiper/element/bundle";

import { ALERT_SERVICE, AlertConfirm, AlertToast } from "./models";
import { ConfirmationService, MessageService } from "primeng/api";
import { ConfirmDialog } from "primeng/confirmdialog";
import { Toast } from "primeng/toast";

@Component({
  selector: "app-root",
  providers: [ConfirmationService, MessageService],
  imports: [
    RouterOutlet,
    RouterModule,
    ConfirmDialog,
    Toast,
    LoadingSpinner,
    NotificationsPanelComponent,
  ],
  templateUrl: "./app.component.html",
  styleUrl: "./app.component.scss",
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  title = "defects-web-app-v3";

  constructor(
    private log: LogService,
    private analytics: AnalyticsService,
    private sw: ServiceWorkerUpdateService,
    private events: EventsService,
    private confirmationService: ConfirmationService,
    private messageService: MessageService
  ) {
    this.initializeApplication();
    this.analytics.init();
    this.subscribeToMessageServices();
  }

  ngOnInit() {
    // TODO: Set the default locale
    // More languages here: https://github.com/primefaces/primelocale
    // const userLang = navigator.language || "en";
    // let locale: Translation = translations[userLang.toLowerCase()];
    // if (locale === undefined) {
    // Default to en if no matching language is found
    //   locale = translations["en"];
    // }
    // this.config.setTranslation({
    //   ...locale,
    // });
  }

  /** Registers the swiper module */
  ngAfterViewInit(): void {
    this.initializeApplication();
  }

  ngOnDestroy() {
    this.events.destroy(ALERT_SERVICE.confirm);
    this.events.destroy(ALERT_SERVICE.upgrade);
    this.events.destroy(ALERT_SERVICE.toast);
  }

  /** Runs everytime the app boots up */
  initializeApplication() {
    if (!environment.production) {
      console.log("initializeApp()");
    }

    register();
    try {
      this.sw.checkForUpdates();
    } catch (ex) {
      console.error(ex);
    }

    initializeApp(environment.firebaseConfig);
    // Check if user is logged in and attach
    const auth = getAuth();
    auth.onAuthStateChanged((user) => {
      if (user !== null) {
        this.log.identifyUser({
          email: user.email,
          id: user.uid,
        });
        this.analytics.identifyUser(user.email ?? "");
      }
    });
  }

  /**
   * Subscribes to the events service for Alerts and Toasts
   */
  private subscribeToMessageServices() {
    // confirm
    this.events.subscribe<AlertConfirm>(ALERT_SERVICE.confirm, (o) =>
      this.dialogSubscription(o)
    );

    this.events.subscribe<AlertToast>(ALERT_SERVICE.toast, (o) =>
      this.toastSubscription(o)
    );

    this.events.subscribe<AlertToast>(ALERT_SERVICE.upgrade, (o) =>
      this.dialogUpgradeSubscription(o)
    );
  }

  private dialogSubscription(data: AlertConfirm): void {
    this.confirmationService.confirm({
      ...data,
      ...defaultAlertConfig,
      icon: data.destructive ? "pi pi-exclamation-triangle" : undefined,
    });
  }

  private dialogUpgradeSubscription(data: AlertConfirm): void {
    this.confirmationService.confirm({
      ...data,
      ...defaultUpgradeAlertConfig,
    });
  }

  private toastSubscription(data: AlertToast): void {
    this.messageService.add({
      ...data,
      life: 3000,
    });
  }
}
