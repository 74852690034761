<swiper-container #swiperRef class="w-full h-screen overflow-hidden" init="false">
  <!-- PROJECT TYPE -->

  <swiper-slide class="w-[310px] sm:w-[450px] pb-6" (onCancel)="reset()">
    <app-swiper-page>
      <ng-template>
        <h4>Select Project Type</h4>
        <p>Select the project type that most closely matches your case. Lists will be optimised for your
          selection.</p>
        <app-project-type-picker [(ngModel)]="type" (onChange)="setType($event)"></app-project-type-picker>
      </ng-template>
    </app-swiper-page>
  </swiper-slide>

  <!-- LIST COUNTER -->
  <swiper-slide class="w-[310px] sm:w-[450px] pb-6">
    <app-swiper-page [title]="'Add Project Lists'" (onCancel)="reset()">
      <ng-template>
        <div class="p-2"></div>
        <p-button label="Back" icon="pi pi-arrow-left" severity="info" (click)="back()"></p-button>

        <!-- SITE SET UP -->
        <div [style.display]="type === 0 || type === 4 ? 'none' : ''">
          <p>Use the tool below to auto-populate your project with lists. You may add or remove lists at any time.</p>
          <div class="p-2"></div>

          <app-project-list-generator [(ngModel)]="newProjectData['siteCounter']"></app-project-list-generator>

          <div class="p-card p-4 flex flex-row justify-center items-center">
            <p class="m-0 mr-4">Include Common Area</p>
            <p-checkbox [(ngModel)]="newProjectData['commonArea']" [binary]="true" inputId="binary"></p-checkbox>
          </div>

          <div class="mt-4 flex flex-col items-center">
            <p-button label="Continue" styleClass="mb-3" severity="secondary" (click)="onListComplete()"></p-button>
            <p-button label="Skip" [text]="true" (click)="skip()"></p-button>
          </div>
        </div>

        <!-- PROJECT CLONE -->
        <div [style.display]="type === 4 ? '' : 'none'">
          <div>Select a project to clone below.</div>
          <div class="p-2"></div>

          <div style="max-height: 90vh; height: calc(100vh - 400px)">
            <p-table #datatable [value]="projects" stripedRows dataKey="id" [loading]="loadingProjects"
              [globalFilterFields]="['reference','name','type']" [scrollable]="true" scrollHeight="flex">
              <!-- Filter toolbar -->
              <ng-template #caption>
                <div class="flex flex-row">
                  <p-iconfield class="mr-2">
                    <p-inputicon styleClass="pi pi-search" />
                    <input pInputText type="text" (input)="search($event)" placeholder="Search Projects" />
                  </p-iconfield>
                </div>
              </ng-template>
              <!-- Table Header Row -->
              <ng-template #header>
                <tr>
                  <th style="text-align: center;" pSortableColumn="type">
                    <p-sortIcon field="type"></p-sortIcon>
                  </th>
                  <th pSortableColumn="name">
                    Project<p-sortIcon field="name"></p-sortIcon>
                  </th>
                  <th pSortableColumn="reference">
                    Ref<p-sortIcon field="reference"></p-sortIcon>
                  </th>
                  <th style="width: 3rem"></th>
                </tr>

              </ng-template>
              <!-- body Row -->
              <ng-template #body let-project>
                <tr class="datatableRowHover" (click)="setClone($event, project)">
                  <td>
                    <div class="text-center">
                      <img class="thumb-icon" src="../../../assets/project-type-0-icon.svg"
                        *ngIf="project.type === 0" />
                      <img class="thumb-icon" src="../../../assets/project-type-1-icon.svg"
                        *ngIf="project.type === 1" />
                      <img class="thumb-icon" src="../../../assets/project-type-2-icon.svg"
                        *ngIf="project.type === 2" />
                      <img class="thumb-icon" src="../../../assets/project-type-3-icon.svg"
                        *ngIf="project.type === 3" />
                    </div>
                  </td>
                  <td><span class="td-hover">{{project.name}}</span></td>
                  <td><span class="td-hover">{{project.reference}}</span></td>
                  <td class="p-0">
                    <i class="pi pi-chevron-right"></i>
                  </td>
                </tr>
              </ng-template>
              <!-- emptymessage Row -->
              <ng-template #emptymessage let-columns>
                <tr>
                  <td [attr.colspan]="5">No projects found</td>
                </tr>
              </ng-template>
            </p-table>
          </div>
        </div>
      </ng-template>
    </app-swiper-page>
  </swiper-slide>

  <!-- PROJECT INFO -->
  <swiper-slide class="w-[310px] sm:w-[450px] pb-6">
    <app-swiper-page [showCreate]="true" [infoFormValid]="infoFormValid && uniqueRef" [loading]="loading"
      (onCreate)="createProject()" (onCancel)="reset()">
      <ng-template>
        <div class="p-2"></div>
        <p-button label="Back" icon="pi pi-arrow-left" severity="info" (click)="back()"></p-button>
        <p>Provide a project name and unique reference for the project. You can change this information from the
          project's details screen at any time.</p>

        <!-- Additional Clone Option -->
        <div class="pb-2 pt-2 flex flex-row justify-between items-center" *ngIf="type === 4">
          <div class="pr-2">
            <strong>Clone Defect Template Data</strong><br>
            <span class="text-sm">Clone existing Area, Element & Issues. Photos, assignments & comments will not be
              cloned. Disable this option to copy lists only.</span>
          </div>
          <p-inputSwitch [(ngModel)]="cloneDefects"></p-inputSwitch>
        </div>

        <div class="p-2"></div>
        <app-project-info #projectInfo [type]="type" [editMode]="false" [newProjData]="newProjectData">
        </app-project-info>
        <div class="p-3"></div>
      </ng-template>
    </app-swiper-page>
  </swiper-slide>

</swiper-container>