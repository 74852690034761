<div class="app-wrapper">
    <router-outlet></router-outlet>
</div>

<app-loading-spinner />

@defer (on immediate) {
<p-confirmdialog />
}

@defer (on immediate) {
<p-toast />
}

@defer (on immediate) {
<app-notifications-panel />
}