<div class="progress-bar-wrapper w-full flex flex-row items-stretch" [class.ready]="newCount !== undefined && inProgressCount !== undefined && completeCount !== undefined &&
  (newCount > 0 || inProgressCount > 0 || completeCount > 0)" [style.height]="height">
  <div *ngIf="showCounts && newCount && newCount > 0" class="flex-1 flex flex-row items-center">
    <div class="progress-bar-number">{{newCount}} New</div>
  </div>
  <div *ngIf="inProgressCount && inProgressCount > 0" [style.width]="getInProgress"
    class="progress-inprogress flex flex-row items-center">
    <div class="progress-bar-number" *ngIf="showCounts">{{inProgressCount}} In progress</div>
  </div>
  <div *ngIf="completeCount && completeCount > 0" [style.width]="getComplete"
    class="progress-complete flex flex-row items-center">
    <div class="progress-bar-number" *ngIf="showCounts">{{completeCount}} Complete</div>
  </div>
</div>