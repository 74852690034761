<div class="flex flex-row gap-1 py-1 px-2 mb-2 rounded-md bg-slate-100 outline-none">
    <p-button
        [styleClass]="isBoldActive ? 'bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 hover:bg-black/5' : ''"
        size="small" [text]="true" (click)="setBold()">
        <i-lucide [img]="BoldIcon" [size]="16"></i-lucide>
    </p-button>

    <p-button
        [styleClass]="isItalicActive ? 'bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 hover:bg-black/5' : ''"
        size="small" [text]="true" (click)="setItalic()">
        <i-lucide [img]="ItalicIcon" [size]="16"></i-lucide>
    </p-button>

    <p-button
        [styleClass]="isUnderlineActive ? 'bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 hover:bg-black/5' : ''"
        size="small" [text]="true" (click)="setUnderline()">
        <i-lucide [img]="UnderlineIcon" [size]="16"></i-lucide>
    </p-button>

    <p-button
        [styleClass]="isListOrderedActive ? 'bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 hover:bg-black/5' : ''"
        size="small" [text]="true" (click)="setListOrdered()">
        <i-lucide [img]="ListOrderedIcon" [size]="16"></i-lucide>
    </p-button>

    <p-button
        [styleClass]="isBulletListActive ? 'bg-black/10 text-neutral-800 dark:bg-white/20 dark:text-neutral-200 hover:bg-black/5' : ''"
        size="small" [text]="true" (click)="setList()">
        <i-lucide [img]="ListIcon" [size]="16"></i-lucide>
    </p-button>
</div>