import {
  Component,
  OnDestroy,
  Input,
  CUSTOM_ELEMENTS_SCHEMA,
  OnInit,
} from "@angular/core";

import { Editor } from "@tiptap/core";
import StarterKit from "@tiptap/starter-kit";
import { Underline } from "@tiptap/extension-underline";
import {
  TiptapEditorDirective,
  // TiptapFloatingMenuDirective,
  // TiptapBubbleMenuDirective,
} from "ngx-tiptap";

import { TextEditorToolbarComponent } from "./ui/text-editor-toolbar/text-editor-toolbar.component";

@Component({
  selector: "app-text-editor",
  templateUrl: "./text-editor.component.html",
  styleUrl: "./text-editor.component.scss",
  imports: [
    TiptapEditorDirective,
    // TiptapFloatingMenuDirective,
    // TiptapBubbleMenuDirective,
    TextEditorToolbarComponent,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
  standalone: true,
})
export class TextEditorComponent implements OnInit, OnDestroy {
  protected editor: Editor;
  protected value: string = "";
  @Input() initialValue: string | null = "";

  /** @ignore */
  constructor() {
    this.editor = new Editor({
      extensions: [
        StarterKit.configure({
          dropcursor: false,
          heading: false,
          horizontalRule: false,
          blockquote: false,
          codeBlock: false,
          paragraph: {
            HTMLAttributes: {
              class: "prose-p !my-1",
            },
          },
        }),
        Underline,
      ],
      editorProps: {
        attributes: {
          class:
            "prose prose-neutral min-h-[300px] max-w-none py-2 px-3 border-0 outline-none",
        },
      },
    });
  }

  ngOnInit(): void {
    this.editor.commands.setContent(this.initialValue ?? "");
  }

  ngOnDestroy(): void {
    this.editor.destroy();
  }

  get html() {
    return this.editor.getHTML();
  }
}
