<div>
    <h1 class="text-center">
        Profile & Reporting Details
    </h1>
    <p class="text-center">
        These details are used for customising your defect reports.
    </p>
</div>

<form [formGroup]="registerForm" (submit)="register()">
    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-user"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocomplete="given-name" autocapitalize="words"
                enterkeyhint="next" formControlName="firstname" placeholder="First Name" />
            <p-inputgroup-addon *ngIf="!registerForm.controls['firstname'].dirty">
                <i class="pi pi-exclamation-circle text-danger"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['firstname'].dirty">
                <i class="pi pi-times-circle text-danger" *ngIf="!registerForm.controls['firstname'].valid"></i>
                <i class="pi pi-check-circle text-success" *ngIf="registerForm.controls['firstname'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-user"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocomplete="family-name" autocapitalize="words"
                enterkeyhint="next" formControlName="lastname" placeholder="Last Name" />
            <p-inputgroup-addon *ngIf="!registerForm.controls['lastname'].dirty">
                <i class="pi pi-exclamation-circle text-danger"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['lastname'].dirty">
                <i class="pi pi-times-circle text-danger" *ngIf="!registerForm.controls['lastname'].valid"></i>
                <i class="pi pi-check-circle text-success" *ngIf="registerForm.controls['lastname'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-building"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocapitalize="words" enterkeyhint="done"
                formControlName="company" placeholder="Company Name" />
            <p-inputgroup-addon *ngIf="!registerForm.controls['company'].dirty">
                <i class="pi pi-exclamation-circle text-danger"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['company'].dirty">
                <i class="pi pi-times-circle text-danger" *ngIf="!registerForm.controls['company'].valid"></i>
                <i class="pi pi-check-circle text-success" *ngIf="registerForm.controls['company'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-users"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocapitalize="words" spellcheck="true" enterkeyhint="next"
                formControlName="position" placeholder="Company Position" />
            <p-inputgroup-addon><i class="pi pi-check-circle text-success"></i></p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2 register-screen">
        <p-select [options]="timezones" formControlName="timezone" [filter]="true" filterBy="value"
            class="tz-dropdown w-full">

            <ng-template pTemplate="selectedItem">
                <div class="flex items-center gap-2">
                    <div class="flex flex-row items-center">
                        <div class="p-inputgroup-addon mr-3"><i class="pi pi-clock"></i></div> Timezone: {{
                        selectedTimezone }}
                    </div>
                </div>
            </ng-template>

            <ng-template pTemplate="empty">
                <div class="flex items-center gap-2">
                    <div class="flex flex-row items-center">
                        <div class="p-inputgroup-addon mr-3"><i class="pi pi-clock"></i></div> Select a Timezone
                    </div>
                </div>
            </ng-template>

            <ng-template let-tzItem pTemplate="item">
                <div class="flex items-center gap-2">
                    <div>{{ tzItem.label }}</div>
                </div>
            </ng-template>
        </p-select>
    </div>

    <div class="pt-2 flex flex-col items-stretch">
        <p-button type="submit" styleClass="w-full mb-2 register-btn" severity="secondary" label="Complete"
            [disabled]="!registerForm.valid"></p-button>

        <p-button styleClass="w-full mb-2 text-primary skip-btn" [text]="true" label="Skip"
            (click)="skip()"></p-button>
    </div>

</form>