import { Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "defect-summary-micro-chart",
    templateUrl: "./summary-micro-chart.component.html",
    styleUrls: ["./summary-micro-chart.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class SummaryMicroChartComponent {
  @Input() newCount: number | undefined;
  @Input() inProgressCount: number | undefined;
  @Input() completeCount: number | undefined;
  @Input() showCounts: boolean = false;
  readonly defaultHeight: number = 14;

  constructor() {}

  get height(): string {
    return this.showCounts ? 'inherit' : `${this.defaultHeight}px`;
  }

  get getInProgress(): string {
    const total = this.getTotal;
    const inProgressPercentage =
      this.inProgressCount && this.inProgressCount > 0
        ? Math.round((this.inProgressCount / total) * 100)
        : 0;
    return inProgressPercentage > 0 ? `${inProgressPercentage}%` : "0";
  }

  get getComplete(): string {
    const total = this.getTotal;
    const completedPercentage =
      this.completeCount && this.completeCount > 0
        ? Math.round((this.completeCount / total) * 100)
        : 0;
    return completedPercentage > 0 ? `${completedPercentage}%` : "0";
  }

  /** return total number of counts for all type */
  get getTotal(): number {
    return (
      (this.newCount ?? 0) +
      (this.inProgressCount ?? 0) +
      (this.completeCount ?? 0)
    );
  }
}
