import { Component, Input, Output, EventEmitter } from "@angular/core";
import { ButtonModule } from "primeng/button";

@Component({
  selector: "app-large-button",
  templateUrl: "./large-button.component.html",
  standalone: true,
  imports: [ButtonModule],
})
export class LargeButton {
  @Output() onClick: EventEmitter<Event> = new EventEmitter<Event>();
  @Input() heading: string = "Example";
  @Input() label: string = "Example message";
  @Input() icon: string = "pi-trash";
  @Input() severity?:
    | "danger"
    | "success"
    | "info"
    | "primary"
    | "secondary"
    | undefined;
  @Input() class?: string = "";
  @Input() disabled?: boolean = false;
  @Input() loading?: boolean = false;

  onButtonClick(ev: Event): void {
    this.onClick.emit(ev);
  }

  get styleClass(): string {
    let className = "w-full";
    if (this.class) {
      className += " " + this.class;
    }
    return className;
  }
}
