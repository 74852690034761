<!-- DETAILS -->
<p class="!mt-0 text-center">You have been invited to join a new team!</p>
<p class="text-secondary text-center font-bold">
  Organisation: @if (invite?.company !== '') {
  <span>{{invite?.company}}</span>
  } @else {
  <span>Undefined</span>
  }
</p>

<p-divider></p-divider>

<div class="flex flex-col flex-auto">

  <app-large-button heading="Accept Invite"
    label="Transfer your profile and existing project cloud data to the organisation. You will retain Owner rights to your projects."
    icon="pi-user-plus" (onClick)="accept(true)" [disabled]="loading" [loading]="loadingAll" />

  <app-large-button class="mt-2" heading="Ignore Invite" label="Ignore the invite." icon="pi-eye-slash"
    (onClick)="ignoreComfirm()" [disabled]="loading" [loading]="loadingIgnore" />

</div>

<p-divider></p-divider>

<!-- INFORMATION CARD -->
<p><b>What happens after account transfer?</b></p>
<p class="text-sm">
  After transfer, the organisation Admin will have control over your account details. Request Admin access
  for additional access rights.<br><br>
  Any on-device project data will continue to remain available and you may sync this to the new
  organisation after account transfer.
</p>