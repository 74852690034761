<!-- EDIT MODE HEADER -->
<div class="p-card p-3" *ngIf="editMode">
  <div class="flex flex-row items-center">
    <div class="pr-4">
      <img class="thumb-icon" src="../../../assets/project-type-0-icon.svg" *ngIf="type == 0">
      <img class="thumb-icon" src="../../../assets/project-type-1-icon.svg" *ngIf="type == 1">
      <img class="thumb-icon" src="../../../assets/project-type-2-icon.svg" *ngIf="type == 2">
      <img class="thumb-icon" src="../../../assets/project-type-3-icon.svg" *ngIf="type == 3">
    </div>

    <div class="flex-1">
      List Details
    </div>

    <div *ngIf="editMode">
      <button pButton type="button" label="Save" [loading]="loading" (click)="save()"
        [disabled]="!listForm.valid || !listForm.dirty"></button>
    </div>
  </div>
</div>

<div class="mb-8"></div>

<form [formGroup]="listForm">
  <!-- DESCRIPTION / UNIT -->
  <div class="field w-full mb-8 mt-4">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="descriptionInput" name="description" class="w-full" autocapitalize="words"
          inputmode="text" formControlName="unit">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger" *ngIf="!listForm.controls['unit'].valid"></i>
          <i class="pi pi-check-circle text-success" *ngIf="listForm.controls['unit'].valid"></i>
        </p-inputicon>
      </p-iconfield>
      <label for="descriptionInput">
        <span *ngIf="type === 2">Description or Unit Number ({{placeholderText}})</span>
        <span *ngIf="type !== 2">Description ({{placeholderText}})</span>
      </label>
    </p-floatlabel>
  </div>

  <!-- FLOOR -->
  <div class="field w-full mb-8" *ngIf="type === 2 || type === 3">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="floorInput" name="floor" class="w-full" autocapitalize="words"
          inputmode="text" formControlName="floor">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger" *ngIf="!listForm.controls['floor'].valid"></i>
          <i class="pi pi-check-circle text-success" *ngIf="listForm.controls['floor'].valid"></i>
        </p-inputicon>
      </p-iconfield>
      <label for="inputName">Floor (e.g. LGR, L01, etc)</label>
    </p-floatlabel>
  </div>

  <!-- ADDRESS type==1 -->
  <div class="field w-full mb-8" *ngIf="type === 1 || type === 3">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="addressInput" name="address" class="w-full" autocapitalize="words"
          inputmode="text" formControlName="address">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger" *ngIf="!listForm.controls['address'].valid"></i>
          <i class="pi pi-check-circle text-success" *ngIf="listForm.controls['address'].valid"></i>
        </p-inputicon>
      </p-iconfield>
      <label for="addressInput">Address (e.g. street no., road name or full address)</label>
    </p-floatlabel>
  </div>

  <!-- Notes -->
  <div class="field w-full mb-8" *ngIf="editMode">
    <p-floatlabel>
      <textarea rows="5" cols="30" pTextarea type="text" pInputText id="notesInput" name="notes" class="w-full"
        autocapitalize="words" inputmode="text" formControlName="notes"></textarea>
      <label for="notesInput">Notes</label>
    </p-floatlabel>
  </div>

</form>

<div class="text-center mb-8" *ngIf="!editMode">
  <p-button label="Save List Details" [loading]="loading" (click)="save()"
    [disabled]="!listForm.valid || !listForm.dirty"></p-button>
</div>

<!-- Information Card -->
<div class="text-sm" *ngIf="!editMode">
  <div class="flex flex-col flex-auto">

    <div class="flex flex-row px-2 py-4 bg-slate-50 rounded">
      <div class="left-col w-4/12">
        <h4 class="!m-0">Project</h4>
        <i class="pi pi-arrow-down text-secondary mt-1" style="font-size: 1.2rem;"></i>
      </div>
      <div class="flex-1">Represents a collection of lists (or zones).<br>
        <b>e.g. An inspection of an apartment complex or office block.</b>
      </div>
    </div>

    <div class="flex flex-row px-2 py-4 my-2 current-page-row surface-secondary rounded">
      <div class="left-col w-4/12">
        <h4 class="!m-0">List</h4>
        <i class="pi pi-arrow-down text-secondary mt-1" style="font-size: 1.2rem;"></i>
      </div>
      <div class="flex-1">Lists represent large zones or spaces within a project.<br>
        <b>e.g. House, Apartment, Office Floor, etc.</b>
      </div>
    </div>

    <div class="flex flex-row p-2 bg-slate-50 rounded">
      <div class="left-col w-4/12">
        <h4 class="!m-0">Defect<br>Areas</h4>
      </div>
      <div class="flex-1">Lists contain smaller areas or rooms added from the defects page.<br>
        <b>e.g. Bedroom, Kitchen, Meeting Room, etc.</b>
      </div>
    </div>
  </div>

</div>