import {
  Component,
  Input,
  ContentChild,
  TemplateRef,
  EventEmitter,
  Output,
} from "@angular/core";
import { NgIf, NgTemplateOutlet } from "@angular/common";
import { RouterLink } from "@angular/router";
import { ButtonModule } from "primeng/button";
import { ToolbarModule } from "primeng/toolbar";

@Component({
  selector: "app-swiper-page",
  templateUrl: "./swiper-page.component.html",
  styleUrls: ["./swiper-page.component.scss"],
  standalone: true,
  imports: [ToolbarModule, ButtonModule, RouterLink, NgIf, NgTemplateOutlet],
})
export class SwiperPageComponent {
  @ContentChild(TemplateRef) template!: TemplateRef<any>;
  @Input() showCreate: boolean = false;
  @Input() infoFormValid: boolean = true;
  @Input() title: string = "Project Setup";
  @Input() loading: boolean = false;
  @Output() onCreate: EventEmitter<any> = new EventEmitter<any>();
  @Output() onCancel: EventEmitter<any> = new EventEmitter<any>();
  /** @ignore */
  constructor() {}

  protected createProject() {
    this.onCreate.emit();
  }

  protected cancel() {
    this.onCancel.emit();
  }
}
