import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { getAuth } from "firebase/auth";

import { UserService } from "src/app/services";

/** Authorisation guard */
@Injectable({
  providedIn: "root",
})
export class ClientAuthGuard {
  /** @ignore */
  constructor(private router: Router, private us: UserService) {}
  /** Guard canActive handler */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const loggedIn = await this.checkAuthState();

    try {
      const clientIds = await this.us.checkAndGetClientIds();
      // console.log("AuthGuard loggedIn", loggedIn, next, state);
      if (loggedIn && clientIds.tenantId && clientIds.userId) {
        return true;
      }
    } catch (ex) {
      // ignore
    }
    // else
    if (loggedIn) {
      await this.us.logout();
    }
    this.router.navigate(["/login"], {
      queryParams: { mode: "client", id: next.params["projectid"] },
    });
    return false;
  }

  async checkAuthState(): Promise<boolean> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user != null) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
