<div class="h-full flex flex-col">
    <app-text-editor-toolbar [editor]="editor" />
    <tiptap-editor class="flex-1 overflow-auto border-neutral-200 focus:border-slate-400 border-1 rounded-md outline-none" [editor]="editor" [(ngModel)]="value"></tiptap-editor>
</div>

<!-- <tiptap-floating-menu [editor]="editor">
    <app-text-editor-toolbar [editor]="editor" />
</tiptap-floating-menu> -->

<!-- <tiptap-bubble-menu [editor]="editor">
    <app-text-editor-toolbar [editor]="editor" />
</tiptap-bubble-menu> -->