import { Component, Input, Output, EventEmitter } from "@angular/core";

import {
  ApiService,
  CatchErrorService,
  AlertService,
  UserService,
  EventsService,
} from "src/app/services";
import { MixpanelService } from "src/app/services/mixpanel.service";
import { IUserInvite, IUserInviteStatus } from "src/app/models";

import { LargeButton } from "src/app/utils/ui";
import { ButtonModule } from "primeng/button";
import { DividerModule } from "primeng/divider";

@Component({
  selector: "app-home-view-invite",
  templateUrl: "./view-invite.component.html",
  styleUrls: ["./view-invite.component.scss"],
  standalone: true,
  imports: [DividerModule, ButtonModule, LargeButton],
})
export class ViewInviteComponent {
  /** */
  @Output() closeFn: EventEmitter<boolean> = new EventEmitter();
  /** Passed in through modalctrl */
  @Input("invite") invite: IUserInvite | undefined;
  /** Controls for ui button loading */
  loading: boolean = false;
  loadingProfile: boolean = false;
  loadingAll: boolean = false;
  loadingIgnore: boolean = false;
  /** @ignore */
  constructor(
    private api: ApiService,
    private cx: CatchErrorService,
    private alert: AlertService,
    private us: UserService,
    private events: EventsService,
    private mixpanel: MixpanelService
  ) {}

  /**
   * Accepts an invite
   * - Edit the Invite "Accepted"
   * - GetLogin
   * - Refresh Home
   *
   * @param transferProjectData
   */
  protected async accept(transferProjectData: boolean): Promise<void> {
    this.loading = true;
    this.loadingProfile = !transferProjectData;
    this.loadingAll = transferProjectData;

    try {
      if (this.invite === undefined) return;

      const result = await this.api.invite.editInvite(
        this.invite.id,
        IUserInviteStatus.accept,
        transferProjectData
      );

      if (result.success) {
        // get new user data and save locally
        const { user } = await this.us.getLogin();

        if (user !== null) {
          this.us.evaluateSubscription({
            tier: user.tier,
            enabled: !user.archived,
            // newly accepted invites default to normal access
            admin: false,
            defectLimit: user.defectLimit,
            defectCount: user.defectCount,
          });
          // Refresh home page data
          this.events.publish<void>("home:reload", undefined);
          //
          this.mixpanel.setUserProperties({
            plan: user.tier > 0 ? "Paid" : "Free",
            tenantId: user.tenantId,
            company: user.company,
          });
          // Clear the invite component
          this.closeFn.emit(false);
        }
      }
    } catch (ex) {
      await this.cx.handle(ex, true);
    } finally {
      this.loading = false;
      this.loadingProfile = false;
      this.loadingAll = false;
    }
  }

  /**
   * Display confirm message for ignore selection
   */
  protected async ignoreComfirm(): Promise<void> {
    await this.alert.showConfirm(
      "Ignore Invite",
      "Confirm",
      "Ignore this invitation? The team admin can invite you again at a later time.",
      "Ignore",
      () => this.ignoreInvite()
    );
  }

  /**
   * Display prompt and ignore invite
   */
  protected async ignoreInvite(): Promise<void> {
    this.loading = false;
    this.loadingIgnore = true;

    try {
      if (this.invite === undefined) return;

      const result = await this.api.invite.editInvite(
        this.invite.id,
        IUserInviteStatus.reject
      );

      if (result.success) {
        await this.alert.showToast("Invitation ignored.", "warn");
        this.closeFn.emit(false);
      }
    } catch (ex) {
      await this.cx.handle(ex, true);
    } finally {
      this.loading = false;
      this.loadingIgnore = false;
    }
  }
}
