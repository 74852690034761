<div>
    <h1 class="text-center">
        Owner Portal Registration
    </h1>
    <p class="text-center">
        Please enter the following details to complete registration and access the project portal.
    </p>
</div>

<div class="w-full mb-2 text-center">
    <strong>Are you a Property Manager? <span class="text-danger">*</span></strong>&nbsp; <p-select
        [(ngModel)]="roleValue" [options]="roleOptions" optionLabel="name" optionValue="value"
        placeholder="Select Item"></p-select>
</div>

<form [formGroup]="registerForm" (submit)="register()">


    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-user"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocomplete="given-name" autocapitalize="words"
                enterkeyhint="next" formControlName="firstname" placeholder="First Name" />
            <p-inputgroup-addon *ngIf="!registerForm.controls['firstname'].dirty">
                <i class="pi pi-exclamation-circle"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['firstname'].dirty">
                <i class="pi pi-times-circle" *ngIf="!registerForm.controls['firstname'].valid"></i>
                <i class="pi pi-check-circle" *ngIf="registerForm.controls['firstname'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-user"></i></p-inputgroup-addon>
            <input pInputText type="text" class="w-full" autocomplete="family-name" autocapitalize="words"
                enterkeyhint="next" formControlName="lastname" placeholder="Last Name">
            <p-inputgroup-addon *ngIf="!registerForm.controls['lastname'].dirty">
                <i class="pi pi-exclamation-circle"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['lastname'].dirty">
                <i class="pi pi-times-circle" *ngIf="!registerForm.controls['lastname'].valid"></i>
                <i class="pi pi-check-circle" *ngIf="registerForm.controls['lastname'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon><i class="pi pi-phone"></i></p-inputgroup-addon>
            <input pInputText type="tel" class="w-full" autocapitalize="words" enterkeyhint="done"
                formControlName="phone" placeholder="Contact Number" />
            <p-inputgroup-addon *ngIf="!registerForm.controls['phone'].dirty">
                <i class="pi pi-exclamation-circle"></i>
            </p-inputgroup-addon>
            <p-inputgroup-addon *ngIf="registerForm.controls['phone'].dirty">
                <i class="pi pi-times-circle" *ngIf="!registerForm.controls['phone'].valid"></i>
                <i class="pi pi-check-circle" *ngIf="registerForm.controls['phone'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <p class="text-danger" *ngIf="!registerForm.valid || !roleValue">Please complete all fields.</p>

    <div class="pt-2 flex flex-col items-stretch">
        <button pButton type="submit" class="mb-2 client-register-btn" label="Complete"
            [disabled]="!registerForm.valid || !roleValue"></button>
    </div>

</form>