import {
  Component,
  Input,
  forwardRef,
  Output,
  EventEmitter,
} from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from "@angular/forms";

import { Card } from 'primeng/card';

@Component({
    selector: "app-project-type-picker",
    templateUrl: "./project-type-picker.page.html",
    styleUrls: ["./project-type-picker.page.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectTypePickerPage),
            multi: true,
        },
    ],
    standalone: true,
    imports: [Card]
})
export class ProjectTypePickerPage implements ControlValueAccessor {
  @Input() _type: number | undefined;
  @Output() onChange: EventEmitter<any> = new EventEmitter<any>();
  /** @ignore */
  constructor() {}

  get type() {
    return this._type;
  }

  set type(val) {
    this._type = val;
    this.propagateChange(this._type);
    this.onChange.emit(this._type);
  }

  setType(val: number) {
    this.type = val;
  }

  /** @override */
  writeValue(value: number | undefined) {
    this._type = value;
  }

  propagateChange = (_: any) => {};

  /** @override */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  /** @override */
  registerOnTouched() {}
}
