import { Component, Input, Output, EventEmitter } from "@angular/core";
import { NgIf } from "@angular/common";

@Component({
    selector: "app-image-attachment",
    templateUrl: "./image-attachment.component.html",
    styleUrls: ["./image-attachment.component.scss"],
    standalone: true,
    imports: [NgIf]
})
export class ImageAttachmentComponent {
  /** Image index */
  @Input() index: number | undefined;
  /** Image index */
  @Input() fullPath: string | undefined;
  /** Hides the delete button */
  @Input() enabled: boolean | undefined;
  /** Trigger when preview image runs */
  @Output() onPreview: EventEmitter<any> = new EventEmitter<any>();
  /** Trigger when preview image runs */
  @Output() onDelete: EventEmitter<any> = new EventEmitter<any>();
  /** @ignore */
  constructor() {}

  get enabledDelete(): boolean {
    return this.enabled !== false;
  }

  protected preview(): void {
    this.onPreview.emit(this.index);
  }

  protected delete(): void {
    this.onDelete.emit(this.index);
  }
  /** Not implemented yet */
  protected download(): void {}
}
