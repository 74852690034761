import { Component } from "@angular/core";
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

import {
  CatchErrorService,
  SiteService,
  AnalyticsService,
  ANALYTICS_EVENTS,
} from "src/app/services";
import { ProjectProvider } from "src/app/providers/project.provider";
import { List, IProjectType } from "src/app/models";

import { TextareaModule } from "primeng/textarea";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { FloatLabel } from "primeng/floatlabel";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { NgIf } from "@angular/common";

/** Site Information component */
@Component({
  selector: "app-site-info",
  templateUrl: "./site-info.component.html",
  styleUrls: ["./site-info.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    TextareaModule,
    FloatLabel,
    IconField,
    InputIcon,
  ],
})
export class SiteInfoComponent {
  /** Project object */
  // readonly project: Project.Project | undefined;
  /** ISteView object */
  readonly list: List.List | undefined;
  /** Defines whether form is edit or new */
  readonly editMode: boolean | undefined;
  /** Site info form */
  listForm: FormGroup;
  /** control save button state */
  loading: boolean = false;
  /** @ignore */
  constructor(
    private cx: CatchErrorService,
    private ss: SiteService,
    private analytics: AnalyticsService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig,
    private pp: ProjectProvider
  ) {
    // this.project = this.dialogConfig.data["project"];
    this.list = this.dialogConfig.data["list"];
    this.editMode = this.dialogConfig.data["editMode"] ?? false;

    this.listForm = new FormGroup({
      address: new FormControl(""),
      unit: new FormControl(""),
      floor: new FormControl(""),
      notes: new FormControl(""),
    });

    if (this.type === 1) {
      this.listForm.controls["address"].setValidators([Validators.required]);
    }

    if (this.type === 0 || this.type === 2 || this.type === 3) {
      this.listForm.controls["unit"].setValidators([Validators.required]);
    }

    if (this.editMode && this.list !== undefined) {
      this.listForm.controls["floor"].setValue(this.list.floor ?? "");
      this.listForm.controls["unit"].setValue(this.list.unit ?? "");
      this.listForm.controls["address"].setValue(this.list.address ?? "");
      this.listForm.controls["notes"].setValue(this.list.notes ?? "");
    } else if (this.pp.project !== undefined) {
      this.listForm.controls["address"].setValue(this.pp.project.address ?? "");
    }
  }

  get project() {
    return this.pp.project;
  }

  get type(): IProjectType {
    return this.pp.project?.type ?? 0;
  }

  /** Generic placeholder text */
  get placeholderText(): string {
    switch (this.type) {
      case 0:
        return "E.g. Main Residence";
      case 1:
        return "Optional";
      case 2:
        return "e.g. Unit 1A or Common Area";
      case 3:
        return "e.g. Suite 1 or Level 12 Offices";
    }
    return "E.g. Main Residence";
  }

  /**
   * - Update existing, or
   * - Create new
   */
  protected async save(): Promise<void> {
    // If in edit mode, do update instead
    this.loading = true;
    try {
      if (this.editMode) {
        this.analytics.trackCustomEvent(ANALYTICS_EVENTS.listEdited);
        await this.update();
      } else {
        this.analytics.trackCustomEvent(ANALYTICS_EVENTS.listCreated);
        await this.create();
      }
    } catch (ex) {
      if (ex !== undefined && JSON.stringify(ex).indexOf("#401") < 0) {
        await this.cx.handle(ex, true);
      }
    } finally {
      this.loading = false;
    }
  }

  /**
   * Create the site
   */
  private async create(): Promise<void> {
    try {
      let cloudChanged: boolean = false;

      const { unit, address, floor } = this.listForm.value;

      let saveSite: List.List = {
        projectCloudId: this.project!.id,
        unit: `${unit}`.trim(),
        address: `${address}`.trim(),
        floor: `${floor}`.trim(),
        notes: "",
        // not used in save
        projectAddress: "",
        cloudId: -1,
        type: 1,
        lastModified: 0,
      };

      // Save to cloud, get cloudId back
      const cloudResult = await this.ss.createCloudSite(
        saveSite,
        this.project!.id
      );
      cloudChanged = cloudResult.lastModified !== undefined;
      saveSite = cloudResult.site;

      // Check results and finish up
      if (cloudChanged) {
        this.pp.onListChange();
        this.dismiss(true, "refresh");
      } else {
        this.dismiss(false, "cancel");
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * Update the site
   */
  private async update(): Promise<void> {
    try {
      let cloudChanged: boolean = false;

      const { unit, address, floor, notes } = this.listForm.value;

      let saveSite: List.List = {
        ...this.list,
        cloudId: this.list?.cloudId ?? -1,
        projectCloudId: this.list?.projectCloudId ?? -1,
        projectAddress: this.list?.projectAddress ?? "",
        unit: `${unit}`.trim(),
        address: `${address}`.trim(),
        floor: `${floor}`.trim(),
        notes: `${notes}`.trim(),
        type: this.list?.type ?? 0,
        lastModified: this.list?.lastModified ?? 0,
      };

      // Save to cloud, get cloudId back
      if (saveSite.cloudId < 0) {
        const cloudResult = await this.ss.createCloudSite(
          saveSite,
          this.project!.id
        );
        saveSite = cloudResult.site;
        cloudChanged = cloudResult.lastModified !== undefined;
      } else {
        const cloudResult = await this.ss.editCloudSite(
          saveSite,
          this.project!.id
        );
        cloudChanged = cloudResult.lastModified !== undefined;
      }

      // Check results and finish up
      if (cloudChanged) {
        this.pp.onListChange();
        this.dismiss(true, "refresh");
      } else {
        this.dismiss(false, "cancel");
      }
    } catch (ex) {
      return Promise.reject(ex);
    }
  }

  /**
   * @param {"cancel" | "refresh"} role
   */
  private dismiss(success: boolean, role: "cancel" | "refresh"): void {
    this.dialogRef.close({ success, role });
  }
}
