import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import { Browser } from "@capacitor/browser";

import {
  UserService,
  AnalyticsService,
  CatchErrorService,
  BillingService,
  AlertService,
  LoadingController,
} from "../services";

import {
  DefectWiseProducts,
  IHttpLogin,
  ProductEnvironmentItem,
  StripeCustomerId,
} from "../models";

import { loadStripe, Stripe } from "@stripe/stripe-js";
import { environment } from "../../environments/environment";

import { Card } from "primeng/card";
import { DividerModule } from "primeng/divider";
import { ChipModule } from "primeng/chip";
import { TooltipModule } from "primeng/tooltip";
import { NgIf } from "@angular/common";
import { ButtonModule } from "primeng/button";

// Example for dev: http://localhost:8100/billing/price_1LaugWL9P7w9VoDKJTGfAkDJ/10
// With promo code: http://localhost:8100/billing/price_1LaugWL9P7w9VoDKJTGfAkDJ/1?code=NEWDEFECT20

@Component({
  selector: "app-checkout",
  templateUrl: "./checkout.page.html",
  styleUrls: ["./checkout.page.scss"],
  standalone: true,
  imports: [ButtonModule, NgIf, TooltipModule, ChipModule, DividerModule, Card],
})
export class CheckoutPage implements OnInit {
  /** Current users email */
  email: string | null = null;
  /** Gets product name from environment for displaying on UI */
  productName: string;
  /** user login data */
  userData: IHttpLogin | null = null;
  /** stripe object for open payment portal */
  private stripe: Stripe | null = null;
  /** current session */
  private session: any;
  /** Stripe customer id */
  private customerId: StripeCustomerId | null = null;
  /** productId form query params */
  readonly productId: DefectWiseProducts | undefined;
  /** quantity form query params */
  readonly quantity: number;
  /** discount from query params */
  promoCode: string | undefined;
  /** URLS */
  readonly urlDefectWise: string = "https://www.defectwise.com.au";
  readonly urlStripe: string = "https://stripe.com";
  /** @ignore */
  constructor(
    private loadingCtrl: LoadingController,
    private us: UserService,
    private billing: BillingService,
    private cx: CatchErrorService,
    private analytics: AnalyticsService,
    private route: ActivatedRoute,
    private router: Router,
    private alert: AlertService
  ) {
    this.analytics.pageView("Account - View Cart", "billing");
    const params = this.route.snapshot.params;
    this.productId = params["productId"];
    this.quantity = isNaN(Number(params["quantity"]))
      ? 1
      : Number(params["quantity"]);

    // apply coupon if it is a valid code
    const queryParams = this.route.snapshot.queryParams;
    const { promoCodes }: any = environment;
    if (promoCodes[queryParams["code"]]) {
      this.promoCode = queryParams["code"];
    }

    if (this.productId) {
      let prods: any = environment.products;
      this.productName = prods[this.productId]?.["item_name"];
    } else {
      this.productName = "Error determinging selected product";
    }

    if (this.productId) {
      this.analytics.viewCart(this.productId, this.quantity, this.promoCode);
    }
  }
  /**
   *
   */
  async ngOnInit(): Promise<void> {
    await this.loadingCtrl.present({ message: "Loading" });

    try {
      this.email = await this.us.getEmail();
      // Email is from auth, will be null if not logged in
      if (this.email === null) {
        await this.loadingCtrl.dismiss();
        await this.redirect();
        return;
      }

      this.loadStripeApi();

      if (this.userData === null) {
        const logResult = await this.us.getLogin();
        this.userData = logResult.user;
        this.customerId = this.userData?.stripeCustomerId ?? null;
      }

      let subscriptionId: any;
      if (this.customerId !== null && this.customerId !== "") {
        try {
          subscriptionId = await this.billing.getSubscription(this.customerId);
        } catch (ex) {
          // no sub
        }
      }

      await this.loadingCtrl.dismiss();

      if (subscriptionId) {
        await this.alert.showAlert({
          title: "Active Subscription Found",
          message:
            "This account has an active subscription. View your account to update your subscription details.",
          handler: async () => {
            await this.router.navigate(["/home"]);
          },
        });
      }
    } catch (ex) {
      this.cx.handle(ex);
      await this.loadingCtrl.dismiss();
    }
  }
  /**
   * Connects and loads stripe API
   */
  private async loadStripeApi(): Promise<void> {
    try {
      if (this.stripe === null) {
        this.stripe = await loadStripe(environment.stripeKey);
      }
    } catch (ex) {
      this.cx.handle(ex, true);
    } finally {
      return Promise.resolve();
    }
  }
  /**
   * Create Stripe Session
   * Open Stripe Portal
   */
  protected async checkout(): Promise<void> {
    if (!this.productId || this.userData === null) {
      this.cx.handle(
        `Checkout Failed - ${this.productId} - ${this.userData}`,
        false
      );
      return;
    } else {
      this.cx.logMessage("Checkout Started - " + this.productId);
    }

    await this.loadingCtrl.present({ message: "Processing" });

    try {
      const { products, promoCodes }: any = environment;
      const product: ProductEnvironmentItem = products[this.productId];

      if (product) {
        this.analytics.beginCheckout(
          this.productId,
          this.quantity,
          this.promoCode
        );

        const authUser = await this.us.getCurrentUser();

        // Check & create customer account if not existing
        if (authUser !== null) {
          this.email = authUser.email ?? "";

          if (this.customerId === null || this.customerId === "") {
            const name: string = authUser?.displayName ?? "";

            const customer = await this.billing.createCustomer(
              this.email,
              name,
              authUser?.phoneNumber ?? ""
            );

            assertCustomerId(customer.id);
            this.customerId = customer.id;
          }

          if (this.customerId !== null && this.customerId !== "") {
            const promoId: string | undefined = this.promoCode
              ? promoCodes[this.promoCode]
              : undefined;

            this.session = await this.billing.getSession(
              this.customerId,
              product.item_id,
              this.quantity,
              this.email,
              promoId
            );
          }
        }

        // Double check stripe is loaded - in case of timing or error
        if (this.stripe === null) {
          await this.loadStripeApi();
        }

        await this.loadingCtrl.dismiss();

        if (this.stripe !== null) {
          const { error } = await this.stripe.redirectToCheckout({
            sessionId: this.session.id,
          });

          if (error) {
            console.log("Checkout error", error);
            console.error("Checkout error", error);
            this.alert.showAlert({
              title: "Error",
              message:
                "An unknown error occured during checkout, contact support if issues persist",
            });
          }
        }
      } else {
        await this.loadingCtrl.dismiss();
        this.alert.showAlert({
          title: "Error",
          message:
            "Error, product does not exist, contact support if issues persist",
        });
        return Promise.reject(
          "Error, product does not exist, contact support if issues persist"
        );
      }
    } catch (ex) {
      this.cx.handle(ex, false);
      await this.loadingCtrl.dismiss();
      this.alert.showAlert({
        title: "Error",
        message:
          "Checkout Error, contact support if issues persist. Error: " + ex,
      });
    }
  }
  /**
   * Open a link in browser
   * @param {string} url
   */
  async openLink(url: string): Promise<void> {
    await Browser.open({ url: url, windowName: "_blank" });
  }
  /**
   * Log the user out and return to login screen
   */
  async logout(): Promise<void> {
    await this.us.logout();
    await this.redirect();
  }
  /** */
  async redirect(): Promise<void> {
    let queryParams: any = {};
    if (this.productId) {
      queryParams["productId"] = this.productId;
    }
    if (this.productId) {
      queryParams["quantity"] = this.quantity;
    }
    if (this.productId && this.quantity) {
      queryParams["mode"] = "checkout";
    }
    if (this.promoCode) {
      queryParams["code"] = this.promoCode;
    }
    await this.router.navigate(["/login"], { queryParams }).then(() => {
      window.location.reload();
    });
  }

  async removePromoCode(): Promise<void> {
    await this.alert.showConfirm(
      "Remove Promo Code",
      "",
      "Are you sure you want to remove this code?",
      "Remove",
      () => {
        this.promoCode = undefined;
      }
    );
  }
}

function assertCustomerId(value: string): asserts value is StripeCustomerId {
  if (value === "") throw new Error("Customer id not available");
}
