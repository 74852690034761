{
  "name": "defectwise",
  "description": "DefectWise Web Portal",
  "version": "3.0.3.2",
  "author": "interApptive Pty Ltd",
  "homepage": "https://www.defectwise.com.au/",
  "scripts": {
    "ng": "ng",
    "start": "ng serve",
    "start:prod": "ng serve --configuration=production",
    "build": "ng build",
    "watch": "ng build --watch --configuration=development",
    "test": "ng test",
    "lint": "ng lint",
    "format": "prettier --config ./.prettierrc --list-different \"src/{app,environments}/**/*{.ts,.html,.scss}\"",
    "build:prod": "ng build --configuration=production --source-map && npm run sentry:sourcemaps",
    "sentry:sourcemaps": "sentry-cli sourcemaps inject --org interapptive-b4 --project defectwise-web www/browser",
    "sentry:upload": "sentry-cli sourcemaps upload --release=defectwise-web@3.0.3.2 --dist=1 --org interapptive-b4 --project defectwise-web www/browser"
  },
  "private": true,
  "dependencies": {
    "@angular/animations": "^19.0.0",
    "@angular/common": "^19.0.0",
    "@angular/compiler": "^19.0.0",
    "@angular/core": "^19.0.0",
    "@angular/fire": "^19.0.0-rc.4",
    "@angular/forms": "^19.0.0",
    "@angular/platform-browser": "^19.0.0",
    "@angular/platform-browser-dynamic": "^19.0.0",
    "@angular/router": "^19.0.0",
    "@angular/service-worker": "^19.0.5",
    "@capacitor/browser": "^5.1.0",
    "@capacitor/core": "^5.5.0",
    "@capacitor/device": "^5.0.6",
    "@capacitor/preferences": "^5.0.6",
    "@capacitor/share": "^5.0.8",
    "@primeng/themes": "^19.0.5",
    "@sentry/angular": "^8.47.0",
    "@stripe/stripe-js": "^1.52.0",
    "@tiptap/extension-placeholder": "^2.11.0",
    "@tiptap/extension-underline": "^2.11.0",
    "@tiptap/starter-kit": "^2.11.0",
    "cropro": "^1.2.1",
    "firebase": "^11.1.0",
    "hashids": "^2.3.0",
    "lucide-angular": "^0.469.0",
    "mixpanel-browser": "^2.58.0",
    "moment-timezone": "^0.5.46",
    "ngx-colors": "^3.6.0",
    "ngx-tiptap": "^12.0.0",
    "primeicons": "^7.0.0",
    "primeng": "^19.0.5",
    "quill": "^2.0.3",
    "rxjs": "~7.8.0",
    "swiper": "^11.1.15",
    "tslib": "^2.3.0",
    "zone.js": "~0.15.0"
  },
  "devDependencies": {
    "@angular-devkit/build-angular": "^19.0.6",
    "@angular/cli": "^19.0.6",
    "@angular/compiler-cli": "^19.0.0",
    "@sentry/cli": "^2.39.1",
    "@tailwindcss/typography": "^0.5.15",
    "@types/jasmine": "~5.1.0",
    "@types/mixpanel-browser": "^2.50.2",
    "autoprefixer": "^10.4.20",
    "jasmine-core": "~5.4.0",
    "karma": "~6.4.0",
    "karma-chrome-launcher": "~3.2.0",
    "karma-coverage": "~2.2.0",
    "karma-jasmine": "~5.1.0",
    "karma-jasmine-html-reporter": "~2.1.0",
    "postcss": "^8.4.49",
    "tailwindcss": "^3.4.17",
    "tailwindcss-primeui": "^0.3.4",
    "typescript": "~5.6.2"
  }
}
