import type { Project, List, IProjectType } from "../models";
/**
 * Helper class for sorting data
 *
 * All functions should be static.
 *
 */
export default class SortHelper {
  /**
   * Custom sort function for site display
   * @param projectType
   * @private
   */
  static defaultListsSort(
    sites: Project.ListView[] | any[],
    projectType: IProjectType
  ): Project.ListView[] {
    let result: Project.ListView[] = [];
    switch (projectType) {
      case 0:
        result = sites.sort(this.sortListTypeHouse);
        break;
      case 1:
        result = sites.sort(this.sortListTypeDupe);
        break;
      case 2:
      case 3:
        result = sites.sort(this.sortListTypeOffice);
        break;
      default:
        result = sites;
        break;
    }
    return result;
  }
  /** Custom sort */
  private static sortListTypeHouse(a: Project.ListView, b: Project.ListView) {
    const aVal = a.unit.toLowerCase();
    const bVal = b.unit.toLowerCase();
    return aVal.localeCompare(bVal, undefined, {
      numeric: true,
      sensitivity: "base",
    });
    // const reA = /[^a-zA-Z]/g;
    // const reN = /[^0-9]/g;
    // var aA = a.unit.replace(reA, "");
    // var bA = b.unit.replace(reA, "");
    // if (aA === bA) {
    //   var aN = parseInt(a.unit.replace(reN, ""), 10);
    //   var bN = parseInt(b.unit.replace(reN, ""), 10);
    //   return aN === bN ? 0 : aN > bN ? 1 : -1;
    // } else {
    //   return aA > bA ? 1 : -1;
    // }
  }
  /** Custom sort */
  private static sortListTypeDupe(a: Project.ListView, b: Project.ListView) {
    return (
      a.address
        .toLowerCase()
        .localeCompare(b.address.toLowerCase(), undefined, {
          numeric: true,
          sensitivity: "base",
        }) ||
      a.unit.toLowerCase().localeCompare(b.unit.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
  /** Custom sort */
  private static sortListTypeOffice(a: Project.ListView, b: Project.ListView) {
    return (
      a.floor.toLowerCase().localeCompare(b.floor.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: "base",
      }) ||
      a.unit.toLowerCase().localeCompare(b.unit.toLowerCase(), undefined, {
        numeric: true,
        sensitivity: "base",
      })
    );
  }
  /** Custom sort */
  static sortDefects(a: List.DefectView, b: List.DefectView) {
    const areaA = a.area ?? "";
    const areaB = b.area ?? "";
    const elementA = a.element ?? "";
    const elementB = b.element ?? "";
    const issueA = a.issue ?? "";
    const issueB = b.issue ?? "";
    return (
      areaA.toLowerCase().localeCompare(areaB.toLowerCase()) ||
      elementA.toLowerCase().localeCompare(elementB.toLowerCase()) ||
      issueA.toLowerCase().localeCompare(issueB.toLowerCase())
    );
  }
}
