import { Component, Input } from "@angular/core";
import { Editor } from "@tiptap/core";

import {
  LucideAngularModule,
  Bold,
  Italic,
  Underline,
  ListOrdered,
  List,
} from "lucide-angular";

import { ButtonModule } from "primeng/button";

@Component({
  selector: "app-text-editor-toolbar",
  imports: [ButtonModule, LucideAngularModule],
  templateUrl: "./text-editor-toolbar.component.html",
  styleUrl: "./text-editor-toolbar.component.scss",
})
export class TextEditorToolbarComponent {
  @Input() editor: Editor | undefined;

  readonly BoldIcon = Bold;
  readonly ItalicIcon = Italic;
  readonly UnderlineIcon = Underline;
  readonly ListIcon = List;
  readonly ListOrderedIcon = ListOrdered;

  get isBoldActive() {
    return this.editor?.isActive("bold");
  }

  get isItalicActive() {
    return this.editor?.isActive("italic");
  }

  get isUnderlineActive() {
    return this.editor?.isActive("underline");
  }

  get isListActive() {
    return this.editor?.isActive("bulletList");
  }

  get isListOrderedActive() {
    return this.editor?.isActive("orderedList");
  }

  get isBulletListActive() {
    return this.editor?.isActive("bulletList");
  }

  setBold() {
    this.editor?.chain().focus().toggleBold().run();
  }

  setItalic() {
    this.editor?.chain().focus().toggleItalic().run();
  }

  setUnderline() {
    this.editor?.chain().focus().toggleUnderline().run();
  }

  setList() {
    this.editor?.chain().focus().toggleBulletList().run();
  }

  setListOrdered() {
    this.editor?.chain().focus().toggleOrderedList().run();
  }
}
