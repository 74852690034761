<div class="h-screen w-screen flex flex-col justify-around items-center content-wrapper p-2">

  <div class="limit-width">

    <p-card styleClass="p-6">
      <h3 class="text-secondary">Success</h3>
      <h1>Your account is ready to go!</h1>

      <div class="prose prose-neutral">
        <ol>
          <li>If you haven't already, download DefectWise using the links below.</li>
          <li><span>Sync projects you've already created to access them online:</span>
            <ul>
              <li>Open the project on your device.</li>
              <li>Use the menu icon in the top right of the project.</li>
            </ul>
          </li>
          <li>Visit the <a class="text-secondary" [routerLink]="['/options']" target="_blank"><b>Options</b></a> screen
            to access other settings like report customisation.</li>
          <li>If you selected multiple users, invite team members here <a class="text-secondary"
              [routerLink]="['/options', 'team']" target="_blank"><b>Options > Team</b></a>.</li>
        </ol>
      </div>

      <div class="p-4 text-center mt-4">
        <p-button class="finish-btn" icon="pi pi-arrow-left" label="Return Home to Get Started"
          title="Return to the Home Screen" routerLink="/projects" />
      </div>

      <p-divider class="my-6" />

      <div class="flex flex-wrap pb-4 pt-6">

        <div class="w-full md:w-6/12 flex justify-center">
          <a [href]="urlAppleDownload" title="Download on the App Store">
            <img height="60" alt="App Store Logo" src="../../assets/download-ios-dark.png" />
          </a>
        </div>

        <div class="w-full md:w-6/12 flex justify-center pt-6 md:pt-0">
          <a [href]="urlGoogleDownload" title="Get it on Google Play">
            <img height="60" alt="Google Play Logo" src="../../assets/download-android-dark.png" />
          </a>
        </div>
      </div>

    </p-card>

  </div>

</div>

<!-- Google conversion -->
<span class="custEmail" style="display:none;">{{userEmail}}</span>