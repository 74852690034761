import { Component, Output, EventEmitter } from "@angular/core";
import { NgIf } from "@angular/common";
import { ActivatedRoute, Router } from "@angular/router";
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import {
  ApiService,
  UserService,
  CatchErrorService,
  AnalyticsService,
  ANALYTICS_EVENTS,
  LoadingController,
} from "src/app/services";

import { MixpanelService } from "src/app/services/mixpanel.service";
import { timezoneData, type TimeZone } from "src/data/timezones";
import * as moment from "moment-timezone";

import { environment } from "src/environments/environment";

import { ButtonModule } from "primeng/button";
import { SharedModule } from "primeng/api";
import { Select } from "primeng/select";
import { InputTextModule } from "primeng/inputtext";
import { InputGroup } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";

/** Register new user component */
@Component({
  selector: "register-widget",
  templateUrl: "./register.component.html",
  styleUrls: ["./register.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    NgIf,
    Select,
    SharedModule,
    ButtonModule,
    InputGroup,
    InputGroupAddonModule,
  ],
})
export class RegisterComponent {
  /** Register complete event output */
  @Output() registerComplete: EventEmitter<any> = new EventEmitter<any>();
  /** Register form object */
  registerForm: FormGroup;
  /** If query params are provided, this will redirect back to checkout */
  readonly redirectToClient: boolean = false;
  /** If query params are provided, this will redirect back to checkout */
  readonly clientProjectId: string = "";
  /** @ignore */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private us: UserService,
    private api: ApiService,
    private cx: CatchErrorService,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private mixpanel: MixpanelService
  ) {
    const params: any = this.route.snapshot.queryParams;
    const mode: string | undefined = params["mode"];
    if (mode === "client") {
      this.redirectToClient = true;
      this.clientProjectId = params["id"];
    }

    let tzInit = "";
    const tzGuess = moment.tz.guess();
    // check if tzGuess is a valid option in timezoneData
    if (!timezoneData.find((tz) => tz.value === tzGuess)) {
      tzInit = tzGuess;
    }

    this.registerForm = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      company: new FormControl(
        "",
        this.redirectToClient ? [] : [Validators.required]
      ),
      position: new FormControl(""),
      timezone: new FormControl(tzGuess, [Validators.required]),
    });
  }

  /** Dropdown list options */
  get timezones() {
    return timezoneData;
  }

  /** Dropdown selected value for UI */
  get selectedTimezone() {
    const tzObj = (timezoneData as TimeZone[]).find(
      (tz) => tz.value === this.registerForm.value.timezone
    );
    return tzObj?.label || this.registerForm.value.timezone || "";
  }

  /**
   * Save available settings
   */
  skip() {
    this.analytics.trackCustomEvent(ANALYTICS_EVENTS.loginRegisterSkipped);
    this.register();
  }

  /**
   * Register logic
   */
  async register(): Promise<void> {
    try {
      await this.loadingCtrl.present();

      if (this.redirectToClient === true) {
        // ignore, this is handled in the client-register component
      } else {
        await this.doRegister();
        // Navigate and Reset Form
        this.router.navigate(["/projects"]).then(() => {
          this.registerForm.controls["firstname"].setValue("");
          this.registerForm.controls["lastname"].setValue("");
          this.registerForm.controls["position"].setValue("");
          this.registerForm.controls["company"].setValue("");
          this.registerForm.reset();
          this.registerComplete.emit(true);
        });
      }

      await this.loadingCtrl.dismiss();
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.cx.handle(ex, true, "Unable to complete registration.");
    }
  }

  /**
   * Creates new firestore document and saves settings locally for reports
   * @returns {boolean} boolean for success and exception for error
   */
  private async doRegister(): Promise<boolean> {
    if (!environment.production) {
      console.log("REGISTER: saveUserSettings()");
    }

    try {
      const { firstname, lastname, position, company, timezone } =
        this.registerForm.value;

      const result = await this.api.user.createUser({
        firstname,
        lastname,
        position,
        company,
        timezone,
      });

      if (result.success) {
        // setting id takes a moment to get fb user
        setTimeout(async () => {
          const email = await this.us.getEmail();
          const tenantId = await this.us.checkAndGetTenantId();
          this.mixpanel.trackEvent("sign_up");
          this.mixpanel.setUserProperties({
            name: `${firstname} ${lastname}`.trim(),
            company,
            plan: "Free",
            email: email ?? undefined,
            tenantId: tenantId ?? undefined,
          });
        }, 50);
        await this.us.getLogin();
      }

      return Promise.resolve(result.success);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
}
