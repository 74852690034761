<div class="progress-bar-wrapper w-full shadow-md rounded overflow-hidden">
  <div class="w-full flex flex-row items-stretch overflow-hidden" *ngIf="data && getTotal > 0">
    <div class="flex-1">
      <defect-summary-micro-chart [newCount]="data.newCount" [inProgressCount]="data.inProgressCount"
        [completeCount]="data.completeCount" [showCounts]="true"></defect-summary-micro-chart>
    </div>

    <div class="text-center progress-bar-total">
      <div>{{getTotal}} Total Defects</div>
    </div>
  </div>

  <div class="w-full overflow-hidden flex flex-row items-center" *ngIf="!data || getTotal === 0">
    <div class="text-center progress-bar-total">
      {{getTotal === 0 ? '--' : getTotal}} Total Defects
    </div>
  </div>
</div>