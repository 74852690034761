<div class="flex flex-col">
  <div class="p-card card-btn p-3 mt-3 flex flex-row items-center" [class.selected]="type === 0" (click)="setType(0)">
    <img src="../../../../assets/project-type-0.svg">
    <div class="pl-4">
      <h3 class="mt-0 mb-2">House</h3>
      <p class="mt-0 mb-1">Reports are sorted by list description</p>
      <p class="mt-0 mb-1 text-sm">Suitable for a house with a unique street address.</p>
    </div>
  </div>

  <div class="p-card card-btn p-3 mt-3 flex flex-row items-center" [class.selected]="type === 1" (click)="setType(1)">
    <img src="../../../../assets/project-type-1.svg">
    <div class="pl-4">
      <h3 class="mt-0 mb-2">Townhouse or Duplex</h3>
      <p class="mt-0 mb-1">Reports are sorted by list address</p>
      <p class="mt-0 mb-1 text-sm">Suitable for multi-address projects.</p>
    </div>
  </div>

  <div class="p-card card-btn p-3 mt-3 flex flex-row items-center" [class.selected]="type === 2" (click)="setType(2)">
    <img src="../../../../assets/project-type-2.svg">
    <div class="pl-4">
      <h3 class="mt-0 mb-2">Apartment or Units</h3>
      <p class="mt-0 mb-1">Reports are sorted by unit description</p>
      <p class="mt-0 mb-1 text-sm">Suitable for apartments located at a single address.</p>
    </div>
  </div>

  <div class="p-card card-btn p-3 mt-3 flex flex-row items-center" [class.selected]="type === 3" (click)="setType(3)">
    <img src="../../../../assets/project-type-3.svg">
    <div class="pl-4">
      <h3 class="mt-0 mb-2">Office</h3>
      <p class="mt-0 mb-1">Reports are sorted by floor, description and address</p>
      <p class="mt-0 mb-1 text-sm">Suitable for projects with multiple sites.</p>
    </div>
  </div>

  <div class="p-card card-btn p-3 mt-3 flex flex-row items-center" [class.selected]="type === 4" (click)="setType(4)">
    <div class="pl-4">
      <h3 class="mt-0 mb-2">Clone Project</h3>
      <p class="mt-0 mb-1"><b>Make a copy of an existing project</b></p>
      <p class="mt-0 mb-1 text-sm">The following data will be copied: Lists and Defect Area, Element and Issue fields.
        Photos, status and comments will not be copied.</p>
    </div>
  </div>
</div>

<!-- Import to force build to load css for p-card classes -->
<p-card styleClass="hidden"></p-card>