<div class="p-3"></div>
<form [formGroup]="contractorForm">
  <!-- COMPANY -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="inputCompany" class="w-full" autocapitalize="words" inputmode="text"
          formControlName="company">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger" *ngIf="!contractorForm.controls['company'].valid"></i>
          <i class="pi pi-check-circle text-success" *ngIf="contractorForm.controls['company'].valid"></i>
        </p-inputicon>
      </p-iconfield>
      <label for="inputCompany">Company</label>
    </p-floatlabel>
  </div>
  <!-- NAME -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputName" class="w-full" autocapitalize="words" inputmode="text"
        formControlName="name">
      <label for="inputName">Contractor Name</label>
    </p-floatlabel>
  </div>
  <!-- EMAIL -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputEmail" class="w-full" autocapitalize="words" inputmode="email"
        formControlName="email">
      <label for="inputEmail">Email</label>
    </p-floatlabel>
  </div>
  <!-- PHONE -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputPhone" class="w-full" inputmode="tel" formControlName="phone">
      <label for="inputPhone">Phone</label>
    </p-floatlabel>
  </div>
  <!-- DISCIPLINE -->
  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText id="inputDiscipline" class="w-full" autocapitalize="on" inputmode="text"
        formControlName="discipline">
      <label for="inputDiscipline">Discipline</label>
    </p-floatlabel>
  </div>

</form>

<!-- DISCIPLINE -->
<div class="w-full mb-8 flex flex-row items-center pl-2 pr-2" *ngIf="editMode">
  <div class="flex-1 pr-3">
    Enable Contractor<br />
    <span class="text-sm dms-color-medium">
      Deactivate to hide contractors from future projects.
    </span>
  </div>
  <p-inputSwitch [(ngModel)]="active"></p-inputSwitch>
</div>

<p-divider *ngIf="editMode" />

<!-- SAVE -->
<div class="text-center pb-2">
  <p-button label="Save Changes" [loading]="loading"
    [disabled]="!contractorForm.controls['company'].valid || loadingDelete" (click)="save()"></p-button>
</div>

<div class="text-center" *ngIf="editMode">

  <!-- DELETE -->
  <div class="p-2"></div>
  <p-button severity="danger" [text]="true" label="Delete Contractor" icon="pi pi-trash" [loading]="loadingDelete"
    [disabled]="loading" (click)="delete()" *ngIf="contractor?.isAssigned === false"></p-button>

  <span class="text-sm" *ngIf="contractor?.isAssigned == true">
    This contractor is assigned to a project or defect and cannot be deleted.<br />You may use the disable option
    instead.
  </span>
</div>