import { Pipe, PipeTransform } from "@angular/core";

// https://github.com/QuantumCatgirl/js_humanized_time_span/blob/master/humanized_time_span.js

@Pipe({
    name: "customDate",
    standalone: true
})
export class CustomDatePipe implements PipeTransform {
  //Date Formats must be be ordered smallest -> largest and must end in a format with ceiling of null
  date_formats: any = {
    past: [
      // { ceiling: 60, text: "$seconds seconds ago" },
      { ceiling: 60, text: "moments ago" },
      { ceiling: 3600, text: "$minutes minutes ago" },
      { ceiling: 86400, text: "$hours hours ago" },
      { ceiling: 2629744, text: "$days days ago" },
      { ceiling: 31556926, text: "$months months ago" },
      { ceiling: null, text: "$years years ago" },
    ],
    future: [
      { ceiling: 60, text: "in $seconds seconds" },
      { ceiling: 3600, text: "in $minutes minutes" },
      { ceiling: 86400, text: "in $hours hours" },
      { ceiling: 2629744, text: "in $days days" },
      { ceiling: 31556926, text: "in $months months" },
      { ceiling: null, text: "in $years years" },
    ],
  };
  //Time units must be be ordered largest -> smallest
  time_units: any[][] = [
    [31556926, "years"],
    [2629744, "months"],
    [86400, "days"],
    [3600, "hours"],
    [60, "minutes"],
    [1, "seconds"],
  ];

  transform(value: any, ...args: unknown[]): string {
    if (
      value === undefined ||
      value === null ||
      value === "" ||
      value === "NULL"
    ) {
      return "";
    }

    const ref_date: Date = new Date();

    let date: Date;
    if (typeof value !== "object") {
      date = new Date(value);
    } else {
      date = ref_date;
    }

    let seconds_difference: number =
      (ref_date.getTime() - date.getTime()) / 1000;
    let tense: string = "past";
    if (seconds_difference < 0) {
      tense = "future";
      seconds_difference = 0 - seconds_difference;
    }

    return this.render_date(
      this.get_format(tense, seconds_difference),
      seconds_difference
    );
  }

  private get_format(
    tense: string,
    seconds_difference: number
  ): CdpFormatOptions | null {
    for (let i = 0; i < this.date_formats[tense].length; i++) {
      if (
        this.date_formats[tense][i].ceiling == null ||
        seconds_difference <= this.date_formats[tense][i].ceiling
      ) {
        return this.date_formats[tense][i];
      }
    }
    return null;
  }

  private get_time_breakdown(seconds_difference: number): any {
    let seconds = seconds_difference;
    let breakdown: any = {};
    for (let i = 0; i < this.time_units.length; i++) {
      const occurences_of_unit = Math.floor(seconds / this.time_units[i][0]);
      seconds = seconds - this.time_units[i][0] * occurences_of_unit;
      breakdown[this.time_units[i][1]] = occurences_of_unit;
    }
    return breakdown;
  }

  private render_date(
    date_format: CdpFormatOptions | null,
    seconds_difference: number
  ): string {
    if (date_format == null) return "";

    const breakdown = this.get_time_breakdown(seconds_difference);
    const time_ago_text = date_format.text.replace(/\$(\w+)/g, function () {
      return breakdown[arguments[1]];
    });
    return this.depluralize_time_ago_text(time_ago_text, breakdown);
  }

  private depluralize_time_ago_text(
    time_ago_text: string,
    breakdown: any[]
  ): string {
    for (let i in breakdown) {
      if (breakdown[i] == 1) {
        let regexp = new RegExp("\\b" + i + "\\b");
        time_ago_text = time_ago_text.replace(regexp, function () {
          return arguments[0].replace(/s\b/g, "");
        });
      }
    }
    return time_ago_text;
  }
}

interface CdpFormats {
  past: CdpFormatOptions[];
  future: CdpFormatOptions[];
}

interface CdpFormatOptions {
  ceiling: number | null;
  text: string;
}
