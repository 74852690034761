<p-dialog [modal]="false" [(visible)]="showLoader" [closeOnEscape]="false" [closable]="false" [resizable]="false">
    <ng-template #headless>
        <div class="p-2 flex flex-row items-center justify-center"
            style="min-width: 80px; max-width: 160px; height: 80px;">
            <div class="p-2 spinner">
                <i-lucide class="" [img]="LoaderCircleIcon" [size]="38"></i-lucide>
            </div>
            @if (loadingMessage !== '') {
            <span class="ml-3 mr-2 leading-5">{{loadingMessage}}</span>
            }
        </div>
    </ng-template>
</p-dialog>