import {
  Component,
  OnInit,
  AfterViewInit,
  ViewChild,
  ElementRef,
  CUSTOM_ELEMENTS_SCHEMA,
} from "@angular/core";
import { FormsModule } from "@angular/forms";
import { Router } from "@angular/router";
import { NgIf } from "@angular/common";

import { Navigation, Pagination, Mousewheel, A11y } from "swiper/modules";
import { SwiperOptions, type Swiper } from "swiper/types";

import {
  HashService,
  ApiService,
  UserService,
  HomeService,
  CatchErrorService,
  EventsService,
} from "../services";

import { ProjectProvider } from "../providers";
import { ProjectInfoComponent } from "src/app/utils/ui";
import { IProjectType, IProjectView } from "../models";
import { environment } from "src/environments/environment";

import { InputTextModule } from "primeng/inputtext";
import { SharedModule } from "primeng/api";
import { CheckboxModule } from "primeng/checkbox";
import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { Table, TableModule } from "primeng/table";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";

import { ProjectListGeneratorPage } from "./ui/project-list-generator/project-list-generator.page";
import { ProjectTypePickerPage } from "./ui/project-type-picker/project-type-picker.page";
import { SwiperPageComponent } from "./ui/swiper-page/swiper-page.component";

@Component({
  selector: "app-create-project",
  templateUrl: "./create-project.page.html",
  styleUrls: ["./create-project.page.scss"],
  standalone: true,
  imports: [
    SwiperPageComponent,
    ProjectTypePickerPage,
    FormsModule,
    ButtonModule,
    ProjectListGeneratorPage,
    CheckboxModule,
    TableModule,
    SharedModule,
    InputTextModule,
    NgIf,
    InputSwitchModule,
    ProjectInfoComponent,
    IconField,
    InputIcon,
  ],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class CreateProjectPage implements OnInit, AfterViewInit {
  @ViewChild("datatable") datatable: Table | undefined;
  @ViewChild("swiperRef") swiperRef: ElementRef | undefined;
  swiper?: Swiper;
  /** Project setup slides element */
  @ViewChild("projectInfo", { read: ProjectInfoComponent })
  readonly projectInfo: ProjectInfoComponent | undefined;
  /** */
  newProjectData: { siteCounter: number; commonArea: boolean } = {
    siteCounter: 1,
    commonArea: false,
  };
  /** Project type */
  type: number | undefined;
  /** */
  projectToClone: IProjectView | undefined;
  /** */
  cloneDefects: boolean = true;
  /** */
  loading: boolean = false;
  /** loading clone data if not found from home */
  loadingProjects: boolean = false;
  /** Swiper config */
  readonly swiperParams: SwiperOptions;
  /** @ignore */
  constructor(
    private router: Router,
    private hash: HashService,
    private pp: ProjectProvider,
    private api: ApiService,
    private us: UserService,
    private home: HomeService,
    private cx: CatchErrorService,
    private events: EventsService
  ) {
    this.swiperParams = {
      modules: [Navigation, Pagination, A11y, Mousewheel],
      allowTouchMove: false,
      autoHeight: true,
      navigation: false,
      pagination: {
        enabled: false,
      },
      slidesPerView: 1,
      centeredSlides: true,
      effect: "fade",
      fadeEffect: {
        crossFade: true,
      },
      on: {
        init() {
          // ...
        },
      },
    };
  }

  get pageIndex(): number {
    return this.swiper?.activeIndex ?? 0;
  }

  get infoFormValid(): boolean {
    return this.projectInfo?.projectForm.valid ?? false;
  }

  get uniqueRef(): boolean {
    return this.projectInfo?.uniqueRef ?? false;
  }

  set slideIndex(val: number) {
    if (val > -1 && this.swiper) {
      this.swiper.slideTo(val);
    }
  }

  get projects(): IProjectView[] {
    return this.pp.projects;
  }

  /**
   * Check if pp.projects has been loaded, if not, attempt it
   */
  async ngOnInit(): Promise<void> {
    if (this.pp.projects.length === 0) {
      this.loadingProjects = true;
      try {
        const premium = await this.us.awaitPremium();
        if (premium && this.us.enabled) {
          const { projects } = await this.home.getHomePageData();
          this.pp.projects = [...projects];
        }
        this.loading = false;
      } catch (ex) {
        // homeService handles alert
      }
      this.loadingProjects = false;
    }
  }
  /**
   * Initializes the swiper after the view has been fully initialized.
   */
  ngAfterViewInit(): void {
    setTimeout(() => {
      // now we need to assign all parameters to Swiper element
      Object.assign(this.swiperRef?.nativeElement, this.swiperParams);
      // init
      this.swiperRef?.nativeElement.initialize();
      // assign to var for use
      this.swiper = this.swiperRef?.nativeElement.swiper;
    }, 100);
  }
  /** Set the project type */
  setType(type: number): void {
    this.projectInfo!.type = type;
    let newSlide = 1;
    if (type === IProjectType.HOUSE) {
      newSlide = 2;
    }

    this.newProjectData.siteCounter = 1;
    this.newProjectData.commonArea = false;
    this.cloneDefects = true;
    this.projectToClone = undefined;

    // wait before transitioning
    setTimeout(() => this.swiper?.slideTo(newSlide), 100);
  }
  /**
   * Set the project to clone data
   * @param _ event handler
   * @param project project data to clone
   */
  setClone(_: any, project: IProjectView): void {
    console.log(project.id, this.projectInfo!.projectForm.controls["name"]);

    this.projectToClone = { ...project };
    const { name, type, reference } = this.projectToClone;

    this.projectInfo!.type = type;
    this.projectInfo!.projectForm.setValue({
      name: name + " Copy",
      ref: reference + "-COPY",
      client: "",
      address: "",
      city: "",
      postcode: "",
      state: "",
      country: "",
    });

    let newSlide = 2;

    // wait before transitioning
    setTimeout(() => this.swiper?.slideTo(newSlide), 100);
  }
  /**
   * Create the project, either:
   * - Create, or
   * - Clone
   *
   * Redirects to project in both cases
   *
   * @return {Promise<void>}
   */
  async createProject(): Promise<void> {
    if (
      !this.projectInfo ||
      this.projectInfo?.projectForm.valid === false ||
      this.projectInfo?.uniqueRef === false
    ) {
      return;
    }

    this.loading = true;

    if (!environment.production) {
      console.log(this.projectInfo.newProjectData);
    }

    if (this.projectToClone) {
      await this.cloneProject();
    } else {
      // create project
      const { success, projectId } = await this.projectInfo?.save();
      if (success) {
        this.pp.destroy();
        // wait 1 second to make the system look like it's doing something
        setTimeout(async () => {
          this.loading = false;
          const hashId = this.hash.encode(projectId);
          await this.router.navigate(["project", hashId]);
          this.reset();
        }, 1000);
      }
    }
  }
  /**
   * Clone project and redirect
   *
   * @return {Promise<void>}
   */
  private async cloneProject(): Promise<void> {
    if (
      !this.projectInfo ||
      this.projectInfo?.projectForm.valid === false ||
      this.projectInfo?.uniqueRef === false
    ) {
      return;
    }

    if (this.projectToClone) {
      try {
        // clone project
        const { name, ref, client, address, city, postcode, state, country } =
          this.projectInfo.projectForm.value;

        const result = await this.api.projects.cloneProject(
          {
            name,
            ref,
            client,
            address,
            city,
            postcode,
            state,
            country,
            type: this.projectToClone.type,
          },
          this.projectToClone.id,
          this.cloneDefects
        );

        if (result.success) {
          this.pp.destroy();
          this.events.publish<void>("home:reload", undefined);
          // wait 1 second to make the system look like it's doing something
          setTimeout(async () => {
            this.loading = false;
            const hashId = this.hash.encode(result.data.cloudId);
            await this.router.navigate(["project", hashId]);
            this.reset();
          }, 1000);
        }
      } catch (ex) {
        this.cx.handle(ex, true, "Error cloning project: " + ex);
      }
    }
  }
  /** Complete the list number screen */
  onListComplete(): void {
    this.slideIndex = 2;
  }
  /** Skip the list number screen */
  skip(): void {
    this.newProjectData.commonArea = false;
    this.newProjectData.siteCounter = 1;
    this.slideIndex = 2;
  }
  /** */
  back(): void {
    let index = 0;
    if (this.type === IProjectType.HOUSE) {
      this.slideIndex = 0;
    } else {
      const realIndex = this.swiper?.realIndex ?? 0;
      index = Math.max(realIndex - 1, 0);
      this.slideIndex = index;
    }
    if (index === 0) {
      // allow transition animation to complete first
      setTimeout(() => {
        this.type = undefined;
      }, 250);
    }
  }
  /**
   * Reset the interface
   */
  reset(): void {
    this.newProjectData.siteCounter = 1;
    this.newProjectData.commonArea = false;
    this.cloneDefects = true;
    this.projectToClone = undefined;
    this.type = undefined;
    if (this.projectInfo) {
      this.projectInfo.resetForm();
      this.projectInfo.type = undefined;
    }
    this.swiper?.slideTo(0);
  }
  /**
   * Table search
   */
  protected search(ev: any) {
    this.datatable?.filterGlobal(ev.target.value ?? "", "contains");
  }
}
