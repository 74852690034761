<p-drawer [(visible)]="showNotificationsSideBar" header="Notifications" position="right"
    styleClass="w-full md:w-[30rem]">
    <!-- Invite Panel -->
    @if (invite !== undefined) {
    <p-divider class="mt-0" />

    <b>You have been invited to join {{invite!.company}}.</b><br>
    View the invite to accept or ignore the request.<br>
    <p-button label="View Invite" icon="pi pi-check" severity="secondary" styleClass="mt-2" size="small"
        (onClick)="openInvite()"></p-button>

    <p-divider />
    }

    <!-- Header (Mark as read) -->
    <div class="mb-6 flex flex-row justify-between items-center">
        <p class="!m-0 text-sm"><b>Latest</b></p>
        <p-button label="Mark all as read" icon="pi pi-check" severity="info" size="small"
            (click)="markAllAsRead()"></p-button>
    </div>

    <!-- Blank -->
    @if (showEmpty) {
    <span>No new notifications.</span>
    <p-divider />
    }


    <!-- Notifications -->
    @for (notification of notifications; track notification.id) {
    @if(notification.show) {
    <div class="mb-6 prose prose-neutral">
        <div class="flex flex-row justify-between items-center">
            <p class="!m-0 text-sm"><b>{{notification.date | date }}</b></p>
            <p-button [icon]="notification.show ? 'pi pi-eye' : 'pi pi-eye-slash'" title="Mark as read" [text]="true"
                size="small" (click)="markAsRead(notification)"></p-button>
        </div>

        <p class="!mt-0">
            <a class="!no-underline text-primary hover:text-[var(--p-secondary-500)]" [href]="notification.link"
                target="_blank"><span [innerHTML]="notification.title"></span>&nbsp;&nbsp;<i
                    class="text-sm pi pi-external-link"></i></a>
        </p>

        <p class="text-sm" [innerHTML]="notification.excerpt"></p>
    </div>

    <p-divider />
    }
    }
</p-drawer>