<div class="w-full h-full flex flex-col items-center">
  <div class="p-2 md:p-5" style="max-width: 700px">
    <!-- Upgrade screen -->
    <div class="buy-premium-wrapper p-4 flex flex-row justifys-center items-center">
      <div class="flex-1">
        <h1 class="!mt-0 text-secondary">Upgrade to Premium</h1>
        <div>
          Unlock cloud project access, teams, report options and more...
        </div>
      </div>
      <a [href]="urlPricing" target="_self" class="p-button p-button-secondary font-bold">
        <i class="pi pi-external-link"></i> SELECT PLAN
      </a>
    </div>

    <div class="pb-2 md:pb-5"></div>

    <!-- Purchase screen -->
    <p-card class="p-4">
      <h1 class="!mt-0 !mb-4">Getting Started</h1>

      <ol class="list-decimal list-outside ml-6">
        <li>Download DefectWise from the App Store or Google Play for FREE</li>
        <li>Upgrade to a trial subscription to access online services</li>
        <li>Invite team members and add contractors</li>
        <li>Start logging defects</li>
      </ol>

      <p>You must upgrade to a <a class="text-secondary underline" [href]="urlPricing" title="Upgrade"
          target="_self">trial
          subscription</a> to access the online services used by this portal.</p>




      <div class="mb-4 flex flex-wrap">
        <p-divider></p-divider>

        <div class="w-full">
          <h3 class="!mb-1 !mt-8 text-center text-primary"><b>Scan to get the app.</b></h3>
          <p class="text-center">Not ready to sign-up to a trial? The app is <b>FREE</b> to download and use.</p>
        </div>

        <div class="w-full text-center">
          <img height="300" width="300" alt="Scan to download" class="inline" src="../../assets/home_qr_code.png" />
          <p-divider></p-divider>
        </div>

        <div class="w-full mt-4 md:w-6/12 text-center">
          <a [href]="urlAppleDownload" title="Download on the App Store">
            <img height="60" alt="App Store Logo" class="inline" src="../../assets/download-ios-dark.png" />
          </a>
        </div>
        <div class="w-full mt-8 md:w-6/12 text-center">
          <a [href]="urlGoogleDownload" title="Get it on Google Play">
            <img height="60" alt="Google Play Logo" class="inline" src="../../assets/download-android-dark.png" />
          </a>
        </div>
      </div>

      <p-divider></p-divider>

      <p class="!mt-8 text-center">Compare plans on <a class="underline" [href]="urlPricing" title="Upgrade"
          target="_self">our website</a>.</p>

    </p-card>
  </div>
</div>