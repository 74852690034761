import { Injectable } from "@angular/core";

import {
  ProjectService,
  SiteService,
  DefectService,
  UserService,
  CatchErrorService,
  AlertService,
  SettingsService,
  HashService,
} from "../services";

import type {
  IDefectContractorAssignment,
  IComment,
  ICommentPhoto,
  ClientSelection,
  IProjectView,
  DefectLog,
} from "../models";

import {
  Project,
  List,
  Defect,
  IDefectStatusSummary,
  IProjectProgressSummaryUpdate,
  IDefectStatus,
  HashDataId,
  ProjectPrimaryKeyId,
  ListPrimaryKeyId,
  DefectPrimaryKeyId,
  PrimaryKeyId,
  StorageKey,
} from "../models";

import SortHelper from "../helpers/sorting.helper";

import { environment } from "../../environments/environment";
import { SortEvent } from "primeng/api";
import { ActivatedRoute } from "@angular/router";

/** Internet connectivity service */
@Injectable({
  providedIn: "root",
})
export class ProjectProvider {
  /** For comparing against comments/other */
  uid: number | undefined;

  // HOME SCREEN
  projects: IProjectView[] = [];

  // PROJECT SCREEN INFORMATION
  /** Project Id number */
  projectId: ProjectPrimaryKeyId | undefined;
  /** Project Id number */
  projectHashId: HashDataId | "" = "";
  /** Project object */
  project: Project.Project | undefined;
  /** Whether current user is a projet owner */
  isOwner: boolean = false;
  /** Summary status data for details page */
  statusSummaryData: IDefectStatusSummary = new IDefectStatusSummary();
  /** Array of original sites */
  lists: Project.ListView[] = [];
  /** project contractors */
  contractors: Project.Contractor[] = [];
  /** Project team members */
  projectTeam: Project.TeamMember[] = [];
  /** Report on server */
  cloudReports: Project.Report[] = [];

  // PROJECT UI CONTROLS
  /** UI Control for things pending get project */
  loadingProject = true;
  loadingList = false;

  // LIST SCREEN INFO
  /** Project Id number */
  listId: ListPrimaryKeyId | undefined;
  /** Project Id number */
  listHashId: HashDataId | "" = "";
  /** IListView screen view object */
  private _list: List.List | undefined;
  /** Original array of defects for list */
  listDefects: List.DefectView[] = [];
  /** Summary status data for details page */
  listStatusSummaryData: IDefectStatusSummary = new IDefectStatusSummary();
  /** original list of client portal contacts */
  listClients: ClientSelection[] = [];

  // DEFECT SCREEN INFO
  /** Defect object to show on page */
  private _defect: Defect.Defect | undefined;
  /** Defect photos array */
  originalDefectPhotos: Defect.Photo[] = [];
  /** Defect photos array */
  defectPhotos: Defect.Photo[] = [];
  /** Holds the old id when switching and updating UI */
  oldDefectStatus: IDefectStatus | undefined;
  /** Contractor Assignments - Only used for populating the sub-component */
  originalDefectContractors: IDefectContractorAssignment[] = [];

  /** defect comments */
  comments: IComment[] = [];
  commentPreviewImages: ICommentPhoto[] = [];
  showDefectCommentGallery: boolean = false;
  activeDefectCommentGalleryIndex: number = 0;

  /** defect history */
  defectHistoryLog: DefectLog = [];

  loadingHistory: boolean = false;

  defectActivityMode: string = "comments";

  // DEFECT UI CONTROLS
  /** controls the side panel or modal */
  showDefectInPanel: boolean = false;
  /** controls the side panel or modal */
  showDefectInModal: boolean = false;
  /** Loading state of a defect */
  loadingDefect = true;
  /** Contractor Assignments - Used for the multi-select control */
  defectContractorIds: PrimaryKeyId[] = [];
  /** Controls the gallery popup in list.html */
  showDefectGallery: boolean = false;
  /** Sets which image to open in the gallery popup in list.html */
  activeGalleryIndex: number = 0;
  /** whether load more comment btn has been clicked */
  loadMore = false;
  /** @ignore */
  constructor(
    private ps: ProjectService,
    private ss: SiteService,
    private ds: DefectService,
    private us: UserService,
    private cx: CatchErrorService,
    private as: AlertService,
    private settings: SettingsService,
    private route: ActivatedRoute,
    private hash: HashService
  ) {}

  get defect(): Defect.Defect | undefined {
    return this._defect;
  }

  /** Set defect - clears related items if undefined */
  set defect(o: Defect.Defect | undefined) {
    if (o === undefined) {
      // resetting defect, reset associated stuff
      this.oldDefectStatus = undefined;
      this.showDefectInModal = false;
      this.showDefectInPanel = false;
      this.defectPhotos = [];
      this.originalDefectContractors = [];
    }
    this._defect = o;
  }

  get list(): List.List | undefined {
    return this._list;
  }

  /** Set defect - clears related items if undefined */
  set list(o: List.List | undefined) {
    if (o === undefined) {
      this.listId = undefined;
      this.listHashId = "";
      this.listDefects = [];
    }
    this._list = o;
  }

  /**
   * Clear / Wipe any data stored in the Project Provider
   */
  destroy(): void {
    if (!environment.production) {
      console.log("Reset project data");
    }
    this.projectId = undefined;
    this.projectHashId = "";
    this.project = undefined;
    this.isOwner = false;
    this.lists = [];
    this.projectTeam = [];
    this.contractors = [];
    this.cloudReports = [];
    this.comments = [];
    this.loadingProject = false;

    this.destroyList();
    this.destroyDefect();
    this.setSummaryData();
  }
  /**
   * Clear list screen data
   */
  destroyList(): void {
    this.list = undefined;
  }
  /**
   * Clear the cached defect
   */
  destroyDefect(): void {
    this.comments = [];
    this.defectHistoryLog = [];
    this.defect = undefined;
  }
  /**
   * Close the currently selected defect
   */
  closeDefect(): void {
    this.destroyDefect();
    this.showDefectInModal = false;
    this.showDefectInPanel = false;
  }
  /**
   * Retrieves project data and relevant information for the user.
   *
   * @param {boolean} keepChildIds - Whether to keep child ids or not.
   * @return {Promise<{
   *    project: Project.Project;
   *    lists: Project.ListView[];
   *    contractors: Project.Contractor[];
   *    projectTeam: Project.TeamMember[];
   *    reports: Project.Report[];
   *    isOwner: boolean;
   * }>} - A promise that resolves with the project data.
   * @throws {string} - Access denied if no subscription found or rejection error if failed to get project data.
   */
  async getProject(keepChildIds?: boolean): Promise<{
    project: Project.Project;
    lists: Project.ListView[];
    contractors: Project.Contractor[];
    projectTeam: Project.TeamMember[];
    reports: Project.Report[];
    isOwner: boolean;
  }> {
    if (!environment.production) {
      console.log("getProject()");
    }

    this.loadingProject = true;

    if (this.uid === undefined) {
      this.uid = await this.us.checkAndGetUserId();
    }

    try {
      if (keepChildIds !== true) {
        this.destroyList();
        this.destroyDefect();
      }

      const premium = await this.us.awaitPremium();
      if (premium === false || this.us.enabled === false) {
        return Promise.reject("Access denied - no subscription found");
      }

      if (this.projectId) {
        const { project, lists, contractors, projectTeam, reports, isOwner } =
          await this.ps.getProjectPageData(this.projectId);

        this.project = project;
        this.projectTeam = projectTeam;
        this.contractors = contractors;
        this.cloudReports = reports;
        this.isOwner = isOwner;
        this.lists = SortHelper.defaultListsSort(lists, this.project.type);
        this.setSummaryData();
        return {
          project,
          lists: this.lists,
          contractors,
          projectTeam,
          reports,
          isOwner,
        };
      }
    } catch (ex) {
      const error = JSON.stringify(ex);
      if (error.includes("User is inactive or does not have permission")) {
        this.cx.logWarning(error);
      } else {
        this.cx.handle(ex, true);
      }
      return Promise.reject(ex);
    } finally {
      this.loadingProject = false;
    }
    return Promise.reject("Unknown error loading project data");
  }
  /**
   * Sorts project lists within the provider by project type sorting
   */
  reSortLists(): void {
    this.lists = SortHelper.defaultListsSort(
      this.lists,
      this.project?.type ?? 0
    );
  }
  /**
   * Get Site & defect data
   */
  async getListData(keepChildIds?: boolean): Promise<boolean> {
    // returning false makes the ui bounce back to project screen
    if (this.loadingList) return true;

    const premium = await this.us.awaitPremium();
    if (premium === false || this.us.enabled === false) {
      return Promise.reject("Access denied.");
    }

    this.loadingList = true;

    // check if project is loaded, if not, do it first
    if (this.project === undefined) {
      await this.getProject(true);
    }

    if (this.projectId && this.listId) {
      if (keepChildIds !== true) {
        this.destroyDefect();
      }

      try {
        const { site, defects, clients } = await this.ss.getSitePageData(
          this.listId,
          this.projectId
        );

        this.listDefects = defects;
        this.listDefects.sort(SortHelper.sortDefects);
        this.list = site;
        this.setListSummaryData();
        this.listClients = clients;

        this.loadingList = false;

        const defectId = this.#checkForDefectQuery();
        if (defectId) {
          await this.getDefect(defectId);
        }
        return true;
      } catch (ex) {
        this.cx.handle(ex, true);
        this.loadingList = false;
        return false;
      }
    } else {
      this.loadingList = false;
      return false;
    }
  }
  /**
   * Check if a query param for a specific defect is present
   * @returns
   */
  #checkForDefectQuery(): DefectPrimaryKeyId | undefined {
    const params = this.route.snapshot.queryParams;
    if (params["id"]) {
      const id = this.hash.decode<DefectPrimaryKeyId>(params["id"]);
      return id;
    }
    return;
  }
  /**
   * Remove a defect from the listDefects array - use for after deletion
   */
  async refreshProjectAndList(): Promise<void> {
    this.defect = undefined;
    await Promise.all([this.getProject(true), this.getListData(true)]);
  }
  /**
   * Get the defect
   */
  async getDefect(defectId?: DefectPrimaryKeyId): Promise<void> {
    try {
      if (!this.projectId) return;

      this.comments = [];
      this.loadMore = false;

      await this.#openDefectPanel();

      if (defectId) {
        this.loadingDefect = true;

        const premium = await this.us.awaitPremium();
        if (premium === false || this.us.enabled === false) {
          return Promise.reject("Access denied.");
        }

        const defectActivityMode = await this.settings.get(
          StorageKey.defectActivityOption
        );

        if (defectActivityMode && defectActivityMode !== "") {
          this.defectActivityMode = defectActivityMode;
        } else {
          this.defectActivityMode = "comments";
        }

        let defectResult;

        if (defectActivityMode === "history") {
          // Get history log
          const pAllRes = await Promise.all([
            this.ds.getDefect(defectId, this.projectId),
            this.loadDefectHistory(defectId),
          ]);
          defectResult = pAllRes[0];
        } else if (defectActivityMode === "show-all") {
          // Get history log and all comments
          const pAllRes = await Promise.all([
            this.ds.getDefect(defectId, this.projectId),
            this.loadDefectHistory(defectId),
            this.loadMoreComments(defectId),
          ]);
          defectResult = pAllRes[0];
        } else {
          // Default view 'comments' mode
          defectResult = await this.ds.getDefect(defectId, this.projectId);
        }

        const { defect, contractors, photos, comments } = defectResult;

        this.defect = defect;
        this.oldDefectStatus = defect.status;
        this.oldDefectStatus = this.oldDefectStatus;
        this.defectPhotos = [...photos];
        this.originalDefectPhotos = [...photos];
        this.originalDefectContractors = contractors;
        this.defectContractorIds = contractors.map(
          ({ contractorCloudId }) => contractorCloudId
        );
        this.comments = comments;
        this.loadingDefect = false;
        if (!environment.production) {
          console.log("GOT DEFECT", this.defect, this.defectPhotos);
        }
      } else {
        // new
        this.defect = {
          cloudId: -1 as DefectPrimaryKeyId,
          defectNumberString: "NEW",
          defectNumber: 0,
          area: "",
          element: "",
          issue: "",
          comments: "",
          created: new Date(),
          completed: undefined,
          dueDate: undefined,
          status: 0,
          priority: 1,
          lastModified: 0,
        };
        this.defectPhotos = [];
        this.originalDefectPhotos = [];
        this.originalDefectContractors = [];
        // Sets default contractors for a new defect
        this.defectContractorIds = [
          ...this.contractors
            .filter((o) => o.defaultAssignment)
            .map((o) => o.contractorCloudId),
        ];
      }
    } catch (ex) {
      const exMessage = JSON.stringify(ex);
      if (exMessage.includes("Unable to find defect in database")) {
        this.as.showAlert({
          title: "Error",
          message:
            "Defect not found, it may have been deleted. Try refreshing the page.",
        });
        this.cx.logWarning(ex);
      } else if (ex && !exMessage.includes("#401")) {
        await this.cx.handle(
          ex,
          true,
          `Error retrieving defect from server: ${
            exMessage ?? ex ?? "Unknown error"
          }`
        );
      } else {
        await this.cx.handle(ex, true);
      }
      // Refresh list data
      this.showDefectInPanel = false;
      this.showDefectInModal = false;
      await this.getListData(true);
    } finally {
      this.loadingDefect = false;
    }
  }
  /**
   * Opens the defect panel based on the window size, and sets the project
   * properties to show the defect in either a panel or a modal.
   *
   * @return {Promise<void>} Promise that resolves with no value
   */
  async #openDefectPanel(): Promise<void> {
    const windowSize = window.innerWidth;
    if (windowSize < 992) {
      // always open as modal
      this.showDefectInPanel = false;
      this.showDefectInModal = true;
    } else {
      const defectSidePanel = await this.settings.get(
        StorageKey.defectSidePanel
      );
      if (defectSidePanel !== "0") {
        this.showDefectInPanel = true;
        this.showDefectInModal = false;
      } else {
        this.showDefectInPanel = false;
        this.showDefectInModal = true;
      }
    }
  }
  /**
   * Load the history for a defect
   * @param defectId
   */
  async loadDefectHistory(defectId: DefectPrimaryKeyId): Promise<void> {
    try {
      this.loadingHistory = true;
      this.defectHistoryLog = await this.ds.getDefectHistory(defectId);

      if (!environment.production) {
        console.log("GOT DEFECT HISTORY", this.defectHistoryLog);
      }
    } catch (ex) {
      await this.cx.handle(ex, true);
    } finally {
      this.loadingHistory = false;
    }
  }

  onProjectContractorsChange(event: Project.Contractor[]): void {
    this.contractors = event;
  }

  onProjectTeamChange(event: Project.TeamMember[]): void {
    this.projectTeam = event;
  }

  /**
   * Trigger when changes to a project list occur
   * @param data
   * @deprecated prefer `getProject(true)` and `getListData(true)` to completely refresh data correctly
   */
  async onListChange(
    data?: IProjectProgressSummaryUpdate | undefined
  ): Promise<void> {
    if (data && this.lists.length > 0) {
      // Update counter if data available
      // Fired from save/delete defect
      for (const list of this.lists) {
        if (list.cloudId == data.listId) {
          // update site counter
          if (data.oldStatus === null && data.newStatus !== null) {
            list.defectsCount += 1;
          } else if (data.defectId === null) {
            list.defectsCount =
              list.defectsCount - 1 >= 0 ? list.defectsCount - 1 : 0;
          }

          // update progress counters if needed
          if (data.newStatus != data.oldStatus) {
            list.newCount = this.setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.newCount,
              0
            );
            list.inProgressCount = this.setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.inProgressCount,
              1
            );
            list.completeCount = this.setProgressCounter(
              data.newStatus,
              data.oldStatus,
              list.completeCount,
              2
            );
          }
          break;
        }
      }
    }

    this.setSummaryData();
  }

  async onDefectChange(
    isNewDefect: boolean,
    data?: IProjectProgressSummaryUpdate
  ): Promise<void> {
    if (isNewDefect && this.defect) {
      await this.getListData();
    } else if (this.defect) {
      const defectId = this.defect.cloudId;
      const index = this.listDefects.findIndex((o) => o.cloudId === defectId);
      if (index > -1 && this.defect) {
        let photo = "";

        if (this.defectPhotos.length > 0) {
          photo = this.defectPhotos[0].fullPath;
        }

        this.listDefects[index] = {
          ...this.listDefects[index],
          area: this.defect?.area,
          element: this.defect?.element,
          issue: this.defect?.issue,
          photo: photo,
          thumbnail: photo,
          status: this.defect?.status,
          priority: this.defect?.priority,
          assigned: this.defectContractorIds.length > 0 ? 1 : 0,
          created: this.defect?.created,
          completed: this.defect?.completed ?? null,
          selectedToMove: false,
        };

        this.listDefects.sort(SortHelper.sortDefects);
        this.setListSummaryData();
      }
    }

    this.onListChange(data);
  }

  /**
   * Remove a list from the project lists screen after deleting
   * @param cloudId
   */
  removeProjectList(cloudId: ListPrimaryKeyId): void {
    this.lists = this.lists.filter((o) => o.cloudId !== cloudId);
    this.onListChange();
  }

  /**
   * Manage the counts in the details summary widget
   * Needs to run after status changes to defects in this window
   */
  setSummaryData(): void {
    this.statusSummaryData.reset();
    this.lists.forEach((o: Project.ListView) => {
      this.statusSummaryData.newCount += o.newCount;
      this.statusSummaryData.inProgressCount += o.inProgressCount;
      this.statusSummaryData.completeCount += o.completeCount;
    });
    this.statusSummaryData.setPercentages();
  }
  /**
   * Manage the counts in the details summary widget
   * Needs to run after status changes to defects in this window
   */
  setListSummaryData(): void {
    try {
      this.listStatusSummaryData.reset();
      this.listDefects.forEach((d: List.DefectView) => {
        switch (d.status) {
          case 0:
            this.listStatusSummaryData.newCount++;
            break;
          case 1:
            this.listStatusSummaryData.inProgressCount++;
            break;
          case 2:
            this.listStatusSummaryData.completeCount++;
            break;
        }
      });
      this.listStatusSummaryData.setPercentages();
    } catch (ex) {
      this.cx.handle(ex, false);
      // avoid triggering parent fn try/catches
    }
    return;
  }
  /** */
  private setProgressCounter(
    newStatus: number | null | undefined,
    oldStatus: number | null | undefined,
    count: number,
    type: number
  ): number {
    switch (type) {
      case newStatus:
        count += 1;
        break;
      case oldStatus:
        count -= 1;
        break;
    }
    return count;
  }
  /**
   * Return contractors formatted for Defects page
   * @param selectedOnly whether to return selected contractors or all project contractors
   * @returns
   */
  getDefectAssignmentContractors(): IDefectContractorAssignment[] {
    if (this.contractors && this.contractors?.length > 0) {
      return this.contractors.map<IDefectContractorAssignment>((o) => {
        return {
          projectMapId: null,
          defectMapId: null,
          defectId: null,
          cloudId: -1,
          contractorCloudId: o.contractorCloudId,
          company: o.company,
          name: o.name,
          selected: o.defaultAssignment === true,
        };
      });
    } else {
      return [];
    }
  }
  /**
   * Default sort order for displaying defects
   * @param event
   * @returns
   */
  defaultDefectsSort(event: SortEvent): void {
    if (event.data === undefined) return;
    event.data.sort(SortHelper.sortDefects);
  }

  /**
   * Load more comments.
   *
   * @return {Promise<void>} A promise that resolves with no value.
   */
  async loadMoreComments(
    defectId?: DefectPrimaryKeyId | undefined
  ): Promise<void> {
    const dId = defectId ?? this.defect?.cloudId;
    if (dId === undefined) {
      return;
    }
    try {
      const result = await this.ds.loadMoreComments(dId);
      if (result) {
        this.comments = result;
        // set this last to hide show more btn after success
        this.loadMore = true;
      }
    } catch (ex) {
      this.cx.handle(ex, true);
    }
  }

  /**
   * Updates the interface to
   * - Add or Remove a comment to/from the defect panel
   * - Update the defect list screen `hasComments` icon
   *
   * @param {IComment} comment onlyt requires the `cloudId`
   * @param {"saved" | "deleted"} action `"saved" | "deleted"`
   * @returns
   */
  onCommentsChanged(comment: IComment, action: "saved" | "deleted"): void {
    if (action === "saved") {
      const index = this.comments.findIndex(
        (o) => o.cloudId == comment.cloudId
      );
      if (index < 0) {
        this.comments = [comment, ...this.comments];
      } else {
        this.comments[index] = comment;
      }
    }

    if (action === "deleted") {
      const index = this.comments.findIndex(
        (o) => o.cloudId == comment.cloudId
      );
      if (index < 0) {
        return;
      } else {
        this.comments.splice(index, 1);
      }
    }

    // Update list screen defect icon
    const hasComments = this.comments.length > 0;
    const defectId = this.defect?.cloudId;
    if (defectId) {
      const defectIndex = this.listDefects.findIndex(
        (o) => o.cloudId == defectId
      );
      if (defectIndex > -1) {
        this.listDefects[defectIndex].hasComments = hasComments;
      }
    }
  }

  async setDefectActivityMode(mode: string): Promise<void> {
    if (this.defect === undefined) return;
    if (this.defectActivityMode === mode) return;

    this.defectActivityMode = mode;

    await this.settings.set(StorageKey.defectActivityOption, mode);

    if (mode === "comments") {
      await this.loadMoreComments();
    } else if (mode === "history") {
      await this.loadDefectHistory(this.defect?.cloudId);
    } else if (mode === "show-all") {
      await Promise.all([
        this.loadDefectHistory(this.defect?.cloudId),
        this.loadMoreComments(),
      ]);
    }
  }
}
