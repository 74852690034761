import { Component, Output, EventEmitter } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";

import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import {
  ApiService,
  UserService,
  CatchErrorService,
  AnalyticsService,
  ANALYTICS_EVENTS,
  LoadingController,
} from "../../../services";

import { MixpanelService } from "src/app/services/mixpanel.service";

import { environment } from "src/environments/environment";
import { ButtonModule } from "primeng/button";
import { NgIf } from "@angular/common";
import { InputTextModule } from "primeng/inputtext";
import { Select } from "primeng/select";
import { InputGroup } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";

/** Register new user component */
@Component({
  selector: "client-register-widget",
  templateUrl: "./client-register.component.html",
  styleUrls: ["./client-register.component.scss"],
  standalone: true,
  imports: [
    Select,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    NgIf,
    ButtonModule,
    InputGroup,
    InputGroupAddonModule,
  ],
})
export class ClientRegisterComponent {
  /** Register complete event output */
  @Output() registerComplete: EventEmitter<any> = new EventEmitter<any>();
  /** Register form object */
  registerForm: FormGroup;
  /** If query params are provided, this will redirect back to checkout */
  readonly redirectToClient: boolean = false;
  /** If query params are provided, this will redirect back to checkout */
  readonly clientProjectId: string = "";
  /** */
  roleOptions: any[] = [
    { name: "Property Manager", value: "manager" },
    { name: "Owner / Resident", value: "owner" },
  ];
  /** Selected role - auto form fill ins wipe this if it is in the form group */
  roleValue: any = "";
  /** @ignore */
  constructor(
    private route: ActivatedRoute,
    private router: Router,
    private us: UserService,
    private api: ApiService,
    private cx: CatchErrorService,
    private loadingCtrl: LoadingController,
    private analytics: AnalyticsService,
    private mixpanel: MixpanelService
  ) {
    const params: any = this.route.snapshot.queryParams;
    const mode: string | undefined = params["mode"];
    if (mode === "client") {
      this.redirectToClient = true;
      this.clientProjectId = params["id"];
    }

    this.registerForm = new FormGroup({
      firstname: new FormControl("", [Validators.required]),
      lastname: new FormControl("", [Validators.required]),
      phone: new FormControl("", [Validators.required]),
    });
  }

  /**
   * Save available settings
   */
  skip() {
    this.analytics.trackCustomEvent(ANALYTICS_EVENTS.loginRegisterSkipped);
    this.register();
  }

  /**
   * Register logic
   */
  async register(): Promise<void> {
    try {
      await this.loadingCtrl.present();

      if (this.redirectToClient === true) {
        await this.doClientRegister();
        // Navigate and Reset Form
        this.router.navigate([`/client/${this.clientProjectId}`]).then(() => {
          this.registerForm.controls["firstname"].setValue("");
          this.registerForm.controls["lastname"].setValue("");
          this.registerForm.controls["phone"].setValue("");
          this.roleValue = "";
          this.registerForm.reset();
          this.registerComplete.emit(true);
        });
      }

      await this.loadingCtrl.dismiss();
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.cx.handle(ex, true, "Unable to complete registration.");
    }
  }

  /**
   * Executes the client registration process.
   * @return {Promise<boolean>} boolean for success and exception for error
   */
  private async doClientRegister(): Promise<boolean> {
    if (!environment.production) {
      console.log("REGISTER: saveUserSettings()");
    }

    try {
      const { firstname, lastname, phone } = this.registerForm.value;

      const result = await this.api.client.createClientUser(
        firstname,
        lastname,
        phone,
        this.roleValue
      );

      if (result.success) {
        // setting id takes a moment to get fb user
        setTimeout(async () => {
          const email = await this.us.getEmail();
          const tenantId = await this.us.checkAndGetTenantId();
          this.mixpanel.trackEvent("sign_up_client_portal");
          this.mixpanel.setUserProperties({
            name: `${firstname} ${lastname}`.trim(),
            company: undefined,
            plan: "Client",
            email: email ?? undefined,
            tenantId: tenantId ?? undefined,
          });
        });
        await this.us.getLogin();
      }

      return Promise.resolve(result.success);
    } catch (ex) {
      return Promise.reject(ex);
    }
  }
}
