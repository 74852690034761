<div class="h-full flex flex-col flex-auto">
    <p-toolbar styleClass="p-home-toolbar mobile-toolbar">
        <ng-template #start>
            <img src="../../../../assets/defectwise-hori-logo.svg" alt="DefectWise" style="height: 1.8rem;" />
        </ng-template>

        <ng-template #end>
            <p-button pTooltip="Notifications" tooltipPosition="bottom" [rounded]="true"
                [severity]="notificationCount !== '' ? 'info' : 'primary'"
                [text]="notificationCount !== '' ? false :true" [badge]="notificationCount" badgeSeverity="danger"
                (click)="showNotifications()">
                <i class="pi pi-bell" style="font-size: 1.2rem"></i>
            </p-button>

            <p-button pTooltip="Settings" tooltipPosition="bottom" styleClass="ml-2" [rounded]="true" [text]="true"
                [routerLink]="['/options/0']" router-direction="forward">
                <i class="pi pi-cog" style="font-size: 1.2rem"></i>
            </p-button>

            <p-button pTooltip="Profile Options" tooltipPosition="bottom" styleClass="ml-2" [rounded]="true"
                [text]="true" (click)="showMenu($event, profileCtrl)">
                <i class="pi pi-user" style="font-size: 1.2rem"></i>
            </p-button>
        </ng-template>
    </p-toolbar>

    <div class="overflow-y-auto surface-ground">
        <!-- Account Disabled Notice -->
        <div class="home-screen-datatable-wrapper m-4 mt-5 p-4 p-card" *ngIf="!loading && !isEnabled">
            <app-disabled-notice></app-disabled-notice>
        </div>

        <!-- Free User Notice -->
        <div class="home-screen-datatable-wrapper" *ngIf="!loading && !isPremium">
            <app-upgrade-notice></app-upgrade-notice>
        </div>

        <div class="home-screen-datatable-wrapper" *ngIf="isPremium && isEnabled !== false">
            <p-table #datatable [value]="projects" stateStorage="local" [stateKey]="tableLocalStoreKey" stripedRows
                dataKey="id" [loading]="loading" [globalFilterFields]="['reference','name','type']"
                [tableStyle]="{'min-width': '600px'}" [scrollable]="true" (onFilter)="tableOnFilter($event)"
                scrollHeight="flex">
                <!-- Filter toolbar -->
                <ng-template #caption>
                    <div class="flex flex-row">
                        <p-iconfield>
                            <p-inputicon styleClass="pi pi-search" />
                            <input pInputText type="text" class="input-compact" [(ngModel)]="textFilterValue"
                                (input)="search($event)" placeholder="Search Projects" />
                        </p-iconfield>

                        <p-button label="Clear Filters" pTooltip="Clear table filters" tooltipPosition="bottom"
                            styleClass="ml-2 text-no-wrap" severity="info" icon="pi pi-filter-slash"
                            *ngIf="filterActive" (click)="resetFilters()"></p-button>

                        <p-button label="Create Project" class="ml-2 sm:ml-auto button-nowrap" styleClass="text-nowrap"
                            severity="info" icon="pi pi-plus" (click)="createProject()"></p-button>

                    </div>
                </ng-template>
                <!-- Table Header Row -->
                <ng-template #header>
                    <tr>
                        <th style="width: 14rem; text-align: center;" pSortableColumn="type">
                            <div class="home-project-type-filter">
                                <p-columnFilter field="type" matchMode="equals" [showMenu]="false">
                                    <ng-template pTemplate="filter" let-value let-filter="filterCallback">
                                        <p-select [ngModel]="value" [options]="typeOptions"
                                            (click)="preventDefault($event)" (onChange)="filter($event.value)"
                                            placeholder="Show All" [showClear]="false">
                                            <ng-template let-option pTemplate="item">
                                                <span class="mr-2">
                                                    <img class="thumb-mini-icon"
                                                        src="../../../assets/project-type-0-icon.svg"
                                                        *ngIf="option.value === 0" />
                                                    <img class="thumb-mini-icon"
                                                        src="../../../assets/project-type-1-icon.svg"
                                                        *ngIf="option.value === 1" />
                                                    <img class="thumb-mini-icon"
                                                        src="../../../assets/project-type-2-icon.svg"
                                                        *ngIf="option.value === 2" />
                                                    <img class="thumb-mini-icon"
                                                        src="../../../assets/project-type-3-icon.svg"
                                                        *ngIf="option.value === 3" />
                                                </span>
                                                <span>{{option.label}}</span>
                                            </ng-template>
                                        </p-select>
                                    </ng-template>
                                </p-columnFilter>
                                <div class="ml-2">
                                    <p-sortIcon field="type"></p-sortIcon>
                                </div>
                            </div>
                        </th>
                        <th pSortableColumn="name">
                            Project
                            <p-columnFilter type="text" field="name" display="menu"></p-columnFilter>
                            <p-sortIcon field="name"></p-sortIcon>
                        </th>
                        <th pSortableColumn="reference" style="width: 15%">
                            Ref
                            <p-columnFilter type="text" field="reference" display="menu"></p-columnFilter>
                            <p-sortIcon field="reference"></p-sortIcon>
                        </th>
                        <th pSortableColumn="lastProjectSyncDate" style="width: 25%">
                            Modified
                            <p-columnFilter type="date" field="lastModifiedDate" display="menu"></p-columnFilter>
                            <p-sortIcon field="lastProjectSyncDate"></p-sortIcon>
                        </th>
                        <th style="width: 3rem"></th>
                    </tr>
                </ng-template>
                <!-- body Row -->
                <ng-template #body let-project>
                    <tr class="datatableRowHover" [routerLink]="['/project/', project.hashedProjectId]">
                        <td>
                            <div class="text-center">
                                <img class="thumb-icon inline" src="../../../assets/project-type-0-icon.svg"
                                    *ngIf="project.type === 0" />
                                <img class="thumb-icon inline" src="../../../assets/project-type-1-icon.svg"
                                    *ngIf="project.type === 1" />
                                <img class="thumb-icon inline" src="../../../assets/project-type-2-icon.svg"
                                    *ngIf="project.type === 2" />
                                <img class="thumb-icon inline" src="../../../assets/project-type-3-icon.svg"
                                    *ngIf="project.type === 3" />
                            </div>
                        </td>
                        <td>
                            <a [routerLink]="['/project/', project.hashedProjectId]">
                                <span class="td-hover">{{project.name}}</span>
                            </a>
                        </td>
                        <td>
                            <span class="td-hover">
                                <a [routerLink]="['/project/', project.hashedProjectId]">
                                    {{project.reference}}
                                </a>
                            </span>
                        </td>
                        <td>
                            <a [routerLink]="['/project/', project.hashedProjectId]">
                                <span *ngIf="project.lastProjectSyncDate > 1">
                                    Modified {{project.lastProjectSyncDate | customDate }}
                                </span>
                            </a>
                        </td>
                        <td class="p-0">
                            <a [routerLink]="['/project/', project.hashedProjectId]">
                                <i class="pi pi-chevron-right"></i>
                            </a>
                        </td>
                    </tr>
                </ng-template>
                <!-- emptymessage Row -->
                <ng-template #emptymessage let-columns>
                    <tr>
                        <td [attr.colspan]="5">No projects found</td>
                    </tr>
                </ng-template>
            </p-table>
        </div>
    </div>

</div>

<p-menu #profileCtrl [popup]="true" [model]="profileOpts"></p-menu>

@defer (when displayInviteModal) {
<p-dialog [(visible)]="displayInviteModal" [modal]="true" header="Team Invitation" [resizable]="false"
    [breakpoints]="{'992px': '75vw', '640px': '100vw'}"
    [style]="{width: '50%', minWidth: '400px', maxWidth: '100vw', minHeight: '40vh'}">
    <app-home-view-invite [invite]="invite" (closeFn)="closeInvite($event)"></app-home-view-invite>
</p-dialog>
}