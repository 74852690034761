<!-- EDIT MODE HEADER -->
<div class="p-card p-3" *ngIf="editMode">
  <div class="flex flex-row items-center">
    <div class="pr-4">
      <img class="thumb-icon" src="../../../assets/project-type-0-icon.svg" *ngIf="type == 0">
      <img class="thumb-icon" src="../../../assets/project-type-1-icon.svg" *ngIf="type == 1">
      <img class="thumb-icon" src="../../../assets/project-type-2-icon.svg" *ngIf="type == 2">
      <img class="thumb-icon" src="../../../assets/project-type-3-icon.svg" *ngIf="type == 3">
    </div>

    <div class="flex-1">
      <span *ngIf="type == 0">Single Site Project<br></span>
      <span *ngIf="type == 1">Multi Site Project<br></span>
      <span *ngIf="type == 2">Apartments & Units<br></span>
      <span *ngIf="type == 3">Office Building<br></span>

      <span class="text-sm" *ngIf="type == 0">Suitable for a single site inspection.</span>
      <span class="text-sm" *ngIf="type == 1">Suitable for inspections with differing addresses.</span>
      <span class="text-sm" *ngIf="type == 2">Suitable for units and apartment buildings.</span>
      <span class="text-sm" *ngIf="type == 3">Suitable for single building offices.</span>
    </div>

    <div *ngIf="editMode">
      <button pButton type="button" label="Save" [loading]="loading" (click)="save()"
        [disabled]="!projectForm.valid || !uniqueRef || !projectForm.dirty"></button>
    </div>
  </div>
</div>

<div class="p-3" *ngIf="editMode"></div>

<!-- MAIN FORM -->
<form [formGroup]="projectForm">
  <h4 class="!mb-8 !mt-0">Details</h4>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText id="inputName" class="w-full" spellcheck="true" autocapitalize="words"
          inputmode="text" formControlName="name" (keyup)="populateRef()">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger" *ngIf="!projectForm.controls['name'].valid"></i>
          <i class="pi pi-check-circle text-success" *ngIf="projectForm.controls['name'].valid"></i>
        </p-inputicon>
      </p-iconfield>

      <label for="inputName">Project Name</label>
    </p-floatlabel>
  </div>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <p-iconfield>
        <input type="text" pInputText class="w-full" id="inputRef" autocapitalize="characters" inputmode="text"
          formControlName="ref" maxlength="20" (change)="checkReference()" (keyup)="checkReference()">
        <p-inputicon>
          <i class="pi pi-exclamation-circle text-danger"
            *ngIf="!projectForm.controls['ref'].valid || !uniqueRef"></i>
          <i class="pi pi-check-circle text-success" *ngIf="projectForm.controls['ref'].valid && uniqueRef"></i>
        </p-inputicon>
      </p-iconfield>

      <label for="inputRef">Project Reference</label>
    </p-floatlabel>

    <small class="text-danger block p-2" *ngIf="!uniqueRef">Duplicate reference! Must be unique.</small>
  </div>

  <h4 class="!mb-8">Location</h4>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText class="w-full" spellcheck="true" autocapitalize="words" inputmode="text"
        id="inputAddress" formControlName="address">
      <label for="inputAddress">Address</label>
    </p-floatlabel>
  </div>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText class="w-full" spellcheck="true" autocapitalize="words" inputmode="text"
        id="inputCity" formControlName="city">
      <label for="inputCity">City</label>
    </p-floatlabel>
  </div>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="numeric" pInputText class="w-full" spellcheck="true" autocapitalize="words" inputmode="numeric"
        id="inputPostCode" formControlName="postcode">
      <label for="inputPostCode">Post Code</label>
    </p-floatlabel>
  </div>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText class="w-full" spellcheck="true" autocapitalize="words" inputmode="text"
        id="inputState" formControlName="state">
      <label for="inputState">State</label>
    </p-floatlabel>
  </div>

  <div class="field w-full mb-8">
    <p-floatlabel>
      <input type="text" pInputText class="w-full" spellcheck="true" autocapitalize="words" inputmode="text"
        id="inputCountry" formControlName="country">
      <label for="inputCountry">Country</label>
    </p-floatlabel>
  </div>

  <h4>Client</h4>

  <div class="text-sm mb-2">Displayed on reports as "Prepared For / On Behalf Of"</div>
  <textarea pTextarea class="w-full" style="min-height:80px;" rows="4" [autoResize]="true" maxlength="150"
    formControlName="client" autocapitalize="sentence"
    placeholder="Enter client name & address details here..."></textarea>

</form>