import { Component, OnInit, Input, ViewChild, ElementRef, ChangeDetectionStrategy } from "@angular/core";
import { ImageModule } from "primeng/image";
import { NgIf } from "@angular/common";

@Component({
    selector: "ia-avatar",
    templateUrl: "./ia-avatar.component.html",
    styleUrls: ["./ia-avatar.component.scss"],
    changeDetection: ChangeDetectionStrategy.OnPush,
    standalone: true,
    imports: [NgIf, ImageModule]
})
export class IaAvatarComponent implements OnInit {
  /** required, will work as a full display name */
  @Input() fname: string = "";
  /** optional */
  @Input() lname: string = "";
  /** If provided, will display an image */
  @Input() photoUrl: string = "";
  /** canvas reference */
  @ViewChild("myCanvas", { static: true }) myCanvas:
    | ElementRef<HTMLCanvasElement>
    | undefined;
  /** Display an image if provided */
  showInitials: boolean = true;

  initials: string = "";
  /** @ignore */
  constructor() { }

  ngOnInit() {
    this.showInitials = this.photoUrl === undefined || this.photoUrl === "";
    // If no fname provided, default to empty string
    this.fname = (this.fname || "").trim();
    // If no lname provided, see if a secondary name exists in fname field
    if (this.lname === undefined) {
      const split = this.fname.split(" ");
      this.lname = (split.length > 1 ? split[1] : "").trim();
    } else {
      this.lname = (this.lname || "").trim();
    }

    const initials = this.createInitials();

    if (this.myCanvas) {
      this.canvasAvatar(initials);
    }
  }

  /**
   * Generate initials
   * @returns string length 2
   */
  createInitials(): string {
    let initials = "";

    try {
      const count = Math.max(this.fname.length, this.lname.length);

      for (let i = 0; i < count; i++) {
        // if firstname has length, take a letter
        if (i <= this.fname.length && this.fname.charAt(i) !== " ") {
          initials += this.fname.charAt(i).toUpperCase();
          // If two initials found, break;
          if (initials.length > 1) {
            break;
          }
        }
        // if lastname has length, take a letter
        if (i <= this.lname.length && this.lname.charAt(i) !== " ") {
          initials += this.lname.charAt(i).toUpperCase();
          // If two initials found, break;
          if (initials.length > 1) {
            break;
          }
        }
      }
    } catch (ex) {
      //
    }

    return initials;
  }

  /**
   * Draw the avatar
   * - white text
   * - transparent background
   */
  canvasAvatar(initials: string): void {
    const canvasSize = 80;
    const canvas = this.myCanvas!.nativeElement;

    let ctx: CanvasRenderingContext2D | null = canvas.getContext("2d");
    if (ctx == null) return;

    // Set canvas with & height
    canvas.width = canvasSize;
    canvas.height = canvasSize;

    // Select a font family to support different language characters
    ctx.font = Math.round(canvas.width / 2) + "px Arial";
    ctx.textAlign = "center";

    // Setup background and front color
    ctx.fillStyle = "rgba(0,0,0,0)";
    ctx.fillRect(0, 0, canvas.width, canvas.height);
    ctx.fillStyle = "#FFF";
    ctx.fillText(initials, canvasSize / 2, canvasSize / 1.5);
  }
}
