<div class="text-center" *ngIf="formMode == 4">
    <h1>Create new account</h1>
    <p>Enter and confirm your new password below.</p>
</div>
<p class="text-center" *ngIf="formMode < 3">
    Enter your email to sign-in or create an account.
</p>
<p class="text-center" *ngIf="formMode == 3">
    Type your email to reset your password.
</p>

<form [formGroup]="loginForm" (keydown.enter)="keydownEnter()">

    <!-- EMAIL -->
    <div class="w-full mb-2">
        <p-inputgroup>
            <p-inputgroup-addon>
                <i class="pi pi-user"></i>
            </p-inputgroup-addon>
            <input pInputText type="email" class="w-full" autocomplete="email" maxLenth="200" placeholder="Email"
                [disabled]="loadingMode !== 0" formControlName="email">
            <p-inputgroup-addon *ngIf="loginForm.controls['email'].dirty">
                <i class="pi pi-times-circle text-danger" *ngIf="!loginForm.controls['email'].valid"></i>
                <i class="pi pi-check-circle text-success" *ngIf="loginForm.controls['email'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>
    </div>

    <!-- PASSWORD -->
    <div class="w-full mb-2" *ngIf="formMode > 0 && formMode < 3 || formMode == 4">
        <p-inputgroup>
            <p-inputgroup-addon>
                <i class="pi pi-lock"></i>
            </p-inputgroup-addon>
            <p-password class="w-full flex border-none p-0" [toggleMask]="true" [feedback]="formMode == 4"
                autocomplete="current-password" [class.ng-invalid]="!loginForm.controls['password'].valid"
                [disabled]="loadingMode !== 0" formControlName="password" placeholder="Password"></p-password>

            <p-inputgroup-addon *ngIf="loginForm.controls['password'].dirty">
                <i class="pi pi-times-circle text-danger" *ngIf="!loginForm.controls['password'].valid"></i>
                <i class="pi pi-check-circle text-success" *ngIf="loginForm.controls['password'].valid"></i>
            </p-inputgroup-addon>

        </p-inputgroup>
    </div>

    <!-- CONFIRM PASSWORD (new account view only) -->
    <div class="w-full mb-2" *ngIf="formMode == 4">
        <p-inputgroup>
            <p-inputgroup-addon>
                <i class="pi pi-lock"></i>
            </p-inputgroup-addon>
            <p-password class="w-full flex border-none p-0" [toggleMask]="true" [feedback]="false"
                [class.ng-invalid]="loginForm.value.password == loginForm.value.confirmPassword && loginForm.controls['password'].valid"
                autocomplete="new-password" [disabled]="loadingMode !== 0" formControlName="confirmPassword"
                placeholder="Confirm Password"></p-password>
            <p-inputgroup-addon *ngIf="loginForm.controls['confirmPassword'].dirty">
                <i class="pi pi-times-circle text-danger"
                    *ngIf="loginForm.value.password != loginForm.value.confirmPassword"></i>
                <i class="pi pi-check-circle text-success"
                    *ngIf="loginForm.value.password == loginForm.value.confirmPassword && loginForm.controls['password'].valid"></i>
            </p-inputgroup-addon>
        </p-inputgroup>

        <small class="my-2 text-danger block"
            *ngIf="loginForm.controls['confirmPassword'].dirty && loginForm.value.password != loginForm.value.confirmPassword">
            Passwords do not match!
        </small>
    </div>
</form>

<small class="mb-2 text-danger block" *ngIf="loginForm.controls['password'].dirty && formMode == 4 && !loginForm.valid">
    Minimum password length is 6 characters
</small>

<small class="mb-2 text-danger block" *ngIf="formMode == 2">
    Email and/or password are incorrect, try again or reset password below.
</small>

<div class="pt-2 flex flex-col items-stretch">

    <!-- 1) STARTING OPTION - EMAIL -->
    <p-button styleClass="mb-2 w-full" severity="secondary" *ngIf="formMode == 0" (click)="startEmailSignIn()"
        [disabled]="!loginForm.controls['email'].valid || loadingMode === 1">
        <ng-template pTemplate="content">
            <div class="w-full text-center p-button-label sign-in-with-email">
                <i class="pi pi-envelope" style="font-size: 1rem;"></i> &nbsp; Sign in with Email
            </div>
        </ng-template>
    </p-button>

    <!-- 1) STARTING OPTION - APPLE -->
    <p-button styleClass="mb-2 w-full p-button-dark" (click)="signIn('apple')" *ngIf="formMode == 0"
        [disabled]="loadingMode === 1">
        <ng-template pTemplate="content">
            <div class="w-full text-center p-button-label sign-in-with-apple">
                <i class="pi pi-apple" style="font-size: 1rem;"></i> &nbsp; Sign in with Apple
            </div>
        </ng-template>
    </p-button>

    <!-- 1) STARTING OPTION - GOOGLE -->
    <p-button styleClass="mb-2 w-full" severity="info" (click)="signIn('google')" *ngIf="formMode == 0"
        [disabled]="loadingMode === 1">
        <ng-template pTemplate="content">
            <div class="w-full text-center p-button-label sign-in-with-google">
                <i class="pi pi-google" style="font-size: 1rem;"></i> &nbsp; Sign in with Google
            </div>
        </ng-template>
    </p-button>

    <!-- EMAIL & PASSWORD - LOGIN & PASSWORD VIEW -->
    <p-button styleClass="w-full mb-2 check-email-btn" severity="secondary" label="LOGIN"
        *ngIf="formMode > 0 && formMode < 3" [disabled]="!loginForm.valid || loadingMode !== 0"
        (click)="signIn('email')"></p-button>

    <!-- EMAIL & PASSWORD - NEW ACCOUNT VIEW -->
    <p-button styleClass="w-full mb-2 create-account-btn" severity="secondary" label="CREATE ACCOUNT"
        *ngIf="formMode == 4"
        [disabled]="!loginForm.valid || loginForm.value.password != loginForm.value.confirmPassword || loadingMode !== 0"
        (click)="emailCreateAccount()"></p-button>

    <!-- EMAIL & PASSWORD - Change to Reset View -->
    <p-button styleClass="w-full mb-2 request-reset-password-btn" [text]="true" [disabled]="loadingMode !== 0"
        label="Reset Password" (click)="startRequestReset()" *ngIf="formMode > 0 && formMode < 3"></p-button>

    <!-- EMAIL & PASSWORD - RESET PASSWORD -->
    <p-button styleClass="w-full mb-2 reset-password-btn" severity="secondary" label="Reset Password"
        [loading]="loadingMode !== 0" (click)="requestReset()" [disabled]="!loginForm.controls['email'].valid"
        *ngIf="formMode == 3"></p-button>

    <p class="mb-2 text-center text-sm" *ngIf="formMode == 0">
        By continuing, you are indicating that you accept our
        <span style="cursor: pointer;font-weight:500;" (click)="openLink('https://www.defectwise.com.au/terms')">
            Terms of Service
        </span> and <span style="cursor: pointer;font-weight:500;"
            (click)="openLink('https://www.defectwise.com.au/privacy')">
            Privacy Policy
        </span>.
    </p>

    <p-button styleClass="mb-2 w-full" [text]="true" (click)="initForm()" *ngIf="formMode > 0">
        <ng-template pTemplate="content">
            <div class="w-full text-center p-button-label go-back">
                <i class="pi pi-arrow-left" style="font-size: 1rem;"></i><span class="ml-2">Go back</span>
            </div>
        </ng-template>
    </p-button>
</div>