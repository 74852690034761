import { Routes } from "@angular/router";
import { AuthGuard, TokenGuard, OptionsAdminGuard } from "src/app/utils/guards";

import { LoginPage } from "./login/login.component";
import { HomePage } from "./home/home.component";
import { GetAppPage } from "./get-app/get-app.page";
import { WebLoginPage } from "./web-login/web-login.page";
import { CheckoutPage } from "./checkout/checkout.page";
import { CreateProjectPage } from "./create-project/create-project.page";
import { ReportDesignerPage } from "./report-designer/report-designer.page";
import { ConfirmationPage } from "./confirmation/confirmation.page";

export const routeConfig: Routes = [
  { path: "", redirectTo: "login", pathMatch: "full" },
  {
    path: "login",
    component: LoginPage,
    canActivate: [TokenGuard],
  },
  {
    path: "projects",
    component: HomePage,
    canActivate: [AuthGuard],
  },
  {
    path: "projects/create",
    component: CreateProjectPage,
    canActivate: [AuthGuard],
  },
  {
    path: "project/:projectid",
    loadChildren: () =>
      import("./project/project-scaffold.module").then(
        (m) => m.ProjectScaffoldPageModule
      ),
    canActivate: [AuthGuard],
  },
  {
    path: "client",
    loadChildren: () =>
      import("./client-home/client-home.module").then(
        (m) => m.ClientHomePageModule
      ),
  },
  // whacked here so it uses the base router outlet, plays up when in options
  {
    path: "options/reports/template/:templateId",
    component: ReportDesignerPage,
    canActivate: [OptionsAdminGuard],
  },
  {
    path: "options",
    loadChildren: () =>
      import("./options/options.module").then((m) => m.OptionsPageModule),
    canActivate: [AuthGuard],
  },
  {
    path: "confirmation/:referenceId/:productId/:quantity",
    component: ConfirmationPage,
  },
  {
    path: "billing/:productId/:quantity",
    component: CheckoutPage,
  },
  {
    path: "web-login/:redirectTo",
    component: WebLoginPage,
  },
  {
    path: "install",
    component: GetAppPage,
  },
  // TODO: Make a 404 page
  { path: "**", redirectTo: "login" },
];
