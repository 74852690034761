import {
  Component,
  Input,
  Output,
  EventEmitter,
  forwardRef,
} from "@angular/core";
import { NG_VALUE_ACCESSOR } from "@angular/forms";
import { ButtonModule } from "primeng/button";

@Component({
  selector: "app-status-button-group",
  templateUrl: "./status-button-group.component.html",
  styleUrl: "./status-button-group.component.scss",
  standalone: true,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => StatusButtonGroupComponent),
      multi: true,
    },
  ],
  imports: [ButtonModule],
})
export class StatusButtonGroupComponent {
  /** When a button is clicked */
  @Output() onChange = new EventEmitter<number | undefined | null>();
  /** Accessed via [(ngModel)] */
  @Input() value: number | undefined | null;
  /** @ignore */
  constructor() {}

  select(value: number) {
    this.writeValue(value);
  }

  /** @override */
  writeValue(value: number | null | undefined): void {
    this.value = value;
    this.propagateChange(this.value);
    if (value !== undefined && value != null) {
      // only notify if valid value
      this.onChange.emit(this.value);
    }
  }

  /** @override */
  propagateChange = (_: any): void => {};

  /** @override */
  registerOnChange(fn: any): void {
    this.propagateChange = fn;
  }

  /** @override */
  registerOnTouched(): void {}
}
