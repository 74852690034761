import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { getAuth } from "firebase/auth";

import { UserService } from "src/app/services";

/** Authorisation guard */
@Injectable({
  providedIn: "root",
})
export class AuthGuard {
  /** @ignore */
  constructor(private router: Router, private us: UserService) {}
  /** Guard canActive handler */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const loggedIn = await this.checkAuthState();

    try {
      const ids = await this.us.checkAndGetUserIds();

      if (
        loggedIn &&
        ids.userId &&
        ids.userId > 0 &&
        ids.tenantId &&
        ids.tenantId > 0
      ) {
        return true;
      } else {
        if (loggedIn) {
          await this.us.logout();
        }
        this.router.navigate(["/login"]);
        return false;
      }
    } catch (ex) {
      if (loggedIn) {
        await this.us.logout();
      }
      this.router.navigate(["/login"]);
      return false;
    }
  }

  async checkAuthState(): Promise<boolean> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user != null) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
