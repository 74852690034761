<div class="p-card p-4 mb-3">
  <div class="w-full flex flex-row justify-between items-center">
    <p-button [rounded]="true" (click)="decrement()">
      <i class="pi pi-minus" style="font-size: 1.2rem"></i>
    </p-button>

    <p-inputNumber class="counter-input" inputId="integeronly" [(ngModel)]="siteCounter"> </p-inputNumber>

    <p-button [rounded]="true" (click)="increment()">
      <i class="pi pi-plus" style="font-size: 1.2rem"></i>
    </p-button>
  </div>
</div>