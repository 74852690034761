<div class="defect-p-image defect-thumbnail-wrapper" (click)="preview()">

  <div class="defect-thumbnail-hover flex flex-auto justify-around items-center">

    <div class="m-2 defect-thumbnail-controls flex flex-row">
      <!-- <div class=" flex flex-auto justify-around items-center defect-thumbnail-control" (click)="download()">
        <i class="pi pi-download"></i>
      </div> -->

      <div class="ml-2 flex flex-auto justify-around items-center defect-thumbnail-control"
        (click)="delete(); $event.stopPropagation();" *ngIf="enabledDelete">
        <i class="pi pi-trash"></i>
      </div>
    </div>

    <i class="pi pi-eye"></i>
  </div>

  <div class="fill" *ngIf="fullPath">
    <img [src]="fullPath" alt="Defect Image" />
  </div>

</div>