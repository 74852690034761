import { Component, Output, EventEmitter, Input } from "@angular/core";
import {
  FormGroup,
  FormControl,
  Validators,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import {
  getAuth,
  signInWithEmailAndPassword,
  signInWithPopup,
  createUserWithEmailAndPassword,
  sendPasswordResetEmail,
  GoogleAuthProvider,
  OAuthProvider,
  UserCredential,
  Auth,
} from "firebase/auth";

/** Browser, device and Apple sign in capacitor plugins */
import { Browser } from "@capacitor/browser";

import {
  CatchErrorService,
  AlertService,
  UserService,
  AnalyticsService,
  LogService,
  ANALYTICS_EVENTS,
  ApiService,
  LoadingController,
} from "src/app/services";

import { GALoginMethod } from "src/app/models";

import { environment } from "src/environments/environment";
import { SharedModule } from "primeng/api";
import { ButtonModule } from "primeng/button";
import { PasswordModule } from "primeng/password";
import { InputTextModule } from "primeng/inputtext";
import { InputGroup } from "primeng/inputgroup";
import { InputGroupAddonModule } from "primeng/inputgroupaddon";
import { NgIf } from "@angular/common";

enum FORM_MODES {
  init = 0,
  email_login = 1,
  error = 2,
  reset_password = 3,
  create_account = 4,
}

enum LOADING_MODE {
  pending = 0,
  email_login = 1,
  apple_login = 2,
  google_login = 3,
  create_password = 4,
  reset_password = 5,
}

/** Login widget component */
@Component({
  selector: "login-widget",
  templateUrl: "./login-widget.component.html",
  styleUrls: ["./login-widget.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    PasswordModule,
    ButtonModule,
    SharedModule,
    InputGroup,
    InputGroupAddonModule,
  ],
})
export class LoginWidgetComponent {
  /** Logged in event observer */
  @Output() loggedIn: EventEmitter<any> = new EventEmitter<any>();
  /** Logged in event observer */
  @Output() loginCompleteCallback: EventEmitter<any> = new EventEmitter<any>();
  /** */
  @Input("isClientPortal") isClientPortal!: boolean;
  /** Is new user flag */
  newUser: boolean = false;
  /** Login form object */
  loginForm: FormGroup;
  /** Tracks mode of the form */
  formMode: number;
  /** State of the loader */
  loadingMode: number = LOADING_MODE.pending;
  /** @ignore */
  constructor(
    private cx: CatchErrorService,
    private as: AlertService,
    private us: UserService,
    private api: ApiService,
    private alert: AlertService,
    private analytics: AnalyticsService,
    private log: LogService,
    private loadingCtrl: LoadingController
  ) {
    this.formMode = FORM_MODES.init;
    this.loginForm = new FormGroup({
      email: new FormControl("", [Validators.email, Validators.required]),
      password: new FormControl("", [
        Validators.required,
        Validators.minLength(6),
      ]),
      confirmPassword: new FormControl(""),
    });
  }
  /**
   * Initial the login form
   */
  initForm(): void {
    this.loginForm.controls["email"].setValue("");
    this.loginForm.controls["password"].setValue("");
    this.loginForm.reset();
    this.formMode = FORM_MODES.init;
  }
  /**
   * Set request reset mode
   */
  startRequestReset(): void {
    this.formMode = FORM_MODES.reset_password;
  }
  /**
   * Check available providers for entered Email from Firebase
   */
  async startEmailSignIn(): Promise<void> {
    try {
      await this.loadingCtrl.present({ message: "Checking Email..." });
      this.loadingMode = LOADING_MODE.email_login;

      // disabled attr on btn isn't preventing function, so use this line to prevent propagation
      const valid = this.loginForm.controls["email"].valid;
      if (!valid) return;

      const email: string = `${this.loginForm.value.email}`;
      if (email.trim() === "") return;

      const checkUserResult = await this.api.user.checkUser(email.trim());

      if (checkUserResult.data.isFirebaseUser) {
        // Show Email Login View
        this.formMode = FORM_MODES.email_login;
      } else {
        // Create Account if No Providers in Result Array
        this.newUser = true;
        this.formMode = FORM_MODES.create_account;
      }
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.handleException(ex);
    } finally {
      await this.loadingCtrl.dismiss();
      this.loadingMode = LOADING_MODE.pending;
    }
  }
  /**
   * Sign in function for all provider types
   * @param provider Type of login provider to authenticate with
   */
  async signIn(provider: string): Promise<void> {
    const auth = getAuth();
    auth.languageCode = "en-AU";
    let result: UserCredential | null = null;
    let signInMethod: GALoginMethod = GALoginMethod.unknown;

    try {
      switch (provider) {
        case "apple":
          this.loadingMode = LOADING_MODE.apple_login;
          const appleProvider = new OAuthProvider("apple.com");
          appleProvider.credential;
          appleProvider.addScope("email");
          appleProvider.addScope("name");
          result = await signInWithPopup(auth, appleProvider);
          signInMethod = GALoginMethod.apple;
          break;
        case "google":
          this.loadingMode = LOADING_MODE.google_login;
          const googleProvider = new GoogleAuthProvider();
          result = await signInWithPopup(auth, googleProvider);
          signInMethod = GALoginMethod.google;
          break;
        case "email":
          await this.loadingCtrl.present({ message: "Logging In..." });
          this.loadingMode = LOADING_MODE.email_login;
          const email: string = this.loginForm.value.email;
          const password: string = this.loginForm.value.password;
          result = await signInWithEmailAndPassword(auth, email, password);
          signInMethod = GALoginMethod.email;
          break;
      }

      this.loadingMode = LOADING_MODE.pending;

      if (result !== null) {
        this.tokenHandler(signInMethod);
      } else {
        await this.loadingCtrl.dismiss();
      }
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      this.loadingMode = LOADING_MODE.pending;
      await this.handleException(ex);
    }
  }

  /**
   * Creates a user account in Firebase for new sign ups
   */
  async emailCreateAccount(): Promise<void> {
    if (!environment.production) console.log("LOGIN: emailCreateAccount()");
    this.loadingMode = LOADING_MODE.create_password;

    await this.loadingCtrl.present({ message: "Creating Account..." });

    const auth = getAuth();

    try {
      const email: string = this.loginForm.value.email;
      const password: string = this.loginForm.value.password;
      await createUserWithEmailAndPassword(auth, email, password);
      this.analytics.trackCustomEvent(ANALYTICS_EVENTS.emailAccountCreated);
      await this.loadingCtrl.dismiss();
      await this.tokenHandler(GALoginMethod.email);
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.handleException(ex);
    } finally {
      this.loadingMode = LOADING_MODE.pending;
    }
  }

  /**
   * Send reset password request
   */
  async requestReset(): Promise<void> {
    this.loadingMode = LOADING_MODE.reset_password;

    await this.loadingCtrl.present({ message: "Resetting..." });

    const auth = getAuth();

    try {
      const email: string = this.loginForm.value.email;
      await sendPasswordResetEmail(auth, email);
      await this.loadingCtrl.dismiss();
      await this.alert.showAlert({
        title: "Success",
        message:
          "Password reset email successfully sent. Please check your email.",
        handler: () => {
          this.formMode = FORM_MODES.init;
        },
      });
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.handleException(ex);
    } finally {
      this.loadingMode = LOADING_MODE.pending;
    }
  }

  /**
   * Finishes setting up the user account on the
   * device and in Firestore database
   */
  private async tokenHandler(loginmethod: GALoginMethod): Promise<void> {
    this.loadingMode = LOADING_MODE.email_login;

    await this.loadingCtrl.present({ message: "Logging In..." });

    let userEmail = "";

    try {
      const auth: Auth = getAuth();
      if (auth.currentUser !== null) {
        userEmail = auth.currentUser.email ?? "";

        this.analytics.login(loginmethod, userEmail);
        // identify user for log and mixpanel
        this.log.identifyUser({
          email: userEmail,
          id: auth.currentUser.uid,
        });
      }
    } catch (ex) {
      await this.handleException(ex);
    }

    try {
      let success, user;

      if (this.isClientPortal) {
        const loginResult = await this.us.getClientLogin(userEmail);
        success = loginResult.success;
        user = loginResult.user;
        this.newUser = true;
        if (success && loginResult.user?.code === "registered") {
          this.newUser = false;
        }
      } else {
        const loginResult = await this.us.getLogin();
        success = loginResult.success;
        user = loginResult.user;
        if (success) {
          if (user) {
            this.newUser = false;
          } else {
            // New user with a database entry
            this.newUser = true;
          }
        } else {
          // New user with no database entry yet
          this.newUser = true;
        }
      }

      if (success && this.newUser) {
        this.us.evaluateSubscription({
          tier: 0,
          admin: true,
          enabled: true,
          defectLimit: 100,
          defectCount: 0,
        });
        this.analytics.signUp(loginmethod, userEmail);
      }

      this.loginComplete();
    } catch (ex) {
      this.loadingMode = LOADING_MODE.pending;
      if (`${ex}`.includes("Not authorised to access this page.")) {
        this.as.showAlert({
          title: "Denied",
          message:
            "You are not authorised to view this page, you have been logged out.",
        });
        await this.us.logout();
        this.loginForm.controls["email"].setValue("");
        this.loginForm.markAsPristine();
        this.formMode = FORM_MODES.init;
      } else {
        await this.loadingCtrl.dismiss();
        await this.handleException(ex);
      }
    } finally {
      await this.loadingCtrl.dismiss();
      this.loadingMode = LOADING_MODE.pending;
    }
  }

  /**
   * Runs after successful login and navigates to home
   */
  loginComplete(): void {
    if (!environment.production) {
      console.log("login complete");
    }
    if (this.newUser) {
      this.newUser = false;
      this.loggedIn.emit(1);
      this.initForm();
    } else {
      this.loginCompleteCallback.emit(1);
    }
  }

  /**
   * Open external link in browser
   * @param link URL
   */
  openLink(link: string): void {
    // const utmTags = "?utm_source=Web+App&utm_medium=Web+App+Link";
    const utmTags = "";
    Browser.open({ url: `${link}${utmTags}` });
  }

  /**
   * Takes the exception and processes into correct message to display
   * @param ex The exception thrown by the function
   */
  private async handleException(ex: any): Promise<void> {
    // firebase errors are not string
    if (typeof ex === "string") {
      this.cx.handle(ex, true);
    } else if (ex) {
      const code = ex?.code ?? "";

      // Handle Firebase auth error cases
      switch (code) {
        case "auth/popup-closed-by-user":
        case "auth/cancelled-popup-request":
          break;
        case "auth/wrong-password":
          await this.as.showAlert({
            title: "Error",
            message: "Failed to sign in, check your password and email.",
          });
          break;
        case "auth/network-request-failed":
          await this.as.showAlert({
            title: "Connection Error",
            message: "Failed to connect to server, please try again.",
          });
          break;
        case "auth/popup-blocked":
          await this.as.showAlert({
            title: "Error",
            message:
              "Pop ups blocked by browser! Enable popups, refresh this page and try again.",
          });
          break;
        case "auth/user-not-found":
          await this.as.showAlert({
            title: "Error",
            message:
              "No account matches this email address. Return to the start screen to create an account.",
          });
          break;
        case "auth/email-already-in-use":
          await this.as.showAlert({
            title: "Error",
            message: `Your account is linked to a different login method. Please use email & password or try one of the others.`,
          });
          break;
        case "auth/missing-password":
          await this.as.showAlert({
            title: "Error",
            message:
              "Unable to login. No password was entered, please try again.",
          });
          break;
        case "auth/invalid-email":
          await this.as.showAlert({
            title: "Error",
            message: `Unable to create account: ${ex.message}`,
          });
          break;
        case "auth/user-cancelled":
          return;
        default:
          if (
            ex.message ===
              "The password is invalid or the user does not have a password." ||
            `${ex.message}`.includes("1000")
          ) {
            await this.as.showAlert({
              title: "Error",
              message: `An error occurred: ${ex.message}`,
            });
          } else if (
            ex.error !== "popup_closed_by_user" &&
            ex.message !== "Something went wrong" &&
            ex.message !== "The user canceled the sign-in flow." &&
            `${ex.message}`.indexOf("1001") < 0
          ) {
            this.cx.handle(ex, true);
          }
      }
    } else {
      this.cx.handle(ex, false, "Unknown error during login");
    }
  }

  protected keydownEnter(): void {
    switch (this.formMode) {
      case FORM_MODES.init:
        this.startEmailSignIn();
        break;
      case FORM_MODES.email_login:
        if (this.loginForm.value.password !== "") {
          this.signIn("email");
        }
        break;
      case FORM_MODES.reset_password:
        this.startRequestReset();
        break;
    }
  }
}
