import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { NgIf } from "@angular/common";

import {
  CatchErrorService,
  ApiService,
  HashService,
  UserService,
  AnalyticsService,
} from "src/app/services";
import { PrimaryKeyId, ReportTemplateData } from "src/app/models";
import { SafePipe } from "src/app/utils/pipes";

import { environment } from "src/environments/environment";

import { ButtonModule } from "primeng/button";
import { InputTextModule } from "primeng/inputtext";

@Component({
  selector: "app-report-designer",
  templateUrl: "./report-designer.page.html",
  styleUrls: ["./report-designer.page.scss"],
  standalone: true,
  imports: [NgIf, SafePipe, ButtonModule, InputTextModule],
})
export class ReportDesignerPage implements OnInit {
  /** */
  readonly #editorBaseUrl = `${environment.reportAppUrl}/editor`;
  /** templateId passed in via route */
  readonly #templateId: PrimaryKeyId | undefined;
  /** TODO: This should be a ReportTemplate */
  #template: ReportTemplateData | undefined;
  /** */
  editorUrl: string = "";
  /** */
  loaded: boolean = false;

  /** @ignore */
  constructor(
    private api: ApiService,
    private cx: CatchErrorService,
    private route: ActivatedRoute,
    private router: Router,
    private hash: HashService,
    private user: UserService,
    private analytics: AnalyticsService
  ) {
    this.analytics.pageView(
      "DefectWise - Report Templates",
      "options/report-templates"
    );

    const hashedId = this.route.snapshot.params["templateId"];
    this.#templateId = this.hash.decode(hashedId);

    window.addEventListener("message", (e) => this.onMessageReceived(e), false);
  }

  /**
   * Listener for iframe MessageEvent to close editor
   * @param event
   * @returns
   */
  private onMessageReceived(event: MessageEvent): void {
    if (!event.data) return;

    // check event.data is JSON
    try {
      const data = JSON.parse(event.data);
      if (
        event.isTrusted &&
        this.#editorBaseUrl.includes(event.origin) &&
        data.action === "close"
      ) {
        this.close();
      }
    } catch (e) {
      return;
    }
  }

  async ngOnInit() {
    if (!this.#templateId) {
      await this.router.navigate(["/options/reports"]);
    } else {
      const { userId, tenantId } = await this.user.checkAndGetUserIds();
      const token = await this.user.getUserToken();

      this.editorUrl = `${this.#editorBaseUrl}?rid=${
        this.#templateId
      }&tid=${tenantId}&uid=${userId}&token=${token}`;
    }

    // Create a smooth transition when opening new window
    setTimeout(() => {
      this.loaded = true;
    }, 250);
  }

  ngOnDestroy(): void {
    window.removeEventListener("message", (e) => this.onMessageReceived(e));
  }

  async saveTemplate(): Promise<void> {
    try {
      if (!this.#template) return;

      const result = await this.api.reportTemplates.edit(
        this.#template.templateId,
        this.#template.templateName ?? "",
        this.#template.items
      );
      if (result) {
        //
      }
    } catch (ex) {
      this.cx.handle(ex, true);
    }
  }

  async close(): Promise<void> {
    await this.router.navigate(["/options/reports"]);
  }
}
