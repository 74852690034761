import { Injectable } from "@angular/core";
import {
  ActivatedRouteSnapshot,
  RouterStateSnapshot,
  Router,
} from "@angular/router";
import { getAuth } from "firebase/auth";

import { UserService } from "src/app/services";

/** General token guard */
@Injectable({
  providedIn: "root",
})
export class TokenGuard {
  /** @ignore */
  constructor(private router: Router, private us: UserService) {}
  /** Token guard canActivate handler */
  async canActivate(
    next: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): Promise<boolean> {
    const loggedIn = await this.checkAuthState();
    // console.log("TokenGuard loggedIn", loggedIn);
    if (!loggedIn) {
      return true;
    } else {
      try {
        const ids = await this.us.checkAndGetUserIds();

        if (
          loggedIn &&
          ids.userId &&
          ids.userId > 0 &&
          ids.tenantId &&
          ids.tenantId > 0
        ) {
          this.router.navigate(["/projects"]);
          return false;
        } else {
          await this.us.logout();
          return true;
        }
      } catch (ex) {
        await this.us.logout();
        return true;
      }
    }
  }

  async checkAuthState(): Promise<boolean> {
    return new Promise((resolve) => {
      const auth = getAuth();
      auth.onAuthStateChanged((user) => {
        if (user != null) {
          resolve(true);
        }
        resolve(false);
      });
    });
  }
}
