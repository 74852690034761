<div class="h-screen w-screen flex flex-col justify-around items-center content-wrapper p-2">

  <div class="w-11/12 xs:w-11/12 sm:w-128 md:w-128 lg:w-128">

    <div class="p-card p-2 login-wrapper">
      <img class="login-logo light-image" src="../../assets/logo-svg.svg">
      <img class="login-logo dark-image" src="../../assets/logo-darkmode-svg.svg">
      <h3 class="text-center mb-4">Please wait...</h3>
    </div>

  </div>

</div>