import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";
import { Device, OperatingSystem } from "@capacitor/device";
import { AnalyticsService } from "src/app/services";
import { environment } from "src/environments/environment";

@Component({
  selector: "app-get-app",
  templateUrl: "./get-app.page.html",
  styleUrls: ["./get-app.page.scss"],
  standalone: true,
})
export class GetAppPage implements OnInit {
  private urlIos =
    "https://apps.apple.com/app/apple-store/id1514729575?pt=118098733&ct=website&mt=8";
  private urlAndroid =
    "https://play.google.com/store/apps/details?id=com.interapptive.defectwise";
  private defaultUrl = "https://www.defectwise.com.au";
  constructor(
    private route: ActivatedRoute,
    private analytics: AnalyticsService
  ) {}

  async ngOnInit() {
    const operatingSystem = await this.getOperatingSystem();
    // moved from constructor as events were missing
    this.analytics.scanQrCode();
    // opened via ios device, treat as sign_up
    if (operatingSystem === "ios") {
      this.analytics.viewAppStore();
    }

    const url = await this.buildUrlFromParams(operatingSystem);
    if (environment.production) {
      // wait for tags
      setTimeout(() => {
        window.location.href = url;
      }, 50);
    } else {
      console.log(url);
    }
  }
  /**
   * Asynchronously builds a URL from the query parameters and the operating system
   * of the device. If there is an error building the URL, it returns a default URL.
   *
   * @return {Promise<string>} A string representing the constructed URL.
   */
  async buildUrlFromParams(operatingSystem: string): Promise<string> {
    try {
      let url = "";
      let queryParams = this.route.snapshot.queryParams;
      let query = Object.keys(queryParams)
        .map((key) => `${key}=${queryParams[key]}`)
        .join("&");

      switch (operatingSystem) {
        case "ios":
        case "mac":
          url = `${this.urlIos}&${query}`;
          break;
        case "android":
        case "windows":
        case "unknown":
          url = `${this.urlAndroid}&referrer=${encodeURIComponent(query)}`;
          break;
      }

      return url;
    } catch (ex) {
      return this.defaultUrl;
    }
  }

  /**
   * Extracted Device call so build can be unit tested properly
   * @returns
   */
  private async getOperatingSystem(): Promise<OperatingSystem> {
    try {
      const { operatingSystem } = await Device.getInfo();
      return operatingSystem;
    } catch (ex) {
      return "unknown";
    }
  }
}
