import { Component } from "@angular/core";
import { links } from "src/app/utils/constants";

import { DividerModule } from "primeng/divider";
import { ButtonModule } from "primeng/button";
import { Card } from "primeng/card";

@Component({
  selector: "app-upgrade-notice",
  templateUrl: "./upgrade-notice.component.html",
  styleUrls: ["./upgrade-notice.component.scss"],
  standalone: true,
  imports: [ButtonModule, DividerModule, Card],
})
export class UpgradeNoticeComponent {
  /** @ignore */
  constructor() {}

  get urlPricing() {
    return (
      links.pricing +
      "?utm_source%3Dwebapp%26utm_medium%3Dlink%26utm_content%3Dhome_btn%26utm_campaign%3Dwebapp"
    );
  }

  get urlAppleDownload() {
    return links.urlAppleDownload;
  }

  get urlGoogleDownload() {
    return links.urlGoogleDownload;
  }
}
