import { Component } from '@angular/core';

@Component({
    selector: 'app-disabled-notice',
    templateUrl: './disabled-notice.component.html',
    styleUrls: ['./disabled-notice.component.scss'],
    standalone: true
})
export class DisabledNoticeComponent {
  readonly supportLink =
    "https://interapptive.atlassian.net/servicedesk/customer/portal/2?utm_source=web_app_link&utm_medium=web_app&utm_campaign=web_app&utm_content=home_disabled";
  /** @ignore */
  constructor() { }

}
