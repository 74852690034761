<p-button [styleClass]="styleClass" [severity]="severity" [text]="true" (click)="onButtonClick($event)"
  [disabled]="disabled" [loading]="loading">
  <ng-template pTemplate="content">
    <div class="w-full flex flex-row items-center justify-start">
      <div>
        <i [class]="'pi ' + icon"></i>
      </div>
      <div class="flex-1 pl-4 text-left">
        <div>{{heading}}</div>
        <div class="text-sm">{{label}}</div>
      </div>
    </div>
  </ng-template>
</p-button>