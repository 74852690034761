import { Component, OnInit } from "@angular/core";
import { ActivatedRoute, RouterLink } from "@angular/router";
import { BillingService, AnalyticsService, FirebaseService } from "../services";

import { Browser } from "@capacitor/browser";
import { environment } from "src/environments/environment";
import { DefectWiseProducts } from "../models";

import { Card } from "primeng/card";
import { DividerModule } from "primeng/divider";
import { ButtonModule } from "primeng/button";

@Component({
  selector: "app-confirmation",
  templateUrl: "./confirmation.page.html",
  styleUrls: ["./confirmation.page.scss"],
  standalone: true,
  imports: [RouterLink, DividerModule, Card, ButtonModule],
})
export class ConfirmationPage implements OnInit {
  userEmail: string = "";
  /**
   * https://stripe.com/docs/payments/checkout/custom-success-page
   * `CHECKOUT_SESSION_ID` from session success
   *
   * http://localhost:8100/confirmation/test/price_1LaugWL9P7w9VoDKJTGfAkDJ/1?gclid=TEST1234
   */
  readonly referenceId: string | undefined;
  /** */
  readonly productId: DefectWiseProducts | undefined;
  /** discount from query params */
  readonly promoCode: string | undefined;
  /** Google analytics GCLID */
  readonly gclid: string | undefined;
  /** download app links */
  readonly urlAppleDownload: string =
    "https://apps.apple.com/au/app/site-defects-app-defectwise/id151472957";
  readonly urlGoogleDownload: string =
    "https://play.google.com/store/apps/details?id=com.interapptive.defectwise";
  /** @ignore */
  constructor(
    private fb: FirebaseService,
    private billing: BillingService,
    private analytics: AnalyticsService,
    private route: ActivatedRoute
  ) {
    this.analytics.pageView("Account - Confirmation", "confirmation");
    const params = this.route.snapshot.params;
    this.referenceId = params["referenceId"];
    this.productId = params["productId"];
    if (params["quantity"]) {
      this.billing.quantity = isNaN(Number(params["quantity"]))
        ? 1
        : Number(params["quantity"]);
    }

    // apply coupon if it is a valid code
    const queryParams = this.route.snapshot.queryParams;
    const { promoCodes }: any = environment;
    if (promoCodes[queryParams["code"]]) {
      this.promoCode = queryParams["code"];
    }
    if (queryParams["gclid"]) {
      this.gclid = queryParams["gclid"];
    }
  }
  /**
   * Register Analytics Event On Load
   */
  async ngOnInit(): Promise<void> {
    try {
      if (!environment.production) {
        console.log("User Service", this.billing);
      }

      // Ensure users email is set and included in purhcase event
      const user = await this.fb.getUser();
      this.userEmail = user?.email ?? "";

      // If valid transaction found, register purchase
      if (this.referenceId && this.productId) {
        this.analytics.purchase(
          this.referenceId,
          this.productId,
          this.billing.quantity,
          user?.email,
          this.promoCode,
          this.gclid
        );
      } else {
        console.error("Missing required parameters to register purchase event");
      }
    } catch (ex) {
      // no biggie
    }
  }
  /**
   * Open a link in browser
   * @param {string} url page to open
   */
  async openLink(url: string): Promise<void> {
    await Browser.open({ url: url, windowName: "_self" });
  }
}
