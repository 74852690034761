<div class="h-screen w-screen flex flex-col justify-around items-center content-wrapper p-2">

  <div class="w-11/12 xs:w-11/12 sm:w-128 md:w-128 lg:w-128">

    <!-- NORMAL LOGIN -->
    <div *ngIf="!redirectToClient && !showRegister">
      <p-card>
        <img width="180" class="login-logo light-image" src="../../assets/logo-svg.svg">
        <img width="180" class="login-logo dark-image" src="../../assets/logo-darkmode-svg.svg">
        <login-widget #loginWidget (loggedIn)="showRegisterScreen()" [isClientPortal]="redirectToClient"
          (loginCompleteCallback)="registerCompleted()"></login-widget>
      </p-card>
    </div>

    <!-- CLIENT PORTAL LOGIN ELEMENTS -->
    <div *ngIf="redirectToClient && !showRegister">
      <p-card *ngIf="!clientParamsInvalid">
        <img width="180" class="login-logo light-image" src="../../assets/logo-svg.svg">
        <img width="180" class="login-logo dark-image" src="../../assets/logo-darkmode-svg.svg">
        <div class="text-center mt-5">
          <h1 style="font-size: 2em;">Owners Management Portal</h1>
        </div>
        <login-widget #loginWidget (loggedIn)="showRegisterScreen()" [isClientPortal]="redirectToClient"
          (loginCompleteCallback)="registerCompleted()"></login-widget>
      </p-card>

      <!-- 404 NOT FOUND - missing project id -->
      <p-card *ngIf="clientParamsInvalid">
        <img width="180" class="login-logo light-image" src="../../assets/logo-svg.svg">
        <img width="180" class="login-logo dark-image" src="../../assets/logo-darkmode-svg.svg">
        <div class="text-center mt-5">
          <h1 style="font-size: 2em;">Invalid Link</h1>
          <p>Use a valid link to access the defect management portal.</p>
        </div>
      </p-card>
    </div>

    <!-- REGISTER ELEMENTS -->
    <div *ngIf="showRegister">
      <!-- REGULAR -->
      <p-card *ngIf="!redirectToClient">
        <register-widget #registerWidget (registerComplete)="registerCompleted()"></register-widget>
      </p-card>

      <!-- CLIENT ELEMENTS -->
      <p-card *ngIf="redirectToClient">
        <client-register-widget #registerWidget (registerComplete)="registerCompleted()"></client-register-widget>
      </p-card>
    </div>
  </div>

</div>