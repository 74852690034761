import {
  ApplicationConfig,
  ErrorHandler,
  inject,
  provideAppInitializer,
  provideZoneChangeDetection,
} from "@angular/core";
import {
  HTTP_INTERCEPTORS,
  provideHttpClient,
  withInterceptorsFromDi,
} from "@angular/common/http";
import { HttpInterceptorService } from "./services/http-interceptor.service";
import { provideAnimationsAsync } from "@angular/platform-browser/animations/async";
import {
  provideRouter,
  Router,
  withComponentInputBinding,
} from "@angular/router";
import { routeConfig } from "./app.routes";
import { providePrimeNG } from "primeng/config";

import { provideServiceWorker } from "@angular/service-worker";
import { DefectWiseTheme } from "src/theme";
import * as Sentry from "@sentry/angular";
import { provideFirebaseApp, initializeApp } from "@angular/fire/app";
import { provideAuth, getAuth } from "@angular/fire/auth";

import { environment } from "src/environments/environment";
import { TraceService } from "@sentry/angular";

export const appConfig: ApplicationConfig = {
  providers: [
    provideZoneChangeDetection({ eventCoalescing: true }),
    provideRouter(routeConfig, withComponentInputBinding()),
    provideAnimationsAsync(),
    provideHttpClient(
      // DI-based interceptors must be explicitly enabled.
      withInterceptorsFromDi()
    ),
    // https://angular.dev/guide/http/interceptors#di-based-interceptors
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
    providePrimeNG({
      theme: {
        preset: DefectWiseTheme,
        options: {
          // disable dark mode
          // https://primeng.org/theming#darkmode
          darkModeSelector: false || ".my-app-dark",
          cssLayer: {
            name: "primeng",
            order: "tailwind-base, primeng, tailwind-utilities",
          },
        },
      },
      // translation: {
      //   accept: 'Aceptar',
      //   reject: 'Rechazar',
      // },
    }),
    provideServiceWorker("ngsw-worker.js", { enabled: environment.production }),
    provideFirebaseApp(() => initializeApp(environment.firebaseConfig)),
    provideAuth(() => getAuth()),
    {
      provide: ErrorHandler,
      useValue: Sentry.createErrorHandler(),
    },
    {
      provide: Sentry.TraceService,
      deps: [Router],
    },
    provideAppInitializer(() => {
      inject(TraceService);
    }),
  ],
};
