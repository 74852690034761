<div class="flex flex-col items-stretch h-full surface-panel">

  <div class="p-2 md:p-3 mb-4 flex flex-row items-center mobile-toolbar">
    <h1 class="ml-3 md:ml-0" *ngIf="defect">{{defect.defectNumberString}}</h1>

    <p-button icon="pi pi-save" [label]="editMode ? 'Update' : 'Save'" [loading]="saving" class="ml-auto"
      [pTooltip]="editMode ? 'Update existing' : 'Save new defect'" tooltipPosition="left" severity="secondary"
      (click)="save()"></p-button>

    <p-button icon="pi pi-ellipsis-h" class="ml-2" severity="secondary" [rounded]="true" [text]="true"
      pTooltip="Options" tooltipPosition="left" (click)="showPopMenu($event, popMenuCtrl)"></p-button>

    <p-button icon="pi pi-times" class="mr-3 md:mr-0" severity="secondary" [rounded]="true" [text]="true"
      pTooltip="Close Defect" tooltipPosition="left" (click)="close()"></p-button>
  </div>

  <div class="p-2 md:p-0 flex-1 overflow-y-auto pb-8">
    <div class="flex flex-col flex-auto px-3">
      <ng-container [ngTemplateOutlet]="template"></ng-container>
    </div>
  </div>
</div>

<p-menu #popMenuCtrl [popup]="true" [style]="{minWidth: '220px'}" [model]="popoverMenuOptions" appendTo="body"></p-menu>