import {
  Component,
  OnInit,
  ViewChild,
  AfterViewInit,
  OnDestroy,
} from "@angular/core";
import { NgIf } from "@angular/common";
import { Router, RouterLink } from "@angular/router";
import { FormsModule } from "@angular/forms";

import {
  CatchErrorService,
  EventsService,
  AnalyticsService,
  AlertService,
  ApiService,
  HomeService,
  UserService,
  PrimeTableHelperService,
  LoadingController,
  NotificationsService,
} from "src/app/services";

import { IProjectType } from "src/app/models";
import { links } from "src/app/utils/constants";
import { ProjectProvider } from "src/app/providers";
import { CustomDatePipe } from "src/app/utils/pipes";

import {
  ViewInviteComponent,
  UpgradeNoticeComponent,
  DisabledNoticeComponent,
} from "./ui";

import { MenuItem, SelectItem, SharedModule } from "primeng/api";
import { Table, TableModule } from "primeng/table";
import { DialogService } from "primeng/dynamicdialog";
import { DialogModule } from "primeng/dialog";
import { MenuModule } from "primeng/menu";
import { InputTextModule } from "primeng/inputtext";
import { BadgeModule } from "primeng/badge";
import { TooltipModule } from "primeng/tooltip";
import { ButtonModule } from "primeng/button";
import { ToolbarModule } from "primeng/toolbar";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { Select } from "primeng/select";

/** Home page component */
@Component({
  selector: "app-home",
  templateUrl: "home.component.html",
  styleUrls: ["./home.component.scss"],
  providers: [DialogService],
  standalone: true,
  imports: [
    ToolbarModule,
    ButtonModule,
    TooltipModule,
    NgIf,
    BadgeModule,
    RouterLink,
    DisabledNoticeComponent,
    UpgradeNoticeComponent,
    TableModule,
    SharedModule,
    FormsModule,
    InputTextModule,
    MenuModule,
    DialogModule,
    ViewInviteComponent,
    CustomDatePipe,
    IconField,
    InputIcon,
    Select,
  ],
})
export class HomePage implements OnInit, AfterViewInit, OnDestroy {
  @ViewChild("datatable") datatable: Table | undefined;
  /** Counts number of local/cloud projects for UI control */
  projectCount: number = 0;
  /** Control UI elements depending on loading state */
  loading: boolean = true;
  /** */
  selectedTypeOption = [];
  /** */
  profileOpts: MenuItem[] = [];
  /** The global text input value for clearing */
  textFilterValue: string = "";
  /** Whether there is an active filter */
  filterActive: boolean = false;
  /** */
  readonly tableLocalStoreKey = "home-screen-state";
  /** Filter menu options */
  readonly typeOptions: SelectItem[];
  /**@ignore */
  constructor(
    private cx: CatchErrorService,
    private events: EventsService,
    private home: HomeService,
    private analytics: AnalyticsService,
    private alert: AlertService,
    private router: Router,
    private api: ApiService,
    private us: UserService,
    private pp: ProjectProvider,
    private tableHelper: PrimeTableHelperService,
    private loadingCtrl: LoadingController,
    private ns: NotificationsService
  ) {
    this.analytics.pageView("DefectWise - Projects", "projects");
    this.subscribeToEvents();
    this.typeOptions = [
      { label: "House", value: IProjectType.HOUSE },
      { label: "Office", value: IProjectType.OFFICE },
      { label: "Multiple", value: IProjectType.MULTIPLE },
      { label: "Units", value: IProjectType.UNITS },
    ];
  }

  get isPremium() {
    return this.us.premium;
  }

  get isEnabled() {
    return this.us.enabled;
  }

  /** Projects array for display */
  get projects() {
    return this.pp.projects;
  }

  get notificationCount(): string | undefined {
    return this.ns.notificationCount ? `${this.ns.notificationCount}` : "";
  }

  get invite() {
    return this.ns.invite;
  }

  get displayInviteModal() {
    return this.ns.displayInviteModal;
  }

  set displayInviteModal(value) {
    this.ns.displayInviteModal = value;
  }

  /** Home initialisation */
  async ngOnInit(): Promise<void> {
    this.loadingCtrl.present();
    try {
      await Promise.all([
        this.getData(),
        this.us.awaitAdmin(),
        this.us.awaitPremium(),
      ]);
    } catch (ex) {
      await this.loadingCtrl.dismiss();
      await this.cx.handle(ex, false);
    } finally {
      await this.loadingCtrl.dismiss();
      this.setGlobalFilterVal();
    }
  }
  /** Home destroy cleanup */
  ngOnDestroy(): void {
    this.events.destroy("home:reload");
  }

  ngAfterViewInit(): void {
    this.setGlobalFilterVal();
  }

  /** Hook up to page/app events */
  private subscribeToEvents(): void {
    this.events.subscribe("home:reload", (_: undefined) => {
      this.getData();
    });
  }
  /**
   * Get home page data
   *
   * Immediately returns local data for responsiveness
   * then mashing in cloud data.
   */
  private async getData(): Promise<void> {
    try {
      this.loading = true;

      const premium = await this.us.awaitPremium();

      if (premium && this.us.enabled) {
        const { projects } = await this.home.getHomePageData();
        this.projectCount = projects.length;
        this.pp.projects = [...projects];
      }

      this.loading = false;
    } catch (ex) {
      await this.cx.handle(ex);
      await this.alert.showConfirm(
        "Error",
        "Failed to load page",
        "Reload the page. If problems persist try logging out and back in. " +
          ex,
        "Refresh",
        async () => {
          await this.getData();
        }
      );
    }
  }

  /** Clears the invite after ignore/accept */
  protected closeInvite(inviteVal: boolean): void {
    if (inviteVal === false) {
      this.ns.hideInvite();
    }
    this.displayInviteModal = false;
  }
  /**
   * Create a new project
   * @param type Integer representing the type of the project
   */
  protected async createProject(): Promise<void> {
    const premium = await this.us.awaitPremium();
    if (premium) {
      await this.router.navigate(["projects/create"]);
    } else {
      this.cx.handle(
        "A subscription is required to create projects online.",
        true
      );
    }
  }
  /**
   * Table search
   */
  protected search(ev: any) {
    this.datatable?.filterGlobal(ev.target.value ?? "", "contains");
  }
  /**
   * Reset filters to show all types
   */
  protected resetFilters(): void {
    if (this.datatable) {
      this.selectedTypeOption = [];
      this.textFilterValue = "";
      this.datatable.clear();
      this.clearTableSelection();
      this.filterActive = false;
    }
  }

  /**
   * Accepts the table filter data and checks if a filter is applied
   * @param event
   */
  protected tableOnFilter(event: any): void {
    let hasFilter = this.tableHelper.tableOnFilter(event);
    if (hasFilter !== undefined) {
      this.filterActive = hasFilter;
    }
  }

  /**
   * Clears the table selection and cached local storage values
   */
  private clearTableSelection(): void {
    // clearing this does no clear the cache, do as well
    let dtStore = this.tableHelper.get(this.tableLocalStoreKey);
    if (dtStore) {
      if (dtStore.filters) {
        dtStore.filters = undefined;
      }
      this.tableHelper.set(this.tableLocalStoreKey, dtStore);
    }
  }

  /**
   * set the text input to the global filter value
   */
  private setGlobalFilterVal(): void {
    setTimeout(() => {
      if (this.datatable?.filters["global"]) {
        this.textFilterValue = `${
          (this.datatable?.filters["global"] as any).value
        }`;
      }
    }, 200);
  }

  /**
   * Log the user out
   */
  private async logout(): Promise<void> {
    try {
      await this.us.logout();
      await this.router.navigate(["/login"]).then(() => {
        window.location.reload();
      });
    } catch (ex) {
      this.cx.handle(ex, true);
    }
  }

  /**
   * My Account menu
   * @param event
   * @param profileCtrl
   */
  protected showMenu(event: any, profileCtrl: any): void {
    let menu: MenuItem[] = [
      {
        label: "ACCOUNT",
        items: [
          {
            label: "Manage Account",
            routerLink: ["/options", "account"],
          },
        ],
      },
    ];

    if (!this.us.premium) {
      menu.push(
        {
          separator: true,
        },
        {
          label: "UPGRADE",
          items: [
            {
              label: "Try Premium",
              url:
                links.pricing +
                "?utm_source=webapp&utm_medium=link&utm_content=menu_btn&utm_campaign=webapp",
            },
          ],
        }
      );
    }

    this.profileOpts = [
      ...menu,
      {
        separator: true,
        label: "Support",
        items: [
          {
            label: "Help Docs",
            icon: "pi pi-question-circle",
            url: links.documentation,
            target: "_blank",
          },
          {
            label: "Contact Support",
            icon: "pi pi-envelope",
            url: links.support,
            target: "_blank",
          },
        ],
      },
      {
        separator: true,
        items: [
          {
            label: "Log Out",
            command: () => this.logout(),
          },
        ],
      },
    ];
    profileCtrl.toggle(event);
  }

  protected showNotifications(): void {
    this.ns.toggleSidePanel(true);
  }

  preventDefault(event: any): void {
    event.preventDefault();
    event.stopPropagation();
  }
}
