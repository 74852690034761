import { Component } from "@angular/core";
import { Dialog } from "primeng/dialog";
import { LucideAngularModule, LoaderCircle } from "lucide-angular";

import { LoadingController } from "src/app/services";

@Component({
  selector: "app-loading-spinner",
  imports: [Dialog, LucideAngularModule],
  templateUrl: "./loading-spinner.component.html",
  styleUrl: "./loading-spinner.component.scss",
})
export class LoadingSpinner {
  readonly LoaderCircleIcon = LoaderCircle;
  constructor(private loadingCtrl: LoadingController) {}

  get showLoader() {
    return this.loadingCtrl.visible;
  }

  get loadingMessage() {
    return this.loadingCtrl.message;
  }
}
