import { Component } from "@angular/core";
import { NgIf } from "@angular/common";
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";
import { DynamicDialogRef } from "primeng/dynamicdialog";
import { DynamicDialogConfig } from "primeng/dynamicdialog";

import {
  ContractorService,
  AnalyticsService,
  CatchErrorService,
  AlertService,
  ANALYTICS_EVENTS,
} from "src/app/services";
import { IContractorStatus, type IContractor } from "src/app/models";

import { ButtonModule } from "primeng/button";
import { InputSwitchModule } from "primeng/inputswitch";
import { InputTextModule } from "primeng/inputtext";
import { FloatLabel } from "primeng/floatlabel";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";
import { Divider } from "primeng/divider";

@Component({
  selector: "app-contractor-edit-modal",
  templateUrl: "./contractor-edit-modal.component.html",
  styleUrls: ["./contractor-edit-modal.component.scss"],
  standalone: true,
  imports: [
    FormsModule,
    ReactiveFormsModule,
    NgIf,
    InputTextModule,
    InputSwitchModule,
    ButtonModule,
    FloatLabel,
    IconField,
    InputIcon,
    Divider,
  ],
})
export class ContractorEditComponent {
  loading: boolean = false;
  loadingDelete: boolean = false;
  LoadingToggle: boolean = false;
  /**
   * Control add/edit
   */
  editMode: boolean = true;
  /**
   * contractor passed in or out
   */
  contractor: IContractor | undefined;
  /**
   * contractor info form
   */
  contractorForm: FormGroup;
  /**
   * refresh parent listing if active/archive or something changed
   */
  refreshPending: boolean = false;

  toggleCallbackFn: Function | undefined;

  active: boolean = false;

  constructor(
    private analytics: AnalyticsService,
    private cs: ContractorService,
    private cx: CatchErrorService,
    private alert: AlertService,
    private dialogRef: DynamicDialogRef,
    private dialogConfig: DynamicDialogConfig
  ) {
    this.contractorForm = new FormGroup({
      company: new FormControl("", { validators: [Validators.required] }),
      name: new FormControl(""),
      email: new FormControl(""),
      phone: new FormControl(""),
      discipline: new FormControl(""),
    });

    if (this.contractor === undefined) {
      this.contractor = this.dialogConfig.data["contractor"];
    }

    if (this.toggleCallbackFn === undefined) {
      this.toggleCallbackFn = this.dialogConfig.data["toggleCallbackFn"];
    }

    this.editMode = this.contractor !== undefined;
    if (this.editMode) {
      this.contractorForm.controls["company"].setValue(
        this.contractor?.company || ""
      );
      this.contractorForm.controls["name"].setValue(
        this.contractor?.name || ""
      );
      this.contractorForm.controls["email"].setValue(
        this.contractor?.email || ""
      );
      this.contractorForm.controls["phone"].setValue(
        this.contractor?.phone || ""
      );
      this.contractorForm.controls["discipline"].setValue(
        this.contractor?.discipline || ""
      );
      this.active = this.contractor?.status === IContractorStatus.active;
    }
  }

  /**
   * Save contractor
   */
  async save(): Promise<void> {
    this.loading = true;

    try {
      const { company, name, email, phone, discipline } =
        this.contractorForm.value;
      const _company = `${company}`.trim();
      const _name = `${name}`.trim();
      const _email = `${email}`.trim();
      const _phone = `${phone}`.trim();
      const _discipline = `${discipline}`.trim();

      let saveContractor: IContractor;
      if (this.editMode) {
        saveContractor = {
          ...this.contractor,
          lastModified: this.contractor?.lastModified || 0,
          lastMapChanged: this.contractor?.lastMapChanged || 0,
          isAssigned: this.contractor?.isAssigned || false,
          cloudId: this.contractor?.cloudId,
          company: _company,
          name: _name,
          email: _email,
          phone: _phone,
          discipline: _discipline,
          status: this.active
            ? IContractorStatus.active
            : IContractorStatus.archived,
        };
      } else {
        saveContractor = {
          company: _company,
          name: _name,
          email: _email,
          phone: _phone,
          discipline: _discipline,
          cloudId: null,
          status: IContractorStatus.active,
          isAssigned: false,
          lastModified: Date.now(),
          lastMapChanged: 0, // not required
        };
      }

      const result = await this.cs.saveContractor(saveContractor);

      if (result.success) {
        this.analytics.trackCustomEvent(
          this.editMode
            ? ANALYTICS_EVENTS.contractorEdited
            : ANALYTICS_EVENTS.contractorCreated
        );
        this.dismiss("refresh-page");
      } else {
        await this.alert.showAlert({ title: "Error", message: result.message });
      }
    } catch (ex) {
      this.cx.handle(ex);
    } finally {
      this.loading = false;
    }
  }

  /**
   * Delete contractor
   */
  async delete(): Promise<void> {
    this.loadingDelete = true;

    try {
      if (this.contractor !== undefined) {
        const result = await this.cs.deleteContractor(this.contractor);
        if (result.success) {
          this.analytics.trackCustomEvent(ANALYTICS_EVENTS.contractorDeleted);
          this.dismiss("refresh-page");
        } else {
          await this.alert.showAlert({
            title: "Error",
            message: result.message,
          });
        }
      }
    } catch (ex) {
      this.cx.handle(ex);
    } finally {
      this.loadingDelete = false;
    }
  }

  /**
   * Close the modal
   */
  dismiss(action?: string): void {
    if (this.refreshPending) {
      action = "refresh-page";
    }
    this.dialogRef.close({ role: action || "cancel" });
  }
}
