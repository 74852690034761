import { Component, OnInit, Input } from "@angular/core";
import { NgIf } from "@angular/common";
import {
  FormGroup,
  Validators,
  FormControl,
  FormsModule,
  ReactiveFormsModule,
} from "@angular/forms";

import {
  CatchErrorService,
  EventsService,
  ProjectService,
  HomeService,
  AnalyticsService,
  ANALYTICS_EVENTS,
} from "src/app/services";
import { ProjectProvider } from "src/app/providers/project.provider";
import {
  Project,
  List,
  ProjectPrimaryKeyId,
  TenantRole,
  PrimaryKeyId,
} from "src/app/models";

import { TextareaModule } from "primeng/textarea";
import { InputTextModule } from "primeng/inputtext";
import { ButtonModule } from "primeng/button";
import { FloatLabel } from "primeng/floatlabel";
import { IconField } from "primeng/iconfield";
import { InputIcon } from "primeng/inputicon";

/** Project info component */
@Component({
  selector: "app-project-info",
  templateUrl: "./project-info.component.html",
  styleUrls: ["./project-info.component.scss"],
  standalone: true,
  imports: [
    NgIf,
    ButtonModule,
    FormsModule,
    ReactiveFormsModule,
    InputTextModule,
    TextareaModule,
    FloatLabel,
    IconField,
    InputIcon,
  ],
})
export class ProjectInfoComponent implements OnInit {
  /** Project data input data */
  @Input("newProjData") newProjectData: any = {};
  /** Edit mode flag */
  @Input("editMode") editMode: boolean | undefined;
  /** Edit mode flag */
  @Input("type") type: number | undefined;
  /** Project data input data */
  @Input("project") project: Project.Project | undefined;
  /** Project form */
  projectForm: FormGroup;
  /** Ensure project reference is unique */
  uniqueRef: boolean = true;
  /** Collection of exiting project refs */
  private existingRefs: string[] = [];
  /** Show or hide + disable Save button loading spinner */
  loading: boolean = false;
  /** @ignore */
  constructor(
    private cx: CatchErrorService,
    private ps: ProjectService,
    private home: HomeService,
    private events: EventsService,
    private analytics: AnalyticsService,
    private pp: ProjectProvider
  ) {
    this.projectForm = new FormGroup({
      name: new FormControl(this.project?.name || "", [Validators.required]),
      ref: new FormControl(this.project?.reference || "", [
        Validators.required,
        Validators.maxLength(20),
      ]),
      client: new FormControl(this.project?.client || ""),
      address: new FormControl(this.project?.address || ""),
      city: new FormControl(this.project?.city || ""),
      postcode: new FormControl(this.project?.postcode || ""),
      state: new FormControl(this.project?.state || ""),
      country: new FormControl(this.project?.country || ""),
    });
    this.type = this.type ?? 0;
  }

  /** OnInit function */
  async ngOnInit(): Promise<void> {
    try {
      this.home.getHomePageData().then((data) => {
        const { projects } = data;
        this.existingRefs = projects.map((p) => p.reference.toLowerCase());
      });

      if (this.editMode) {
        this.projectForm.controls["name"].setValue(this.project?.name || "");
        this.projectForm.controls["ref"].setValue(
          this.project?.reference || ""
        );
        this.projectForm.controls["client"].setValue(
          this.project?.client || ""
        );
        this.projectForm.controls["address"].setValue(
          this.project?.address || ""
        );
        this.projectForm.controls["city"].setValue(this.project?.city || "");
        this.projectForm.controls["postcode"].setValue(
          this.project?.postcode || ""
        );
        this.projectForm.controls["state"].setValue(this.project?.state || "");
        this.projectForm.controls["country"].setValue(
          this.project?.country || ""
        );
      }
    } catch (ex) {
      await this.cx.handle(ex, true, "An error occured:");
    }
  }

  /**
   * Create the project reference value and populate field
   * @param event Keyboard
   */
  public populateRef(): void {
    // console.log(this.projectForm.value["name"]);
    try {
      if (this.projectForm.controls["ref"].untouched && !this.editMode) {
        const comparitor: string = this.projectForm.value["name"];
        const compArray = comparitor.split(" ").filter((x) => x !== "");

        let ref = "";

        if (compArray.length < 3) {
          let l: number;
          comparitor.length < 3 ? (l = comparitor.length) : (l = 3);
          ref += comparitor.substring(0, l).toUpperCase();
        } else {
          ref = "";

          for (let i = 0; i < compArray.length; i++) {
            if (i == 3) break;
            ref += compArray[i].substring(0, 1).toUpperCase();
          }
        }

        this.projectForm.controls["ref"].setValue(ref);
        this.checkReference();
      }
    } catch (ex) {
      this.cx.handle(ex, true, "An error occured:");
    }
  }

  /**
   * Check whether the project reference already exists
   * @param event Text input
   */
  public checkReference(): void {
    const test = this.projectForm.value["ref"] ?? "";
    if (
      this.editMode &&
      test.toLowerCase() === this.project?.reference.toLowerCase()
    ) {
      this.uniqueRef = true;
    } else {
      this.uniqueRef = !this.existingRefs.includes(test.toLowerCase());
    }
  }

  /**
   * - Update existing, or
   * - Create new
   */
  public async save(): Promise<{ success: boolean; projectId: number }> {
    // If in edit mode, do update instead
    this.loading = true;
    let result: { success: boolean; projectId: number } = {
      success: false,
      projectId: -1,
    };
    try {
      if (this.editMode) {
        this.analytics.trackCustomEvent(ANALYTICS_EVENTS.projectEdited);
        result = await this.update();
      } else {
        this.analytics.trackCustomEvent(ANALYTICS_EVENTS.projectCreated);
        result = await this.create();
      }
    } catch (ex) {
      // hanled in other fn's
    } finally {
      this.loading = false;
      return result;
    }
  }

  /**
   * Create the project + sites
   */
  private async create(): Promise<{ success: boolean; projectId: number }> {
    try {
      let isServerSaved = false;

      const { name, ref, client, address, city, postcode, state, country } =
        this.projectForm.value;

      // Create Project Logic
      let saveProject: Project.Project = {
        id: -1 as ProjectPrimaryKeyId,
        type: this.type ?? 0,
        created: new Date().toISOString(),
        // Form fields
        name: `${name}`.trim(),
        reference: `${ref}`.trim(),
        client: `${client}`.trim(),
        address: `${address}`.trim(),
        city: `${city}`.trim(),
        postcode: `${postcode}`.trim(),
        state: `${state}`.trim(),
        country: `${country}`.trim(),
        // Not req'd for saving to device
        // contractors: this.project?.contractors || [],
        lastModified: 0,
        // lastSyncDate: 0,
        role: TenantRole.owner,
        photo: { url: "", photoId: -1 as PrimaryKeyId },
        clientEnabled: false,
      };

      // Save project table and newly generated site lists
      const sites: List.List[] = this.generateNewSites(saveProject.type);

      const createResult = await this.ps.createCloudProject(saveProject, sites);
      isServerSaved = createResult.success;

      // For online-only projects, swap cloud id as the project id
      saveProject.id = (createResult.cloudId ?? -1) as ProjectPrimaryKeyId;

      this.events.publish<void>("home:reload", undefined);
      // this.dismiss(isServerSaved, saveProject.cloudId);
      return { success: isServerSaved, projectId: saveProject.id };
    } catch (ex) {
      if (ex !== undefined && JSON.stringify(ex).indexOf("#401") < 0) {
        await this.cx.handle(ex, true);
      }
      return { success: false, projectId: -1 };
    }
  }

  /**
   * Update the project
   */
  private async update(): Promise<{ success: boolean; projectId: number }> {
    try {
      const { name, ref, client, address, city, postcode, state, country } =
        this.projectForm.value;

      // console.log("update()");
      const saveProject: Project.Project = {
        id: this.project!.id,
        type: this.project!.type,
        created: this.project!.created,
        // Form fields
        name: `${name}`.trim(),
        reference: `${ref}`.trim(),
        client: `${client}`.trim(),
        address: `${address}`.trim(),
        city: `${city}`.trim(),
        postcode: `${postcode}`.trim(),
        state: `${state}`.trim(),
        country: `${country}`.trim(),
        // Not req'd for saving to device
        lastModified: 0,
        role: TenantRole.owner,
        photo: this.project?.photo ?? { url: "", photoId: -1 as PrimaryKeyId },
        clientEnabled: this.project?.clientEnabled ?? false,
      };

      let cloudUpdated = false,
        duplicateRef = false;

      // Server operations - NOT DEVICE ONLY
      const cloudResult = await this.ps.editCloudProject(saveProject);
      cloudUpdated = cloudResult.success;
      duplicateRef = cloudResult.message.includes("reference");

      // Local operations - NOT CLOUD ONLY
      if (duplicateRef) {
        this.uniqueRef = !duplicateRef;
      }

      if (!duplicateRef && cloudUpdated) {
        await this.pp.getProject(true);
        this.events.publish("home:reload", cloudUpdated);
      }

      return { success: cloudUpdated, projectId: saveProject.id };
    } catch (ex) {
      if (ex !== undefined && JSON.stringify(ex).indexOf("#401") < 0) {
        await this.cx.handle(ex, true);
      }
      return { success: false, projectId: -1 };
    }
  }
  /**
   * Reset the FormGroup
   */
  public resetForm(): void {
    this.projectForm.reset({
      name: "",
      reference: "",
      client: "",
      address: "",
      city: "",
      postcode: "",
      state: "",
      ountry: "",
    });
  }
  /**
   * Create sites using data from 'newProjectData' object
   */
  private generateNewSites(type: number): List.List[] {
    let sites: List.List[] = [];

    // Add Common Area
    if (this.newProjectData.commonArea) {
      let commonAreaAddress: string = this.projectForm.value.address;
      if (type == 1) commonAreaAddress = "Common Areas";

      sites.push({
        cloudId: -1,
        address: `${commonAreaAddress}`.trim(),
        unit: "Common Areas",
        floor: "",
        notes: "",
        type: type,
        lastModified: 0,
        projectCloudId: -1, // not used here
        projectAddress: commonAreaAddress, // not used here
      });
    }

    for (let i = 0; i < this.newProjectData.siteCounter; i++) {
      sites.push({
        cloudId: -1,
        address: "",
        unit: "",
        floor: "",
        notes: "",
        type: type,
        lastModified: 0,
        projectCloudId: -1, // not used here
        projectAddress: "", // not used here
      });
    }

    return sites;
  }
}
