<div class="h-screen w-screen flex flex-col content-wrapper p-4">

  <div class="!mt-8 limit-width">

    <!-- Branding and logout -->
    <p-card>
      <div class="flex flex-row items-center">

        <a [href]="urlDefectWise" title="Return to Defectwise.com.au" class="home-link">
          <div class="flex flex-row items-center">
            <img class="block mr-3" height="24" width="24" src="../../assets/logo-icon-only.svg">
            <h1 class="block !m-0 pt-1 text-primary">DefectWise</h1>
          </div>
        </a>

        <p-button label="Switch Account" icon="pi pi-sign-out" title="Log out" class="ml-auto mb-1" [rounded]="true"
          [text]="true" (click)="logout()"></p-button>
      </div>
    </p-card>
    <div class="p-3"></div>

    <!-- Branding and logout -->
    <p-card>
      <div class="mb-6 flex flex-row items-center font-bold text-primary">
        <div class="w-[120px]">Logged in as: </div>
        <span class="custEmail">{{email}}</span>
      </div>

      <p-divider></p-divider>

      <div class="flex flex-col sm:flex-row items-center">
        <div>

          <div class="mb-3 flex flex-row items-center">
            <div class="w-[120px] font-bold text-primary">Selected Plan: </div>
            <span>{{productName}}</span>
          </div>

          <div class="mb-3 flex flex-row items-center">
            <div class="w-[120px] font-bold text-primary">Team Size: </div>
            <span>{{quantity}} <i class="ml-1 pi pi-info-circle"
                pTooltip="You can change the team size 'quantity' during check out"></i></span>
          </div>


          <div class="pt-2 flex flex-row items-center" *ngIf="promoCode !== undefined && promoCode !== ''">
            <p-chip [label]="promoCode" icon="pi pi-tag" title="Promo Code"></p-chip>
            <p-button class="pl-2 pr-2" severity="danger" [text]="true" pTooltip="Remove promo code"
              (click)="removePromoCode()"><i class="pi pi-trash"></i></p-button>
          </div>
        </div>

        <p-button class="my-8 sm:mt-0 sm:ml-auto checkout-btn" styleClass="px-4" severity="secondary" [rounded]="true"
          (click)="checkout()">
          CHECK OUT &nbsp;<i class="pi pi-arrow-right"></i>
        </p-button>
      </div>

      <p-divider></p-divider>

      <p class="text-center text-sm">
        Log out to change account or continue to pay securely via Stripe.
      </p>


      <a class="text-decoration-none" [href]="urlStripe" target="_blank">
        <div class="flex flex-row justify-center items-center text-sm">
          <div><span class="powered-by-text" color="dark">Powered by</span></div>
          <div class="ml-1">
            <svg width="33" height="15">
              <g fill-rule="evenodd">
                <path
                  d="M32.956 7.925c0-2.313-1.12-4.138-3.261-4.138-2.15 0-3.451 1.825-3.451 4.12 0 2.719 1.535 4.092 3.74 4.092 1.075 0 1.888-.244 2.502-.587V9.605c-.614.307-1.319.497-2.213.497-.876 0-1.653-.307-1.753-1.373h4.418c0-.118.018-.588.018-.804zm-4.463-.859c0-1.02.624-1.445 1.193-1.445.55 0 1.138.424 1.138 1.445h-2.33zM22.756 3.787c-.885 0-1.454.415-1.77.704l-.118-.56H18.88v10.535l2.259-.48.009-2.556c.325.235.804.57 1.6.57 1.616 0 3.089-1.302 3.089-4.166-.01-2.62-1.5-4.047-3.08-4.047zm-.542 6.225c-.533 0-.85-.19-1.066-.425l-.009-3.352c.235-.262.56-.443 1.075-.443.822 0 1.391.922 1.391 2.105 0 1.211-.56 2.115-1.39 2.115zM18.04 2.766V.932l-2.268.479v1.843zM15.772 3.94h2.268v7.905h-2.268zM13.342 4.609l-.144-.669h-1.952v7.906h2.259V6.488c.533-.696 1.436-.57 1.716-.47V3.94c-.289-.108-1.346-.307-1.879.669zM8.825 1.98l-2.205.47-.009 7.236c0 1.337 1.003 2.322 2.34 2.322.741 0 1.283-.135 1.581-.298V9.876c-.289.117-1.716.533-1.716-.804V5.865h1.716V3.94H8.816l.009-1.96zM2.718 6.235c0-.352.289-.488.767-.488.687 0 1.554.208 2.241.578V4.202a5.958 5.958 0 0 0-2.24-.415c-1.835 0-3.054.957-3.054 2.557 0 2.493 3.433 2.096 3.433 3.17 0 .416-.361.552-.867.552-.75 0-1.708-.307-2.467-.723v2.15c.84.362 1.69.515 2.467.515 1.879 0 3.17-.93 3.17-2.548-.008-2.692-3.45-2.213-3.45-3.225z">
                </path>
              </g>
            </svg>
          </div>
        </div>
      </a>
    </p-card>
  </div>

</div>