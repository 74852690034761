import { Component, Input } from "@angular/core";
import { NgIf } from "@angular/common";

import { IDefectStatusSummary } from "src/app/models";

import { SummaryMicroChartComponent } from "../summary-micro-chart/summary-micro-chart.component";

@Component({
  selector: "app-defects-status-summary",
  templateUrl: "./defects-status-summary.component.html",
  styleUrls: ["./defects-status-summary.component.scss"],
  providers: [SummaryMicroChartComponent],
  standalone: true,
  imports: [NgIf, SummaryMicroChartComponent],
})
export class DefectsStatusSummaryComponent {
  @Input() data: IDefectStatusSummary | undefined;

  constructor() {}

  get getTotal(): number {
    return this.data?.getTotal() ?? 0;
  }
}
