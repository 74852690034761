<div class="h-screen flex flex-col">
  <p-toolbar>
    <ng-template #start>
      <div class="w-[130px]">
        <p-button label="Cancel" pTooltip="Close" severity="info" [routerLink]="['/projects']" routerDirection="back"
          (click)="cancel()"></p-button>
      </div>
    </ng-template>

    <ng-template #center>
      <h1 class="!m-0">{{title}}</h1>
    </ng-template>

    <ng-template #end>
      <div class="w-[130px]">
        <p-button label="Create Project" class="ml-3 button-nowrap" styleClass="text-nowrap" severity="secondary"
          [loading]="loading" [disabled]="!infoFormValid" (click)="createProject()" *ngIf="showCreate"></p-button>
      </div>
    </ng-template>
  </p-toolbar>

  <!-- BODY -->
  <div class="flex-1 w-screen overflow-y-auto overflow-x-hidden surface-ground">
    <div class="flex justify-center p-2">
      <div class="w-11/12 xs:w-11/12 sm:w-128 md:w-128 lg:w-128">
        <ng-container [ngTemplateOutlet]="template"></ng-container>
      </div>
    </div>
  </div>
</div>