<div class="flex flex-row">
  <i class="pi pi-exclamation-circle text-secondary" style="font-size: 2rem;"></i>
  <div class="pl-4">
    <h3 class="mt-0">Account disabled!</h3>
    Your account has been disabled by your organisation admin and access to
    project data is denied.
    <ul>
      <li>Contact your system admin</li>
      <li>
        <a [href]="supportLink" target="_self">Contact support</a>&nbsp;<i class="pi pi-external-link"
          style="font-size: 0.8rem;"></i>
      </li>
    </ul>
  </div>
</div>