import { Component, Input, forwardRef } from "@angular/core";
import { ControlValueAccessor, NG_VALUE_ACCESSOR, FormsModule } from "@angular/forms";
import { InputNumberModule } from "primeng/inputnumber";
import { ButtonModule } from "primeng/button";

@Component({
    selector: "app-project-list-generator",
    templateUrl: "./project-list-generator.page.html",
    styleUrls: ["./project-list-generator.page.scss"],
    providers: [
        {
            provide: NG_VALUE_ACCESSOR,
            useExisting: forwardRef(() => ProjectListGeneratorPage),
            multi: true,
        },
    ],
    standalone: true,
    imports: [ButtonModule, InputNumberModule, FormsModule]
})
export class ProjectListGeneratorPage implements ControlValueAccessor {
  /** ngModel */
  @Input() _siteCounter!: number;
  /** @ignore */
  constructor() {}

  set siteCounter(val: number) {
    this._siteCounter = val;
    this.propagateChange(this._siteCounter);
  }

  get siteCounter() {
    return this._siteCounter;
  }

  /** Button toggle */
  increment() {
    this.siteCounter++;
  }

  decrement() {
    const newCount = this.siteCounter - 1;
    this.siteCounter = newCount < 1 ? 1 : newCount;
  }

  /** @override */
  writeValue(value: any) {
    if (value !== undefined) {
      this.siteCounter = value;
    }
  }

  propagateChange = (_: any) => {};

  /** @override */
  registerOnChange(fn: any) {
    this.propagateChange = fn;
  }
  /** @override */
  registerOnTouched() {}
}
